//Actions
export const textChange = function (email) {
	return {
		type: "textChange",
		payload: email,
	};
};

//Reducer
const initialState = "";

export const userEmailReducer = function (state = initialState, action) {
	switch (action.type) {
		case "textChange":
			return action.payload;
		default:
			return state;
	}
};
