import React, { useContext, useState, useEffect } from 'react';
import Modal from '../../components/Modal';
import { useForm } from 'react-hook-form';
import M from 'materialize-css';
import Axios from 'axios';
import { API_URL } from '../../config';
import { AuthContext } from '../../context/auth-provider';
import { states } from '../AuthorizeNetCartPage/variables';

export const Form = ({ onSubmit, prefill = null }) => {
  const { register, errors, handleSubmit, setValue } = useForm();
  const { user } = useContext(AuthContext);

  useEffect(() => {
    prefill &&
      Object.keys(prefill).forEach((k) => {
        setValue(k, prefill[k]);
      });
  }, [prefill, setValue]);

  useEffect(() => {
    if (!prefill || !prefill.email) {
      user && user.email && setValue('email', user.email);
    }
  }, [prefill]);

  const s = {
    height: '2.5rem',
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h5 style={{ marginTop: '0' }}>
        {!prefill && 'New '}
        {prefill && 'Edit '}
        Address
      </h5>
      {/* <p style={{ fontSize: '1em' }}>
            lorem ipsum
         </p> */}
      <div className="row">
        <div className="form-group col s12">
          <label htmlFor="company">Company</label>
          <input
            style={s}
            className="form-control"
            name="company"
            id="company"
            ref={register}
          />
        </div>
        <div className="form-group col s12 m6">
          <label htmlFor="firstname">First Name *</label>
          <input
            style={s}
            className="form-control"
            name="firstname"
            id="firstname"
            ref={register({ required: 'First Name is required' })}
          />
          <span className="red-text helper-text">
            {errors && errors.firstname && errors.firstname.message}
          </span>
        </div>
        <div className="form-group col s12 m6">
          <label htmlFor="lastname">Last Name *</label>
          <input
            style={s}
            className="form-control"
            name="lastname"
            id="lastname"
            ref={register({ required: 'Last Name is required' })}
          />
          <span className="red-text helper-text">
            {errors && errors.lastname && errors.lastname.message}
          </span>
        </div>
        <div className="form-group col s12 m6">
          <label htmlFor="address1">Address *</label>
          <input
            style={s}
            className="form-control"
            name="address1"
            id="address1"
            ref={register({ required: 'Address is required' })}
          />
          <span className="red-text helper-text">
            {errors && errors.address1 && errors.address1.message}
          </span>
        </div>
        <div className="form-group col s12 m6">
          <label htmlFor="address2">Apartment, suite, etc</label>
          <input
            style={s}
            className="form-control"
            name="address2"
            id="address2"
            ref={register}
          />
        </div>
        <div className="form-group col s12 m4">
          <label htmlFor="city">City *</label>
          <input
            style={s}
            className="form-control"
            name="city"
            id="city"
            ref={register({ required: 'City is required' })}
          />
          <span className="red-text helper-text">
            {errors && errors.city && errors.city.message}
          </span>
        </div>
        <div className="form-group col s12 m4 c25">
          <Select
            register={register}
            label="State"
            name="state"
            options={states}
          />
          {/* <label htmlFor="state">State</label>
               <select name="state" id="id" ref={register}></select> */}
          {/* <input className="form-control" name="state" id="state" ref={register} /> */}
        </div>
        <div className="form-group col s12 m4">
          <label htmlFor="zip">Zip Code *</label>
          <input
            style={s}
            className="form-control"
            name="zip"
            id="zip"
            ref={register({ required: 'Zip is required' })}
          />
          <span className="red-text helper-text">
            {errors && errors.zip && errors.zip.message}
          </span>
        </div>
        <div className="form-group col s6">
          <label htmlFor="phone">Phone *</label>
          <input
            style={s}
            className="form-control"
            name="phone"
            id="phone"
            ref={register({ required: 'Phone is required' })}
          />
          <span className="red-text helper-text">
            {errors && errors.phone && errors.phone.message}
          </span>
        </div>
        <div className="form-group col s6">
          <label htmlFor="email">Email *</label>
          <input
            style={s}
            className="form-control"
            name="email"
            id="email"
            ref={register({ required: 'Email is required' })}
          />
          <span className="red-text helper-text">
            {errors && errors.email && errors.email.message}
          </span>
        </div>
        {user && (
          <div className="form-group col s12">
            <p>
              <label>
                <input
                  type="checkbox"
                  ref={register}
                  id="default"
                  name="default"
                />
                <span>Default Address</span>
              </label>
            </p>
          </div>
        )}
      </div>
      <div className="form-group col s12 m6">
        <button className="form-control btn btn-primary" type="submit">
          Submit
        </button>
      </div>
    </form>
  );
};

const Select = React.memo(({ register, name, label, error, options }) => {
  useEffect(() => {
    M.AutoInit();
  });

  const selected = options.reduce(
    (tot, el) => el.options && el.options.select,
    ''
  );

  return (
    <>
      <label className="active" htmlFor={name}>
        {label}
      </label>
      <select
        style={{ height: '2.5rem' }}
        ref={register && register}
        id={name}
        name={name}
        defaultValue={selected}
      >
        {options.map((el, i) => (
          <option key={i} value={el.value}>
            {el.label}
          </option>
        ))}
      </select>
      <span className="red-text helper-text">{error && error.message}</span>
    </>
  );
});

const AddressesTab = ({ addresses }) => {
  const [theAddresses, setTheAddresses] = useState(addresses);

  const { token } = useContext(AuthContext);

  useEffect(() => {
    setTheAddresses(addresses);
  }, [addresses]);

  const deleteAddress = (id = 0) => {
    const _id = id;

    Axios({
      url: `${API_URL}users.cfc?method=deleteAddress&i=${_id}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
      .then((r) => r.status === 200 && r.data)
      .then(
        (d) =>
          d.success && setTheAddresses(theAddresses.filter((e) => e.id !== _id))
      );
  };

  const onSubmit = (v) => {
    return Axios({
      url: `${API_URL}users.cfc?method=createAddress`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: v,
    })
      .then((r) => r.status === 200 && r.data)
      .then((d) => {
        if (d.success) {
          console.log('add address to ui', d.address);
          M.toast({ html: 'Address added!' });
          setTheAddresses(theAddresses.concat([d.address]));
        } else {
          M.toast({ html: 'Unable to add your address!' });
        }
        return d;
      })
      .catch((e) =>
        M.toast({
          html: 'Not validated, please login',
          classes: 'red white-text',
        })
      );
  };

  return (
    <>
      {theAddresses.length === 0 && (
        <div className="center">
          <br />
          <br />
          <h5 style={{ fontSize: '2em' }}>No Addresses</h5>
          <p>You don't have any orders yet</p>

          <SignupTrigger onSubmit={onSubmit} />

          <br />
          <br />
        </div>
      )}

      {theAddresses.length > 0 && (
        <div className="row">
          <div className="col s12 m3 push-m9 center-align">
            <br />
            <SignupTrigger onSubmit={onSubmit} />
          </div>
          <div className="col s12 m9 pull-m3">
            <div className="collection">
              {theAddresses.map((el, i) => (
                <AddressRecord
                  key={i}
                  deleteAddress={() => deleteAddress(el.id)}
                  {...el}
                  d={el.default}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const AddressRecord = ({
  id,
  d = false,
  deleteAddress,
  company,
  firstname,
  lastname,
  address1,
  address2,
  city,
  state,
  zip,
  email,
  phone,
  notes,
}) => {
  // const { token } = useContext(AuthContext);

  return (
    <div className="collection-item" style={{ position: 'relative' }}>
      <span className="title">
        {d > 0 && <i className="material-icons">star</i>}
        {company}
      </span>
      <p style={{ margin: 0 }}>
        {`${firstname}${lastname}`.length > 0 && (
          <>
            {firstname} {lastname}
          </>
        )}
        {`${address1}`.length > 0 && (
          <>
            <br />
            {address1}
          </>
        )}
        {`${address2}`.length > 0 && (
          <>
            <br />
            {address2}
          </>
        )}
        {`${city}${state}${zip}`.length > 0 && (
          <>
            <br />
            {city} {state}, {zip}
          </>
        )}
        {`${phone}`.length > 0 && (
          <>
            <br />
            {phone}
          </>
        )}
        {`${email}`.length > 0 && (
          <>
            <br />
            {email}
          </>
        )}
        {`${notes}`.length > 0 && (
          <>
            <br />
            {notes}
          </>
        )}
      </p>
      <button
        onClick={deleteAddress}
        className="btn btn-small red white-text secondary-content"
      >
        <i className="material-icons">delete</i>
      </button>
    </div>
  );
};
export default AddressesTab;

export const SignupTrigger = ({ onSubmit }) => {
  const [shown, setShown] = React.useState(false);
  const modalRef = React.useRef(null);
  const buttonRef = React.useRef(null);
  const { token, ...rest } = useContext(AuthContext);
  console.log({ rest });

  const showModal = () => {
    // console.log('trigger modal');
    setShown(true);
  };

  const closeModal = () => {
    // console.log('trigger modal');
    setShown(false);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 27) {
      closeModal();
    }
  };

  const onClickOutside = (e) => {
    if (modalRef && modalRef.current && modalRef.current.contains(e.target))
      return;
    console.log(
      modalRef.current,
      e.target,
      modalRef.current.contains(e.target)
    );
    closeModal();
  };

  const toggleScrollLock = () => {
    document.querySelector('html').classList.toggle('scroll-lock');
  };

  const doSubmit = (v) => {
    onSubmit(v).then((d) => {
      d && d.success && closeModal();
    });
  };

  return (
    <>
      <button
        type="button"
        onClick={showModal}
        className="btn waves-effect waves-teal"
      >
        Add Address
      </button>
      {shown && (
        <Modal
          ref={[modalRef, buttonRef]}
          Form={Form}
          onSubmit={doSubmit}
          modalRef={(x) => (modalRef = x)}
          buttonRef={buttonRef}
          closeModal={closeModal}
          onKeyDown={onKeyDown}
          onClickOutside={onClickOutside}
        ></Modal>
      )}
    </>
  );
};

// export default SignupTrigger;
