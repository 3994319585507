import CasualShirts from './casual-shirts.jpg';
import DressShirts from './dress-shirts.jpg';
import PoloShirts from './polo-shirts.jpg';
import Golf from './golf-shirt.jpg';

import Sweatshirts from './sweatshirt.jpg';
import Hoodies from './hoodies.jpg';
import Pullovers from './pullover.jpg';
import Sweaters from './sweater.jpg';

import Jackets from './jackets.jpg';
import Windbreakers from './windbreakers.jpg';
import Parkas from './parkas.jpg';
import RainGear from './raingear.jpg';

import Vests from './vests.jpg';
import BallCaps from './ballcaps.jpg';
import Beanies from './beanies.jpg';
import Visors from './visor.jpg';

import Workwear from './workwear.jpg';
import YouthApparel from './youth-apparel.jpg';
import Aprons from './aprons.jpg';
import CamouflageApparel from './camouflage.jpg';

import SHOPALLAPPAREL from './shop-all.jpg';

export const categories = [
   {
      name: 'Casual Shirts',
      image: CasualShirts,
      subcategories: [],
      link: 'https://shop.bahamajoes.com/category/1216/CASUALS.html',
   },
   {
      name: 'Dress Shirts',
      image: DressShirts,
      subcategories: [],
      link: 'https://shop.bahamajoes.com/category/1168/DRESS-SHIRTS.html',
   },
   {
      name: 'Polo Shirts',
      image: PoloShirts,
      subcategories: [],
      link: 'https://shop.bahamajoes.com/category/1167/POLO-SHIRTS.html',
   },
   {
      name: 'Golf Shirts',
      image: Golf,
      subcategories: [],
      link: 'https://shop.bahamajoes.com/category/580/Golf-Apparel.html',
   },
   {
      name: 'Sweatshirts',
      image: Sweatshirts,
      subcategories: [],
      link: 'https://shop.bahamajoes.com/category/1237/SWEATSHIRTS.html',
   },
   {
      name: 'Hoodies',
      image: Hoodies,
      subcategories: [],
      link: 'https://shop.bahamajoes.com/category/1327/HOODED.html',
   },
   {
      name: 'Pullovers',
      image: Pullovers,
      subcategories: [],
      link: 'https://shop.bahamajoes.com/category/1242/PULLOVERS.html',
   },
   {
      name: 'Sweaters',
      image: Sweaters,
      subcategories: [],
      link: 'https://shop.bahamajoes.com/category/1235/SWEATERS.html',
   },
   {
      name: 'Jackets',
      image: Jackets,
      subcategories: [],
      link: 'https://shop.bahamajoes.com/category/1232/JACKETS.html',
   },
   {
      name: 'Windbreakers',
      image: Windbreakers,
      subcategories: [],
      link: 'https://shop.bahamajoes.com/category/1239/SOFTSHELL--and--WINDSHIRTS.html',
   },
   {
      name: 'Parkas',
      image: Parkas,
      subcategories: [],
      link: 'https://shop.bahamajoes.com/category/1234/PARKAS.html',
   },
   {
      name: 'Rain Gear',
      image: RainGear,
      subcategories: [],
      link: 'https://shop.bahamajoes.com/category/1238/RAIN-GEAR.html',
   },
   {
      name: 'Vests',
      image: Vests,
      subcategories: [],
      link: 'https://shop.bahamajoes.com/category/1236/VESTS.html',
   },
   {
      name: 'Ball Caps',
      image: BallCaps,
      subcategories: [],
      link: 'https://shop.bahamajoes.com/category/1306/BALLCAPS.html',
   },

   {
      name: 'Beanies',
      image: Beanies,
      subcategories: [],
      link: 'https://shop.bahamajoes.com/category/32/BEANIES.html',
   },
   {
      name: 'Visors',
      image: Visors,
      subcategories: [],
      link: 'https://shop.bahamajoes.com/category/45/VISORS.html',
   },
   {
      name: 'Workwear',
      image: Workwear,
      subcategories: [],
      link: 'https://shop.bahamajoes.com/category/1250/WORKWEAR.html',
   },
   {
      name: 'Youth Apparel',
      image: YouthApparel,
      subcategories: [],
      link: 'https://shop.bahamajoes.com/category/58/Youth-Apparel.html',
   },
   {
      name: 'Aprons',
      image: Aprons,
      subcategories: [],
      link: 'https://shop.bahamajoes.com/category/29/WORK-APRONS.html',
   },
   {
      name: 'Camouflage Apparel',
      image: CamouflageApparel,
      subcategories: [],
      link: 'https://shop.bahamajoes.com/category/33/Camouflage-Apparel.html',
   },
   {
      name: 'SHOP ALL APPAREL',
      image: SHOPALLAPPAREL,
      subcategories: [],
      link: 'https://shop.bahamajoes.com/category/27/Apparel--and--Caps.html',
   },
];
