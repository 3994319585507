import React, { useState, useEffect, useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import moment from 'moment';
import { HOME_URL, API_URL } from '../../config';
import { AuthContext } from '../../context/auth-provider';
import Axios from 'axios';

const Wrapper = () => {
   return <>asd</>;
};

export const OrdersTab = ({ addresses, orders }) => {
   return (
      <>
         {orders.length === 0 && (
            <div className="center">
               <br />
               <br />
               <h5 style={{ fontSize: '2em' }}>No Orders</h5>
               <p>You don't have any orders yet</p>
               <Link to="https://bahamajoesuniforms.myshopify.com/" className="btn" onclick={() => window.location.href="https://bahamajoesuniforms.myshopify.com/"}>
                  Start Shopping
               </Link>
               <br />
               <br />
            </div>
         )}

         {orders.length > 0 && orders.filter((el) => el.items.length > 0).map((el, i) => <Order addresses={addresses} key={i} {...el} />)}
      </>
   );
};

const Order = ({ addresses, created, items, orderid, billingaddress, shippingaddress, ...rest }) => {
   const [[billing, shipping, loading], setAddresses] = useState([null, null, true]);

   const { token } = useContext(AuthContext);

   console.log({ items });

   useEffect(() => {
      // const shipping = addresses.filter((el) => el.orderid === orderid);
      // const billing = addresses.filter((el) => el.orderid === orderid);

      // if()

      const shipping_axios = Axios({
         method: 'GET',
         url: `${API_URL}users.cfc?method=getAddress&i=${shippingaddress}`,
         headers: {
            'Content-Type': 'application/json',
            Authorization: token,
         },
      })
         .then((r) => r.status === 200 && r.data)
         .then((d) => d.success && d.data[0]);

      const billing_axios = Axios({
         method: 'GET',
         url: `${API_URL}users.cfc?method=getAddress&i=${billingaddress}`,
         headers: {
            'Content-Type': 'application/json',
            Authorization: token,
         },
      })
         .then((r) => r.status === 200 && r.data)
         .then((d) => d.success && d.data[0]);

      setAddresses([null, null, true]);

      Axios.all([shipping_axios, billing_axios]).then(
         Axios.spread((shipping_d, billing_d) => {
            setAddresses([shipping_d, billing_d, false]);
         })
      );
   }, []);

   console.log({ shipping }, { billing });

   const restructureItems = (items) => {
      let new_items = [];
      let itemHolder = { id: 0 };

      items.forEach((item, key, arr) => {
         if (itemHolder.id != item.id) {
            // new item update
            itemHolder = { ...item };
            itemHolder.images = [item.image];
         } else {
            itemHolder.images = [...itemHolder.images, item.image];
         }

         console.log('for', item, item === arr[key + 1], key);
         new_items = itemHolder.id === item.id && arr[key + 1] && arr[key + 1].id != item.id ? [...new_items, itemHolder] : new_items;
      });

      new_items = [...new_items, itemHolder];

      console.log({ new_items });

      return new_items;
   };

   return (
      <div style={{ border: '1px solid lightgrey', marginBottom: '10px' }}>
         <div className="row" style={{ marginBottom: '0', borderBottom: 'solid 1px lightgrey' }}>
            <div className="col s12 m2" style={{ fontSize: '1em' }}>
               <p style={{ fontSize: 'inherit' }}>
                  <b>ORDER PLACED</b>
                  <br />
                  {moment(created).format('MMMM DD, YYYY')}
               </p>
            </div>
            <div className="col s12 m4 right-align" style={{ fontSize: '1em' }}>
               <p style={{ fontSize: 'inherit' }}>
                  <b>Shipping Address</b>
                  <br />
                  {shipping && shipping.firstname && shipping.firstname} {shipping && shipping.lastname && shipping.lastname} {shipping && shipping.address1 && shipping.address1}
               </p>
            </div>
            <div className="col s12 m4" style={{ fontSize: '1em' }}>
               <p style={{ fontSize: 'inherit' }}>
                  <b>Billing Address</b>
                  <br />
                  {billing && billing.firstname && billing.firstname} {billing && billing.lastname && billing.lastname} {billing && billing.address1 && billing.address1}
               </p>
            </div>
            <div className="col s12 m2" style={{ fontSize: '1em' }}>
               <p className="right-align" style={{ fontSize: 'inherit' }}>
                  <b>Order # : </b>
                  <span>{orderid}</span>
               </p>
            </div>
         </div>
         <div className="row" style={{ marginTop: '0' }}>
            <div className="col s12 m4 push-m8">&nbsp;</div>
            <div className="col s12 m8 pull-m4">
               {restructureItems(items).map((el, i) => (
                  <OrderItem key={i} {...el} />
               ))}
            </div>
         </div>
      </div>
   );
};

const OrderItem = ({ images, name, qty, price, orderid }) => {
   return (
      <>
         <div className="row">
            <div className="col s8 offset-s2 m3">
               {images.map((image, z) => (
                  <div className="col s12 m6">
                     <img src={`${HOME_URL}assets/products/medium/${image}`} alt="" className="responsive-img" />
                  </div>
               ))}
            </div>
            <div className="col s12 m9 store">
               <p>
                  {name}
                  {/* <br />
                  <button className="btn btn-small">Buy it again</button> */}
               </p>
            </div>
         </div>
      </>
   );
};

export default Wrapper;
