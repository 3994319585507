import React, { useContext, useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import M from "materialize-css";
import axios from "axios";
import CurrencyFormat from "react-currency-format";

import { CartContext } from "../../context/CartContext";
import { AuthContext } from "../../context/auth-provider";
import { API_URL } from "../../config";
import Modal from "../../components/Modal";
import { Form } from "../AccountPage/addresses";
import { AdminNav } from "../SchoolsPage";
import PgTitle from "./imgs/BJ-checkout.png";
import Axios from "axios";
import { stores as allStores } from "./";
import PaymentWrapper from "./cart.checkout.payment";

const round = (number, decimalPlaces) =>
  Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
const currencyAttr = {
  decimalScale: 2,
  fixedDecimalScale: true,
  displayType: "text",
  thousandSeparator: true,
  prefix: "$",
};

const AddAddressTrigger = ({ onSubmit }) => {
  const [shown, setShown] = React.useState(false);
  const modalRef = React.useRef(null);
  const buttonRef = React.useRef(null);
  const { token } = useContext(AuthContext);

  const showModal = () => {
    // console.log('trigger modal');
    setShown(true);
  };

  const closeModal = () => {
    // console.log('trigger modal');
    setShown(false);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 27) {
      closeModal();
    }
  };

  const onClickOutside = (e) => {
    if (modalRef && modalRef.current && modalRef.current.contains(e.target))
      return;
    // console.log(modalRef.current, e.target, modalRef.current.contains(e.target));
    closeModal();
  };

  const toggleScrollLock = () => {
    document.querySelector("html").classList.toggle("scroll-lock");
  };

  const doSubmit = (v) => {
    if (v.state === "") {
      M.toast({ html: "State is required", classes: "red white-text" });
      return;
    }
    onSubmit(v).then((d) => {
      // console.log({ d }, 'close modal now');
      d && d.success && closeModal();
    });
  };

  return (
    <>
      <button
        type="button"
        onClick={showModal}
        className="btn waves-effect waves-teal"
      >
        Create Address
      </button>
      {shown && (
        <Modal
          ref={[modalRef, buttonRef]}
          Form={Form}
          onSubmit={doSubmit}
          modalRef={(x) => (modalRef = x)}
          buttonRef={buttonRef}
          closeModal={closeModal}
          onKeyDown={onKeyDown}
          onClickOutside={onClickOutside}
        ></Modal>
      )}
    </>
  );
};

const EditAddressTrigger = ({
  onSubmit,
  className = "",
  prefill = null,
  onClick,
}) => {
  const [shown, setShown] = React.useState(false);
  const modalRef = React.useRef(null);
  const buttonRef = React.useRef(null);
  const { token } = useContext(AuthContext);

  const showModal = (e) => {
    e.preventDefault();
    // console.log('trigger modal');
    onClick && onClick(true);
    setShown(true);
  };

  const closeModal = () => {
    // console.log('trigger modal');
    onClick && onClick(false);
    setShown(false);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 27) {
      closeModal();
    }
  };

  const onClickOutside = (e) => {
    if (modalRef && modalRef.current && modalRef.current.contains(e.target))
      return;
    // console.log(modalRef.current, e.target, modalRef.current.contains(e.target));
    closeModal();
  };

  const toggleScrollLock = () => {
    document.querySelector("html").classList.toggle("scroll-lock");
  };

  const doSubmit = (v) => {
    if (v.state === "") {
      M.toast({ html: "State is required", classes: "red white-text" });
      return;
    }
    onSubmit(prefill ? { ...prefill, ...v } : v).then((d) => {
      d && d.success && closeModal();
    });
  };

  return (
    <>
      <a href="#" onClick={showModal} className={`link ${className}`}>
        Edit
      </a>
      {shown && (
        <Modal
          ref={[modalRef, buttonRef]}
          prefill={prefill}
          Form={Form}
          onSubmit={doSubmit}
          modalRef={(x) => (modalRef = x)}
          buttonRef={buttonRef}
          closeModal={closeModal}
          onKeyDown={onKeyDown}
          onClickOutside={onClickOutside}
        ></Modal>
      )}
    </>
  );
};

const SelectAddress = ({ onClick, onCreateAddress, forceClose }) => {
  const { user, token } = useContext(AuthContext);
  const [addresses, setAddresses] = useState(null);

  const getAddresses = () => {
    axios({
      method: "GET",
      url: `${API_URL}users.cfc?method=getAddresses`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((r) => r.status === 200 && r.data)
      .then(
        (d) =>
          d.success &&
          setAddresses(d.data.sort((a, b) => (a.id > b.id ? 1 : -1)))
      )
      .catch((e) => console.log({ e }));
  };

  useEffect(() => {
    if (user) {
      getAddresses();
    }
  }, []);

  const handleOnClick = (e, a) => {
    e.preventDefault();

    onClick(a);
  };

  const handleOnCreateAddress = (v) => {
    return onCreateAddress(v).then((r) => {
      forceClose();
      return r;
    });
  };

  const handleEditAddress = (v) => {
    return axios({
      url: `${API_URL}users.cfc?method=updateAddress`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      data: v,
    })
      .then((r) => r.status === 200 && r.data)
      .then((d) => {
        let results = {
          success: true,
        };

        if (d.success) {
          const toBeEdited = addresses.findIndex((e) => e.id === v.id);

          setAddresses(
            [...addresses.filter((e) => e.id !== v.id), v].sort((a, b) =>
              a.id > b.id ? 1 : -1
            )
          );
        } else {
          results.success = false;
        }

        return results;
      })
      .catch((e) => console.log("error updated database address", { e }));
  };

  return (
    <>
      <div className="collection">
        {addresses &&
          addresses.map((a, i) => (
            <div key={i} className="collection-item">
              <EditAddressTrigger
                className="right"
                onSubmit={handleEditAddress}
                prefill={a}
              />
              <a
                style={{ cursor: "pointer" }}
                href
                onClick={(e) => handleOnClick(e, a)}
                className="collection-sitem"
                key={i}
              >
                {displayAddress(a)}
              </a>
            </div>
          ))}
      </div>
      <AddAddressTrigger onSubmit={handleOnCreateAddress} />
      <button className="btn btn-small" onClick={forceClose}>
        Cancel
      </button>
    </>
  );
};

const displayAddress = (a) => {
  return (
    <>
      {a.company && (
        <>
          {a.company}
          <br />
        </>
      )}
      {a.firstname} {a.lastname}
      {a.address1 && (
        <>
          <br />
          {a.address1}
        </>
      )}
      {a.address2 && (
        <>
          <br />
          {a.address2}
        </>
      )}
      {`${a.city}${a.state}${a.zip}`.length > 0 && (
        <>
          <br />
          {a.city} {a.state}, {a.zip}
        </>
      )}
      {a.email && (
        <>
          <br />
          {a.email}
        </>
      )}
    </>
  );
};

const ShippingWrapper = ({ defaultAddress = null, onEdit }) => {
  const { user, token } = useContext(AuthContext);
  const {
    cartItems,
    shipping,
    setShipping,
    delivery,
    pickup,
    setPickup,
    setDelivery,
  } = useContext(CartContext);
  const [changeAddress, setChangeAddress] = useState(false);

  const onCreateAddress = (v) => {
    console.log("on  create address");
    onEdit(false);
    if (user) {
      return axios({
        url: `${API_URL}users.cfc?method=createAddress`,
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: token },
        data: v,
      })
        .then((r) => r.status === 200 && r.data)
        .then((d) => d.success && setShipping(v) && { success: true })
        .catch((e) => console.log("error adding new shipping address", { e }));
    } else {
      return new Promise((resolve, reject) => {
        const results = { success: true };
        setShipping(v);
        resolve(results);
      });
    }
  };

  const handleChangeAddress = (e) => {
    e.preventDefault();

    onEdit(true);
    setChangeAddress(true);
  };

  const handleAddressChange = (v) => {
    console.log("changing address");
    setShipping(v);
    onEdit(false);
    setChangeAddress(false);
  };

  useEffect(() => {
    console.log("defaulting setting");
    defaultAddress && setShipping(defaultAddress);
  }, [defaultAddress]);

  const handleChangePickup = (el) => {
    setPickup(el);
  };

  const handleSwitchToShip = (e) => {
    e.preventDefault();

    setDelivery(delivery === "ship" ? "pickup" : "ship");
  };

  const handleOnEdit = (e) => {
    onEdit(e);
  };

  useEffect(() => {
    const isEditing = () => {
      if (changeAddress) return true;
      if (delivery === "pickup") return false;
      if (delivery === "ship" && shipping) return false;
      return true;
    };

    onEdit(isEditing());
  }, []);

  useEffect(() => {
    if (delivery === "pickup") {
      onEdit(false);
    } else {
      onEdit(shipping ? false : true);
    }
  }, [delivery]);

  const stores = allStores(cartItems);

  const isStore = stores.reduce(
    (res, el) =>
      res === true ? true : JSON.stringify(pickup) === JSON.stringify(el),
    [false]
  );

  !isStore && stores.length > 0 && handleChangePickup(stores[0]);

  return (
    <>
      <div className="row">
        <div className="col s12">
          <h5 style={{ marginTop: "0" }}>
            {delivery === "pickup" && (
              <>
                <b>In Store Pickup</b>
                <a href onClick={handleSwitchToShip} className="right link">
                  Switch To Delivery
                </a>
              </>
            )}
            {delivery === "ship" && (
              <>
                <b>Shipping Address</b>
                {shipping && !changeAddress && (
                  <span style={{ paddingLeft: "10px" }}>
                    {!user && (
                      <EditAddressTrigger
                        onClick={handleOnEdit}
                        onSubmit={onCreateAddress}
                        prefill={shipping}
                      />
                    )}
                    {user && (
                      <a href onClick={handleChangeAddress} className="link">
                        Edit
                      </a>
                    )}
                  </span>
                )}
                {stores.length > 0 && (
                  <a
                    href="#"
                    onClick={handleSwitchToShip}
                    className="right link"
                  >
                    Switch To Pickup
                  </a>
                )}
              </>
            )}
          </h5>
        </div>
      </div>
      <div className="row">
        <div className="col s12">
          <div className="displayAddressDiv">
            {delivery === "pickup" && (
              <>
                {!changeAddress && (
                  <>
                    <div className="flex flex-btn">
                      {stores.map((el, i) => (
                        <div
                          key={i}
                          onClick={() => handleChangePickup(el)}
                          className={`card ${
                            JSON.stringify(pickup) === JSON.stringify(el)
                              ? "active"
                              : "hoverable"
                          }`}
                        >
                          <div className="card-content">
                            {pickup && (
                              <p style={{ marginTop: "0" }}>
                                {displayAddress(el)}
                              </p>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </>
            )}
            {delivery === "ship" && (
              <>
                {changeAddress && (
                  <SelectAddress
                    onClick={handleAddressChange}
                    onCreateAddress={onCreateAddress}
                    forceClose={() => setChangeAddress(false)}
                  />
                )}
                {!changeAddress && (
                  <>
                    {/* {!shipping && <AddAddressTrigger onSubmit={onCreateAddress} />} */}
                    {!shipping && <Form onSubmit={onCreateAddress} />}
                    {shipping && (
                      <p style={{ marginTop: "0" }}>
                        {displayAddress(shipping)}
                      </p>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const BillingWrapper = ({ onEdit }) => {
  const { user, token } = useContext(AuthContext);
  const { billing, shipping, setBilling, delivery } = useContext(CartContext);
  const [changeAddress, setChangeAddress] = useState(false);
  const [sameAsShipping, setSameAsShipping] = useState(false);

  const onCreateAddress = (v) => {
    onEdit(false);

    if (user) {
      return axios({
        url: `${API_URL}users.cfc?method=createAddress`,
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: token },
        data: v,
      })
        .then((r) => r.status === 200 && r.data)
        .then((d) => d.success && setBilling(v) && { success: true })
        .catch((e) => console.log("error adding new shipping address", { e }));
    } else {
      return new Promise((resolve, reject) => {
        const results = { success: true };
        setBilling(v);
        resolve(results);
      });
    }
  };

  const handleChangeAddress = (e) => {
    e.preventDefault();

    onEdit(true);
    setChangeAddress(true);
  };

  const handleAddressChange = (v) => {
    setBilling(v);
    onEdit(false);
    setChangeAddress(false);
  };

  const handleSameAsShipping = () => {
    setBilling(shipping);
  };

  sameAsShipping && setBilling(shipping);

  const handleOnEdit = (e) => onEdit(e);

  useEffect(() => {
    const isEditing = () => {
      if (changeAddress) return true;
      if (billing) return false;
      return true;
    };

    onEdit(isEditing());
  }, []);

  return (
    <>
      <div className="row">
        <div className="col s12">
          <h5 style={{ marginTop: "0" }}>
            <b>Billing address</b>
            {billing && !changeAddress && (
              <span style={{ paddingLeft: "10px" }}>
                {!user && (
                  <EditAddressTrigger
                    onClick={handleOnEdit}
                    onSubmit={onCreateAddress}
                    prefill={billing}
                  />
                )}
                {user && (
                  <a href onClick={handleChangeAddress} className="link">
                    Edit
                  </a>
                )}
              </span>
            )}
          </h5>
        </div>
      </div>
      <div className="row">
        <div className="col s12">
          <div className="displayAddressDiv">
            {changeAddress && (
              <SelectAddress
                onClick={handleAddressChange}
                onCreateAddress={onCreateAddress}
                forceClose={() => setChangeAddress(false)}
              />
            )}
            {!changeAddress && (
              <>
                {!billing && (
                  <>
                    {shipping && delivery === "ship" && (
                      <button
                        onClick={handleSameAsShipping}
                        className="btn btn-small"
                      >
                        Same as shipping
                      </button>
                    )}
                    <AddAddressTrigger onSubmit={onCreateAddress} />
                  </>
                )}
                {billing && (
                  <p style={{ marginTop: "0" }}>{displayAddress(billing)}</p>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const CartWrapper = () => {
  const { cartItems, shipping, itemCount, cost, ...rest } =
    useContext(CartContext);

  const [showItems, setShowItems] = useState(false);

  const navigate = useNavigate();

  if (!cartItems || cartItems.length === 0) {
    M.toast({ html: "No items in your cart" });
    navigate("https://bahamajoesuniforms.myshopify.com/");
  }

  const getImage = (product) => {
    const image = product.attributes.filter(
      (element) => element.name.toLowerCase() === "color"
    );
    if (image.length > 0) return image[0].image;
    return product.images[0].image;
  };

  return (
    <>
      <div className="row">
        <div className="col s12">
          <h5 style={{ marginTop: "0" }}>
            <b>Your cart</b>
            <span className="right">
              <Link to="/cart">Edit cart</Link>
            </span>
          </h5>
        </div>
        <div className="col s12">
          <div className="collection">
            <div className="collection-item grey">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => setShowItems(!showItems)}
              >
                Total{" "}
                <CurrencyFormat
                  {...currencyAttr}
                  value={round(cost.grandTotal, 2)}
                />
                {` : `}
                {itemCount} item{itemCount > 1 && "s"}
              </div>
              {showItems && (
                <>
                  {cartItems.map((item, i) => (
                    <div
                      key={i}
                      className="card horizontal z-depth-1 small-cart"
                    >
                      <div className="card-image">
                        <img
                          src={`/assets/products/medium/${getImage(item)}`}
                          style={{ maxHeight: "100px" }}
                        />
                      </div>
                      <div className="card-stacked">
                        <div className="card-content">
                          <p>
                            <b>{item.name}</b>
                          </p>
                        </div>
                        <div className="card-action">
                          <span className="qt">{item.quantity}</span>
                          <span className="full-price right">
                            <CurrencyFormat
                              {...currencyAttr}
                              value={round(item.quantity * item.price, 2)}
                            />
                          </span>
                          <span className="price right">
                            <CurrencyFormat
                              {...currencyAttr}
                              value={round(item.price, 2)}
                            />{" "}
                            Unit Price
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="white" style={{ padding: ".1px 10px" }}>
                    <p>
                      Merchandise Subtotal{" "}
                      <span className="right">
                        <CurrencyFormat
                          {...currencyAttr}
                          value={round(cost.total, 2)}
                        />
                      </span>
                    </p>
                    {cost.shipping > 0 && (
                      <p>
                        Shipping
                        <span className="right">
                          <CurrencyFormat
                            {...currencyAttr}
                            value={round(cost.shipping, 2)}
                          />
                        </span>
                      </p>
                    )}
                    {cost.tax > 0 && (
                      <p>
                        Tax
                        <span className="right">
                          <CurrencyFormat
                            {...currencyAttr}
                            value={round(cost.tax, 2)}
                          />
                        </span>
                      </p>
                    )}
                    <p>
                      Total:{" "}
                      <span className="right">
                        <CurrencyFormat
                          {...currencyAttr}
                          value={round(cost.grandTotal, 2)}
                        />
                      </span>
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const createRandomToken = (length = 10) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;

  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

const FormWrapper = () => {
  const [defaultAddress, setDefaultAddress] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [eShipping, setEShipping] = useState(true);
  const [eBilling, setEBilling] = useState(true);
  const [ePayment, setEPayment] = useState(true);
  const [randomToken, setToken] = useState(createRandomToken());
  const { user, token } = useContext(AuthContext);
  const {
    cartItems,
    clear,
    cost,
    shipping,
    billing,
    payment,
    setPayment,
    delivery,
    pickup,
  } = useContext(CartContext);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchDefaultAddress = () => {
      axios({
        method: "GET",
        url: `${API_URL}users.cfc?method=getDefaultAddress`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
        .then((r) => r.status === 200 && r.data)
        .then((d) => d.success && setDefaultAddress(d.data[0]))
        .catch((e) => console.log({ e }));
    };

    if (user) {
      fetchDefaultAddress();
    }
  }, []);
  const sendUserEmail = async (order, cost, shipping, billing) => {
    const domainName = 'https://bahamajoes.com/api';
    const localhost = 'http://localhost:3360';
    try {
      await axios.post(`${domainName}/users/send-user-email`, {
        user: user,
        order: order,
        cost,
        shipping,
        billing,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const sendEmailEMS = async (order, cost, shipping, billing) => {
    const domainName = 'https://bahamajoes.com/api';
    const localhost = 'http://localhost:3360';
    try {
      await axios.post(`${domainName}/users/send-EMS-email`, {
        order,
        cost,
        shipping,
        billing,
        user,
      });
      sendUserEmail(order, cost, shipping, billing);
    } catch (error) {
      console.log(error);
    }
  };

  const doProcessOrder = () => {
    // console.log({ shipping });
    // console.log({ billing });
    // console.log({ payment });

    if (processing) return;

    setProcessing(true);

    Axios({
      url: `${API_URL}_orders.cfc?method=process`,
      // url: "https://bahamajoes.com/api/users/confirm-order",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        user,
        randomToken,
        shipping,
        billing,
        cartItems,
        cost,
        payment: {
          token: payment.token,
        },
        delivery,
        pickup,
      },
    })
      .then((r) => r.status === 200 && r.data)
      .then((d) => {
      
      if (
          (cartItems && cartItems[0].school == "Chatham Fire Department") ||
          (cartItems && cartItems[0].school == "Chatham County EMS")
        ) {
          sendEmailEMS(d, cost, shipping, billing);
          return;
        }
        // console.log({ d }, 'create new card token', { payment });

        if (d.transaction.success && d.order.success) {
          // order paid and saved
          const saveOrderLocal = async () => {
            const getLocalOrders = () => localStorage.getItem('orders');
            let orders = JSON.parse(await getLocalOrders());

            if (orders) {
              orders.push({
                user,
                shipping,
                billing,
                cartItems,
                cost,
                payment: {
                  token: payment.token,
                },
                order: {
                  ...d,
                },
              });
            } else {
              orders = [
                {
                  user,
                  shipping,
                  billing,
                  cartItems,
                  cost,
                  payment: {
                    token: payment.token,
                  },
                  order: {
                    ...d,
                  },
                },
              ];
            }

            localStorage.setItem('orders', JSON.stringify(orders));
            clear();
            // navigate(`/cart.confirmation/${d.orderid}`);
            navigate(`/cart.confirmation/${d.order.orderid}`);
            setProcessing(false);
          };

          saveOrderLocal();
        } else {
          if (!d.transaction.success) {
            // payment failed
            d.transaction.message.forEach((mes) => {
              M.toast({
                html: mes,
              });
            });
          }
        }

        return;

        // if (d.transaction.errors && d.transaction.errors.length > 0) {
        //    d.transaction.errors.map((d) => {
        //       let message = d.message;
        //       message = message.replace('card.cvc', 'CVC');
        //       message = message.replace('card.ExpirationMonth/ExpirationYear', 'Expiration date');
        //       message = message.replace('card.number', 'Card Number');
        //       message = message.indexOf('token') > -1 ? 'Unable to process transaction, please confirm your payment information' : message;
        //       M.toast({ html: message, classes: 'red white-text' });
        //    });
        //    return;
        // }

        const saveOrderLocal = async () => {
          const getLocalOrders = () => localStorage.getItem("orders");
          let orders = JSON.parse(await getLocalOrders());

          if (orders) {
            orders.push({
              user,
              shipping,
              billing,
              cartItems,
              cost,
              payment: {
                token: payment.token,
              },
              order: {
                ...d,
              },
            });
          } else {
            orders = [
              {
                user,
                shipping,
                billing,
                cartItems,
                cost,
                payment: {
                  token: payment.token,
                },
                order: {
                  ...d,
                },
              },
            ];
          }
          localStorage.setItem("orders", JSON.stringify(orders));
          clear();
          // navigate(`/cart.confirmation/${d.orderid}`);
          navigate(`/cart.confirmation/${d.SAVEDORDER.orderid}`);
          setProcessing(false);
        };

        saveOrderLocal();
      })
      .catch((e) => console.log({ e }))
      .finally((d) => setProcessing(false));
  };

  useEffect(() => {
    const checkReady = () => {
      console.log(eShipping, eBilling, ePayment);
      if (eShipping || eBilling || ePayment) return false;

      if (!shipping && delivery === "ship") return false;
      if (!billing) return false;
      if (!payment) return false;

      return true;
      // return (shipping && billing && payment) || (billing && payment && delivery && pickup) ? true : false;
    };

    setIsReady(checkReady());
  }, [
    shipping,
    billing,
    payment,
    delivery,
    pickup,
    eShipping,
    eBilling,
    ePayment,
  ]);

  const handleOnEdit = (i, e) => {
    if (i === 0) setEShipping(e);
    if (i === 1) setEBilling(e);
    if (i === 2) setEPayment(e);
  };

  return (
    <>
      <div className="center-align">
        <br />
        <br />
        <img src={PgTitle} />
        <br />
        <br />
      </div>
      <AdminNav />
      <div className="container" style={{ marginTop: "20px" }}>
        <div className="row">
          <div className="col s12 m10 offset-m1 l8 offset-l2">
            <div className="cart-wrapper">
              <CartWrapper />
            </div>
            <hr />
            <div className="shipping-address">
              <ShippingWrapper
                defaultAddress={defaultAddress}
                onEdit={(e) => handleOnEdit(0, e)}
              />
            </div>
            <hr />
            <div className="billing-address">
              <BillingWrapper
                defaultAddress={defaultAddress}
                onEdit={(e) => handleOnEdit(1, e)}
              />
            </div>
            <hr />
            <div className="payment-container">
              <PaymentWrapper onEdit={(e) => handleOnEdit(2, e)} />
            </div>
            <hr />
            <div className="action-buttons center-align">
              {processing ? (
                <button
                  style={{ width: "100px" }}
                  disabled
                  className="btn"
                  type="button"
                  onClick={doProcessOrder}
                >
                  <div className="progress">
                    <div className="indeterminate"></div>
                  </div>
                </button>
              ) : (
                <button
                  className="btn"
                  type="button"
                  onClick={doProcessOrder}
                  disabled={!isReady}
                >
                  Confirm Order
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Select = React.memo(({ register, name, label, error, options }) => {
  const selected = options.reduce(
    (tot, el) => el.options && el.options.select,
    ""
  );

  useEffect(() => {
    M.AutoInit();
  }, [options, selected]);

  return (
    <>
      <select
        ref={register && register}
        id={name}
        name={name}
        defaultValue={selected}
      >
        {options.map((el, i) => (
          <option key={i} value={el.value} {...el.options}>
            {el.label}
          </option>
        ))}
      </select>
      <label htmlFor={name}>{label}</label>
      <span className="red-text helper-text">{error && error.message}</span>
    </>
  );
});

const Input = React.memo(
  ({ register, name, label, error, type = "text", ...rest }) => {
    return (
      <>
        <input
          ref={register && register}
          id={name}
          name={name}
          type={type}
          {...rest}
        />
        <label className="active" htmlFor={name}>
          {label}
        </label>
        <span className="red-text helper-text">{error && error.message}</span>
      </>
    );
  }
);

const cardType = (number) => {
  // visa
  var re = new RegExp("^4");
  if (number.match(re) != null) return "Visa";

  // Mastercard
  // Updated for Mastercard 2017 BINs expansion
  if (
    /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
      number
    )
  )
    return "Mastercard";

  // AMEX
  re = new RegExp("^3[47]");
  if (number.match(re) != null) return "AMEX";

  // Discover
  re = new RegExp(
    "^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)"
  );
  if (number.match(re) != null) return "Discover";

  // Diners
  re = new RegExp("^36");
  if (number.match(re) != null) return "Diners";

  // Diners - Carte Blanche
  re = new RegExp("^30[0-5]");
  if (number.match(re) != null) return "Diners - Carte Blanche";

  // JCB
  re = new RegExp("^35(2[89]|[3-8][0-9])");
  if (number.match(re) != null) return "JCB";

  // Visa Electron
  re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
  if (number.match(re) != null) return "Visa Electron";

  return "Card";
};

export default FormWrapper;
