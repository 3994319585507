import React from 'react';
import { Link } from 'react-router-dom';

const topStyle = {
	position: 'absolute',
	border: '0',
	boxShadow: '0 0 0',
};

function index(props) {
	return (
		<div className="container">
			<header className="row" style={{ position: 'relative' }}>
				<nav className="transparent" style={topStyle}>
					<React.Fragment>
						<Link className="btn right btn-small white-text signup black-text" to="/login">
							HAULER LOGIN/REGISTRATION
						</Link>
					</React.Fragment>
				</nav>
			</header>
			<header className="row" style={{ position: 'relative' }}>
				<nav className="transparent" style={topStyle}>
					<React.Fragment>
						<Link className="btn right btn-small white-text signup black-text" to="/authenticated">
							auth
						</Link>
					</React.Fragment>
				</nav>
			</header>
		</div>
	);
}

export default index;
