import React, { createContext, useReducer, useEffect } from 'react';
import { createReducer, initialState } from './AuthReducer';
import { API_URL } from '../../config';
import Axios from 'axios';
import { setWithExpiry } from '../tokens';
import { CONST_USER, CONST_TOKEN, CONST_GUEST } from './keys';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // const memorizedReducer = useCallback(() => createReducer(), []);
  const [state, dispatch] = useReducer(createReducer(), initialState);

  useEffect(() => {
    setWithExpiry(CONST_USER, state.user ? state.user : null);
    setWithExpiry(CONST_TOKEN, state.token && state.token.length > 0 ? state.token : '');
    setWithExpiry(CONST_GUEST, state.continueAsGuest ? state.continueAsGuest : false);
  }, [state.user, state.token, state.continueAsGuest]);

  useEffect(() => {
    const doCloseDispatch = () => dispatch({ type: 'HIDE_LOGIN_FORM' });

    state.form.open && document.querySelectorAll('.modal-overlay')[0] && document.querySelectorAll('.modal-overlay')[0].addEventListener('click', doCloseDispatch);

    !state.form.open && document.querySelectorAll('.modal-overlay')[0] && document.querySelectorAll('.modal-overlay')[0].removeEventListener('click', doCloseDispatch);
  }, [state.form]);

  const login = async (payload) => {
    try {
      dispatch({ type: 'REQUEST_LOGIN' });

      return Axios({
        url: `${API_URL}users.cfc?method=login`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: btoa(`${payload.username}:${payload.password}`),
        },
      })
        .then((r) => r.status === 200 && r.data)
        .then((payload) => {
          console.log({ payload }, 'axios return');
          if (payload.user && payload.token) {
            dispatch({ type: 'LOGIN_SUCCESS', payload });
            return payload;
          }
        })
        .catch((error) => {
          dispatch({ type: 'LOGIN_ERROR', error });
          console.log(error);
        });
    } catch (error) {
      dispatch({ type: 'LOGIN_ERROR', error });
      console.log(error);
    }
  };

  const startReset = async (payload) => {
    try {
      dispatch({ type: 'REQUEST_START_RESET' });

      return Axios({
        url: `${API_URL}users.cfc?method=startReset`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: btoa(`${payload.username}:chimney`),
        },
      })
        .then((r) => r.status === 200 && r.data)
        .then((payload) => {
          console.log({ payload }, 'axios return');
          if (payload.success) {
            dispatch({ type: 'START_RESET_SUCCESS', payload });
            return payload;
          }
        })
        .catch((error) => {
          dispatch({ type: 'START_RESET_ERROR', error });
          console.log(error);
        });
    } catch (error) {
      dispatch({ type: 'START_RESET_ERROR', error });
      console.log(error);
    }
  };

  const handleReset = async (payload) => {
    try {
      dispatch({ type: 'REQUEST_DO_START' });

      return Axios({
        url: `${API_URL}users.cfc?method=handleReset`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: payload,
      })
        .then((r) => r.status === 200 && r.data)
        .then((payload) => {
          console.log({ payload }, 'axios return');
          if (payload.success) {
            dispatch({ type: 'START_DO_RESET', payload });
            return payload;
          }
        })
        .catch((error) => {
          dispatch({ type: 'START_DO_RESET_ERROR', error });
          console.log(error);
        });
    } catch (error) {
      dispatch({ type: 'START_DO_RESET_ERROR', error });
      console.log(error);
    }
  };

  const logout = (payload) => {
    dispatch({ type: 'LOGOUT' });
    localStorage.removeItem(CONST_USER);
    localStorage.removeItem(CONST_TOKEN);
    localStorage.removeItem("EMS")
    localStorage.removeItem("EMS_EMAIL");
  };

  const showLoginForm = () => {
    dispatch({ type: 'SHOW_LOGIN_FORM' });
  };

  const hideLoginForm = () => {
    dispatch({ type: 'HIDE_LOGIN_FORM' });
  };

  const setContinueAsGuest = () => {
    dispatch({ type: 'CONTINUE_AS_GUEST' });
  };

  const contextValues = {
    login,
    logout,
    startReset,
    handleReset,
    showLoginForm,
    hideLoginForm,
    setContinueAsGuest,
    ...state,
  };

  return <AuthContext.Provider value={contextValues}>{children}</AuthContext.Provider>;
};
