import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import pet001 from './PC54_ash_model_front_102016.jpg';
import pet002 from './DM476_blackhthrcharcoal_model_front_012016.jpg';
import pet003 from './ST350LS_white_model_front_072014.jpg';
import pet004 from './DT137L_white_model_front_112017.jpg';
import pet005 from './its_1944_5050.jpg';
import topImage from './mock/BJoe-TopImage.jpg';
import btmImage from './bottom-image-embroidery.jpg';
import custom from './custom.jpg';
import ready from './READY.png';
import { ReadyToOrder } from '../ScreenPrintingPage';

import { categories } from './mock';
import { Helmet } from 'react-helmet';

const Holder = () => {
  const [category, setCategory] = useState('');

  useEffect(() => {
    setCategory('');
  }, []);

  return (
    <>
      <Helmet>
        <title>Embroidery : Bahama Joe&apos;s</title>
      </Helmet>
      <br />
      <br />
      <div className="container">
        <div className="center-align">
          <img src={custom} className="responsive-img" />
        </div>

        <div className="row">
          <div className="col s12 m12 l8 offset-l2 center-align">
            <h2>STAND OUT FROM THE CROWD...</h2>
            <p style={{ margin: '20px' }}>
              and make a statement with custom embroidered apparel from Bahama
              Joe’s. We offer thousands of products that can be custom
              embroidered, from hoodies to rain gear, as well as a full art
              department to help you get started.
            </p>
          </div>
        </div>
        <br />
        <div className="row">
          {!category &&
            categories.map((category, i) => (
              <CategoryLink category={category} onClick={setCategory} />
            ))}
          {category &&
            categories
              .filter((x) => x.name === category)[0]
              .subcategories.map((category, i) => (
                <ProductsLink
                  category={category}
                  clabels={['mens', 'ladies', 'youth', 'boys', 'girls']}
                />
              ))}
        </div>
      </div>
      <div
        className="white-text center-align"
        style={{ backgroundColor: 'rgb(54, 54, 54)' }}
      >
        <div className="container">
          <div className="row">
            <div className="col s12 m12 l8 offset-l2">
              <br />
              <h4 style={{ marginBottom: '0' }}>
                Bahama Joe's offeres thousands of blank products.
              </h4>
              <p style={{ marginTop: '0' }}>
                Select the perfect items to apply your custom design or business
                logo. All our apparel can be customized to fit your vision. We
                specialize in custom screen printing and custom embroidery and
                offer fast turn around times. Select from t-shirts, outerwear,
                hats, pants, athletic apparel, uniforms and more! We have
                customizable apparel available for all occasions and budgets.
                All of our custom apparel is made to order and produced in
                Savannah, Georgia.
              </p>
              <div className="center-align">
                <a
                  href="https://shop.bahamajoes.com/category/26/Everything-Else.html"
                  className="btn amber grey-text text-darken-3"
                >
                  See all products
                </a>
              </div>
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
      <ReadyToOrder />
      <div className="white-text center-align bahama-yellow grey-text text-darken-3">
        <div className="container">
          <div className="row" style={{ marginBottom: '0' }}>
            <div className="col s12 m12 l8 offset-l2">
              <br />
              <h4 style={{ marginBottom: '0' }}>
                Your order is ready, on-time and without surprises. Because we
                are Savannah's custom embroidery experts
              </h4>
              <p style={{ marginTop: '0' }}>
                For more than 18 years, Bahama Joe’s has been providing Savannah
                with quality custom embroidery products. We specialize in
                helping customers build custom embroidery designs with lasting
                quality, so logos and designs do not fade or breakdown over
                time.
              </p>
              <p style={{ marginTop: '0' }}>
                Bahama Joe’s take pride in producing quality designs for our
                customers that they are proud to share and wear their custom
                apparel. Our custom embroidery experts have over 75 years of
                combine custom embroidery experience and take a hand-on-approach
                to personally work with clients. We allow our customers to come
                in and proof our stitching before we produce mass custom
                embroidery orders.
              </p>
              <br />
            </div>
          </div>
        </div>
      </div>
      <img src={btmImage} style={{ width: '100%' }} />
    </>
  );
};

const CategoryLink = ({ category, onClick }) => {
  return (
    <>
      <div
        className="col s6 m4 l3 center-align"
        style={{ marginBottom: '20px' }}
      >
        <div className="center-align ssquare" style={{ position: 'relative' }}>
          <div style={{}}>
            <a href={category.link}>
              <img
                src={category.image}
                className="responsive-img productImgAdj"
                alt=""
              />
            </a>
            <div
              className="valign-wrapper center-align"
              style={{ height: '60px' }}
            >
              <h5 style={{ width: '100%' }} className="center-align">
                {category.name}
              </h5>
            </div>
          </div>
        </div>
        <a
          className="btn btn-small amber grey-text text-darken-3"
          href={category.link}
        >
          LET'S GO
        </a>
      </div>
    </>
  );
};

const ProductsLink = ({ category, clabels }) => {
  const defaultActive = clabels.reduce(
    (total, x) => (total === '' && x in category ? x : total),
    ''
  );
  const [active, setActive] = useState(defaultActive);

  if (!category[active]) {
    return <></>;
  }

  return (
    <>
      <div className="col s12 m3 center-align">
        <img
          src={category[active].image}
          style={{ maxHeight: '250px' }}
          alt=""
        />
        <h4>{category.name}</h4>
        <div className="row">
          <div className="col s12 center-align">
            {clabels.map((c) => (
              <>
                {c in category && (
                  <a
                    href={category[c].link}
                    onMouseEnter={() => setActive(c)}
                    onMouseLeave={() => setActive(defaultActive)}
                    className="btn btn-small amber grey-text text-darken-3"
                  >
                    {c}
                  </a>
                )}
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Holder;
