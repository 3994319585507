//Store
import { configureStore } from "@reduxjs/toolkit";

//Reducers
import { counterReducer } from "../features/counter/counterSlice";
import { userEmailReducer } from "../features/userEmail/userEmailSlice";

export const store = configureStore({
	reducer: {
		counter: counterReducer,
		userEmail: userEmailReducer,
	},
});
