import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import heroImg from "./hero.jpg";
import embroidery from "./embroidery.png";
// import unifrom from "./BJ-CTA-Unform15.png";
import unifrom from "./unifrom.png";
import screenprint from "./screenprint.png";
import storefront from "./storefront.jpg";
import good from "./good.jpg";
import brand01 from "./Cornerstone-bw.jpg";
import brand02 from "./DistrictMade-bw.jpg";
import brand03 from "./EddieBauer-bw.jpg";
import brand04 from "./FruitOfTheLoom-bw.jpg";
import brand05 from "./Gildan-bw.jpg";
import brand06 from "./Jerzees-bw.jpg";
import brand07 from "./NewEra-bw.jpg";
import brand08 from "./NikeGolf-bw.jpg";
import brand09 from "./Ogio-bw.jpg";
import brand010 from "./PortAuthority-bw.jpg";
import brand011 from "./RedKap-bw.jpg";
import crabhat from "./crab-hat.png";
import testimologo01 from "./crab-logo-bw.jpg";
import testimologo02 from "./Weyerhaeuser-bw.jpg";
import testimologo03 from "./SavFireDept-bw.jpg";
import testimologo04 from "./MacAljon-bw.jpg";

import heroSlider01 from "./BJ-Hero-TEE.jpg";
import heroSlider01Txt from "./TEES.png";
import heroSlider02 from "./BJ-Hero-DESIGN.jpg";
import heroSlider02Txt from "./DESIGN.png";
import heroSlider03 from "./BJ-Hero-UNIFORM-SALE.jpg";
// import heroSlider03 from './BJ-Hero-UNIFORM.jpg';
import heroSlider03Txt from "./UNIFORMS.png";
// BJ-Hero-UNIFORM-SALE.jpg
import heroSlider04 from "./BJ-Hero-POLO.jpg";
import heroSlider04Txt from "./POLOS.png";
import heroSlider05 from "./BJ-Hero-MASK.jpg";
import heroSlider05Txt from "./MASK.png";
import heroSlider06_new from "./BJ-Hero-CAP_new.jpg";
import heroSlider06Txt from "./CAP.png";
import { Helmet } from "react-helmet";

const heroImage01 = {
	backgroundImage: "url(" + heroSlider01 + ")",
	backgroundPosition: "center",
	backgroundRepeat: "no-repeat",
	backgroundSize: "cover",
	position: "relative",
};

const heroImage02 = {
	backgroundImage: "url(" + heroSlider02 + ")",
	backgroundPosition: "center",
	backgroundRepeat: "no-repeat",
	backgroundSize: "cover",
	position: "relative",
};

const heroImage03 = {
	backgroundImage: "url(" + heroSlider03 + ")",
	backgroundPosition: "center",
	backgroundRepeat: "no-repeat",
	backgroundSize: "cover",
	position: "relative",
};

const heroImage04 = {
	backgroundImage: "url(" + heroSlider04 + ")",
	backgroundPosition: "center",
	backgroundRepeat: "no-repeat",
	backgroundSize: "cover",
	position: "relative",
};

const heroImage05 = {
	backgroundImage: "url(" + heroSlider05 + ")",
	backgroundPosition: "center",
	backgroundRepeat: "no-repeat",
	backgroundSize: "cover",
	position: "relative",
};

const heroImage06 = {
	// backgroundImage: 'url(/BJ-Hero-CAP_new.2458a154.jpg
	backgroundImage: "url(" + heroSlider06_new + ")",
	backgroundPosition: "center",
	backgroundRepeat: "no-repeat",
	backgroundSize: "cover",
	position: "relative",
};
const HomePage = () => {
	var settings = {
		dots: true,
		arrows: false,
		infinite: true,
		speed: 500,
		slidesToShow: 5,
		responsive: [
			{
				breakpoint: 700,
				settings: {
					slidesToShow: 2,
				},
			},
		],
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 2000,
	};

	var settings02 = {
		dots: true,
		arrows: false,
		infinite: true,
		speed: 1000,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 5000,
	};
	const settings03 = {
		dots: true,
		arrows: false,
		infinite: true,
		arrows: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 5000,
	};

	return (
		<div className="store">
			<Helmet>
				<title>Bahama Joe&apos;s - Your Vision, Our Products</title>
			</Helmet>
			<Slider {...settings03}>
				<div>
					<div className="heroImage01">
						<div className="container row">
							<div className="col s12 m6 offset-m6 l6 offset-l6 center">
								<div className="hero-text white-text heroPadAdj">
									<img
										src={heroSlider01Txt}
										className="heroTxtHeader"
									/>
									<p className="heroSmallTxtAdj">
										CUSTOM SCREENPRINTED TEES
										<br />
										Looking for a creative way to promote
										your business or event? A custom screen
										printed t-shirt is the best place to
										start!
									</p>
									<br />
									<Link
										to="/ScreenPrinting"
										className="btn btn-large"
									>
										LET'S GO!
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div className="heroImage02">
						<div className="container row">
							<div className="col s12 m6 offset-m6 l6 offset-l6 center">
								<div className="hero-text white-text heroPadAdj">
									<img
										src={heroSlider02Txt}
										className="heroTxtHeader"
									/>
									<p className="heroSmallTxtAdj">
										DESIGN YOUR OWN SHIRTS
										<br />
										And hats, and bags, and hoodies, and
										anything else that you can think of.
										<br />
										Check out our design studio!
									</p>
									<br />
									<Link
										to="/ScreenPrinting"
										className="btn btn-large"
									>
										LET'S GO!
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <div>
					<div className="heroImage03">
						<div className="container row">
							<div className="col s12 m6 offset-m6 l6 offset-l6 center">
								<div className="hero-text white-text heroPadAdj">
									<img
										src={heroSlider03Txt}
										className="heroTxtHeader"
									/>
									<p className="heroSmallTxtAdj">
										WE HAVE THEM ALL
										<br />
										Specialty uniforms for a variety of
										schools in the greater Savannah area and
										beyond. Guaranteed fast turnaround!
									</p>
									<br />
									<Link
										to="/School-Uniforms"
										className="btn btn-large"
									>
										LET'S GO!
									</Link>
								</div>
							</div>
						</div>
					</div>
	</div> */ }
				<div>
					<div className="heroImage04">
						<div className="container row">
							<div className="col s12 m6 offset-m6 l6 offset-l6 center">
								<div className="hero-text white-text heroPadAdj">
									<img
										src={heroSlider04Txt}
										className="heroTxtHeader"
									/>
									<p className="heroSmallTxtAdj">
										FOR ALL OCCASIONS
										<br />
										We offer polos for work, comfort,
										fashion, and athletics. Short sleeve,
										long sleeve, performance, golf, fashion,
										and more!
									</p>
									<br />
									<Link
										to="/Embroidery"
										className="btn btn-large"
									>
										LET'S GO!
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* <div>
					<div className="heroImage05">
						<div className="container row">
							<div className="col s12 m6 offset-m6 l6 offset-l6 center">
								<div className="hero-text white-text heroPadAdj">
									<img src={heroSlider05Txt} className="heroTxtHeader" />
									<p className="heroSmallTxtAdj">
										REUSABLE AND WASHABLE
										<br />
										CDC recommends using cloth face covers to prevent the spread
										of COVID-19. 50 Cotton/50 Poly Blend. In stock and ready to
										ship.
									</p>
									<br />
									<a
										href="https://bahamajoes.shop/covid-19-FaceCovers/shop/home"
										target="_blank"
										className="btn btn-large"
									>
										LET'S GO!
									</a>
								</div>
							</div>
						</div>
					</div>
				</div> */}
				<div>
					<div className="heroImage06">
						<div className="container row">
							<div className="col s12 m6 offset-m6 l6 offset-l6 center">
								<div className="hero-text white-text heroPadAdj">
									<p className="heroSmallTxtAdj">
										<img
											src={heroSlider06Txt}
											className="heroTxtHeader"
										/>
										CUSTOMIZED HEADWARE
										<br />
										Ballcaps and hats for you to add your
										custom logo. All of our ballcap styles
										can be customized to fit your vision.
									</p>
									<br />
									<a
										href="https://shop.bahamajoes.com/category/664/Headwear.html"
										className="btn btn-large"
									>
										LET'S GO!
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Slider>
			{/* <div className="heroImgAdj" style={heroImage}>
				<div className="container row">
					<div className="col s12 m6 offset-m6 l6 offset-l6 center">
						<div className="hero-text white-text heroPadAdj">
							<h1 className="heroBigTxtAdj">Get Creative!</h1>
							<p className="heroSmallTxtAdj">
								DESIGN YOUR OWN SHIRTS...
								<br />
								And hats. And anything else that you can think of. dolor sit
								amet, consectetur adipiscing elit, sed do eiusmod tempor
								incididunt ut labore et dolore magna aliqua. commodo viverra
								maecenas accumsan lacus vel
							</p>
							<br />
							<Link to="/login" className="btn btn-large">
								LET'S GO!
							</Link>
						</div>
					</div>
				</div>
			</div> */}
			<div>
				<div className="container center" style={{ marginTop: "50px" }}>
					<div className="row" style={{ marginBottom: "0px" }}>
						<Section1
							link="https://bahamajoesuniforms.myshopify.com/"
							title="SCHOOL UNIFORMS"
							onclick={() => window.location.href="https://bahamajoesuniforms.myshopify.com/"}
							body="15 specialty uniforms in the greater Savannah area and beyond for students to shop all styles and sizes available."
							image={unifrom}
						/>
						<Section1
							link="/Embroidery"
							title="CUSTOM EMBROIDERY"
							body="We offer custom embroidery for shirts, polos, jackets, hats, towels and more. Let our in house design experts help you create something great."
							image={embroidery}
						/>
						<Section1
							link="/ScreenPrinting"
							title="SCREEN PRINTING"
							body="We cater to a large variety of industries that need custom screen printing: Restaurants, Marinas, Lawn Care, Clubs, and Sporting Teams."
							image={screenprint}
						/>
					</div>
				</div>
			</div>
			<div className="" style={{ backgroundColor: "rgb(54, 54, 54)" }}>
				<div className="row flexAdj">
					<div className="col s12 m7 push-m5 padLeftRightAdj">
						<img
							src={storefront}
							alt=""
							className="responsive-img"
							style={{ width: "100%", verticalAlign: "bottom" }}
						/>
					</div>
					<div
						className="col s12 m5 pull-m7 white-text rightAlignAdj"
						style={{ padding: "20px 0.75rem" }}
					>
						<div className="right" style={{ maxWidth: "400px" }}>
							<span
								className=""
								style={{
									fontSize: "2rem",
									lineHeight: "1",
									fontWeight: "500",
								}}
							>
								WE’RE LOCAL.
							</span>
							<p>
								We’ve been Savannah's trusted partner for custom
								screen printing, custom embroidery, and
								personalized promotional products since 1977.
							</p>
							<p style={{ marginBottom: "0" }}>
								With more than four decades of custom screen
								printing experience, we provide top quality
								products, access to our in-house design team and
								quick screen printing turnaround times.
							</p>
						</div>
					</div>
				</div>
			</div>

			{/* <div>
				<div
					className="container valign-wrapper"
					style={{
						justifyContent: "space-evenly",
						paddingTop: "30px",
						paddingBottom: "30px",
						overflow: "hidden",
					}}
				>
					<img src={brand01} alt="" className="responsive-img" />
					<img src={brand02} alt="" className="responsive-img" />
					<img src={brand03} alt="" className="responsive-img" />
					<img src={brand04} alt="" className="responsive-img" />
					<img src={brand05} alt="" className="responsive-img" />
					<img src={brand06} alt="" className="responsive-img" />
				</div>
			</div> */}

			<div>
				<div
					className="container"
					style={{
						paddingTop: "40px",
						paddingBottom: "60px",
						overflow: "hidden",
					}}
				>
					<Slider {...settings}>
						<div>
							<img
								src={brand01}
								alt=""
								className="responsive-img"
							/>
						</div>
						<div>
							<img
								src={brand02}
								alt=""
								className="responsive-img"
							/>
						</div>
						<div>
							<img
								src={brand03}
								alt=""
								className="responsive-img"
							/>
						</div>
						<div>
							<img
								src={brand04}
								alt=""
								className="responsive-img"
							/>
						</div>
						<div>
							<img
								src={brand05}
								alt=""
								className="responsive-img"
							/>
						</div>
						<div>
							<img
								src={brand06}
								alt=""
								className="responsive-img"
							/>
						</div>
						<div>
							<img
								src={brand07}
								alt=""
								className="responsive-img"
							/>
						</div>
						<div>
							<img
								src={brand08}
								alt=""
								className="responsive-img"
							/>
						</div>
						<div>
							<img
								src={brand09}
								alt=""
								className="responsive-img"
							/>
						</div>
						<div>
							<img
								src={brand010}
								alt=""
								className="responsive-img"
							/>
						</div>
						<div>
							<img
								src={brand011}
								alt=""
								className="responsive-img"
							/>
						</div>
					</Slider>
				</div>
			</div>

			<div className="" style={{ backgroundColor: "rgb(54, 54, 54)" }}>
				<div className="row flexAdj" style={{ marginBottom: "0" }}>
					<div className="col s12 m7 padLeftRightAdj">
						<img
							src={good}
							alt=""
							className="responsive-img"
							style={{ width: "100%", verticalAlign: "bottom" }}
						/>
					</div>
					<div
						className="col s12 m5 white-text leftAlignAdj"
						style={{ padding: "20px 0.75rem" }}
					>
						<div className="left" style={{ maxWidth: "400px" }}>
							<span
								className=""
								style={{
									fontSize: "2rem",
									lineHeight: "1",
									fontWeight: "500",
								}}
							>
								WE’RE GOOD.
							</span>
							<p>
								Bahama Joe’s uses best-in-class embroidery
								equipment to produce high quality designs on a
								variety of different fabrics. Custom embroidery
								incorporates your brand into the design by
								stitching directly on to the fabric.
							</p>
							<p style={{ marginBottom: "0" }}>
								You provide the deadline and Bahama Joe’s
								produces the highest quality custom embroidery
								work, on time, every time.
							</p>
						</div>
					</div>
				</div>
			</div>

			<div>
				<div
					className="container"
					style={{
						paddingBottom: "60px",
						overflow: "hidden",
					}}
				>
					<Slider {...settings02}>
						<div>
							<div className="center-align">
								<div
									className="container flexAdj"
									style={{
										justifyContent: "space-evenly",
										paddingTop: "30px",
										paddingBottom: "30px",
									}}
								>
									{/* <img src={crabhat} alt="" className="responsive-img" /> */}
									<p className="center-align testimoTxt">
										“The Crab Shack has relied on Bahama
										Joes for all our imprinted wearables for
										our Crew and for our Gift Shack for over
										15 years. "Buying local" is important to
										us and equally important is great art
										work, prompt service, and quality
										products. We get it ALL from Bahama
										Joes!” <br />
										<br />
										<b>Virginia Ward</b>
										<br />
										<i>The Crab Shack</i>
									</p>
									<img
										src={testimologo01}
										alt=""
										className="responsive-img"
									/>
								</div>
							</div>
						</div>
						<div>
							<div className="center-align">
								<div
									className="container flexAdj"
									style={{
										justifyContent: "space-evenly",
										paddingTop: "30px",
										paddingBottom: "30px",
									}}
								>
									{/* <img src={crabhat} alt="" className="responsive-img" /> */}
									<p className="center-align testimoTxt">
										"The professionalism of the staff at
										Bahama Joe's is incredible and the
										quality is amazing. The items are always
										done on time, and usually ahead of
										schedule. Pat and the other staff work
										as team members with Weyerhaeuser to
										help us with every project and we are so
										glad to have them as partners!" <br />
										<br />
										<b>Kathryn Johnson</b>
										<br />
										<i>Weyerhaeuser Port Wentworth</i>
									</p>
									<img
										src={testimologo02}
										alt=""
										className="responsive-img"
									/>
								</div>
							</div>
						</div>
						<div>
							<div className="center-align">
								<div
									className="container flexAdj"
									style={{
										justifyContent: "space-evenly",
										paddingTop: "30px",
										paddingBottom: "30px",
									}}
								>
									{/* <img src={crabhat} alt="" className="responsive-img" /> */}
									<p className="center-align testimoTxt">
										"The personal care for attention from
										EVERYONE at Bahama Joes is what keeps us
										coming back." <br />
										<br />
										<b>Michael Dodd</b>
										<br />
										<i>
											The Savannah Professional
											Firefighters Association
										</i>
									</p>
									<img
										src={testimologo03}
										alt=""
										className="responsive-img"
									/>
								</div>
							</div>
						</div>
						<div>
							<div className="center-align">
								<div
									className="container flexAdj"
									style={{
										justifyContent: "space-evenly",
										paddingTop: "30px",
										paddingBottom: "30px",
									}}
								>
									{/* <img src={crabhat} alt="" className="responsive-img" /> */}
									<p className="center-align testimoTxt">
										"Quality of Service and Bahama Joe's
										consistently meets and exceeds our
										expectations for service. Team is very
										helpful, knowledgeable, and eager to
										make each project successful for their
										customer."
										<br />
										<br />
										<b>MacAlJon</b>
										{/* <br />
										<i>Weyerhaeuser Port Wentworth</i> */}
									</p>
									<img
										src={testimologo04}
										alt=""
										className="responsive-img"
										style={{ verticalAlign: "bottom" }}
									/>
								</div>
							</div>
						</div>
						{/* <div>
							<img src={brand03} alt="" className="responsive-img" />
						</div>
						<div>
							<img src={brand04} alt="" className="responsive-img" />
						</div> */}
					</Slider>
				</div>
			</div>

			{/* <div className="center-align">
				<div
					className="container flexAdj"
					style={{
						justifyContent: "space-evenly",
						paddingTop: "30px",
						paddingBottom: "30px",
					}}
				>
					<img src={crabhat} alt="" className="responsive-img" />
					<p
						className="center-align"
						style={{ fontSize: "20px", fontWeight: "300", padding: "0 20px" }}
					>
						“The Crab Shack has relied on Bahama Joes for all our imprinted
						wearables for our Crew and for our Gift Shack for over 15 years.
						"Buying local" is important to us and equally important is great art
						work, prompt service, and quality products. We get it ALL from
						Bahama Joes!” <br />
						<br />
						<b>Virginia Ward</b>
						<br />
						<i>The Crab Shack</i>
					</p>
					<img src={crablogo} alt="" className="responsive-img" />
				</div>
			</div> */}

			<div className="clearfix"></div>
		</div>
	);
};

const Section1 = ({ title, body, image, link }) => {
	return (
		<div className="col s12 m12 l4" style={{ marginBottom: "70px" }}>
			<div className="col s12 left-align">
				<span
					className=""
					style={{
						fontSize: "1.7rem",
						lineHeight: "1",
						fontWeight: "500",
					}}
				>
					{title}
				</span>
			</div>
			<div className="col s12 left-align">
				<img
					src={image}
					alt=""
					className="responsive-img"
					style={{ float: "right", marginLeft: "20px" }}
				/>
				<p style={{ marginTop: "10px" }}>{body}</p>
				<Link to="https://bahamajoesuniforms.myshopify.com/" onClick={() => window.location.href="https://bahamajoesuniforms.myshopify.com/"} className="cyanBtn">
					SHOP NOW
				</Link>
			</div>
		</div>
	);
};

export default HomePage;
