import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./store.css";
import { CartContext } from "../../context/CartContext";
import CurrencyFormat from "react-currency-format";
import { IntuitContext } from "../../context/IntuitContext";
import PgTitle from "./imgs/BJ-yourcart.png";
import { AdminNav } from "../SchoolsPage";
import axios from "axios";

const round = (number, decimalPlaces) =>
  Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
const currencyAttr = {
  decimalScale: 2,
  fixedDecimalScale: true,
  displayType: "text",
  thousandSeparator: true,
  prefix: "$",
};

export const stores = (x) => {
  const storesArrayFixed = [
    {
      company: "Bahama Joe's Corporate Offices",
      action: "Pick-up",
      firstname: "In Store",
      lastname: "Pickup",
      address1: "198 W Chatham Blvd",
      address2: "",
      city: "Savannah",
      state: "GA",
      zip: "31408",
      email: "",
    },
    {
      company: "Bahama Joe's School Uniforms Store",
      action: "Pick-up",
      firstname: "In Store",
      lastname: "Pickup",
      address1: "4755 Waters Ave",
      address2: "",
      city: "Savannah",
      state: "GA",
      zip: "31404",
      email: "",
    },
  ];

  // if items includes an optim item

  const customStoresArray = [
    {
      company: "Pinewood Christian (PCA)",
      action: "Pick-up",
      firstname: "In Store",
      lastname: "Pickup",
      address1: "1 Buck Cravey Drive",
      address2: "",
      city: "Bellville",
      state: "GA",
      zip: "30414",
      email: "",
      label: "Pinewood Christian Academy",
    },
    // {
    //   company: 'Optim in Savannah',
    //   action: 'Optim Courier',
    //   firstname: 'Optim',
    //   lastname: 'Courier',
    //   address1: '210 E. DeRenne Avenue',
    //   address2: '',
    //   city: 'Savannah',
    //   state: 'GA',
    //   zip: '31405',
    //   email: '',
    //   label: 'optim',
    // },
  ];

  // extract school names from cart to array ['pine','chatham school']
  const itemStores = x.reduce(
    (res, el) =>
      res.indexOf(res.school) === -1 ? [...res, el.school.toLowerCase()] : res,
    []
  );

  const hasOptim =
    itemStores.length > 0 &&
    itemStores.filter((el) => el.toLowerCase().indexOf("optim") > -1).length ===
      itemStores.length;

  const hasTitlemax =
    itemStores.length > 0 &&
    itemStores.filter((el) => el.toLowerCase().indexOf("titlemax") > -1)
      .length === itemStores.length;

  const results = [
    ...storesArrayFixed.filter((x) => (hasOptim ? false : true)),
    // ...storesArrayFixed.filter((x) =>
    //   x.company === "Bahama Joe's School Uniforms Store" && hasOptim
    //     ? false
    //     : true
    // ),
    ...customStoresArray.filter(
      (el) =>
        itemStores.filter(
          (itemStore) => itemStore.indexOf(el.label.toLowerCase()) > -1
        ).length > 0
    ),
  ];

  return results;
};

export const SelectShipping = () => {
  const {
    cost,
    itemCount,
    cartItems,
    increase,
    decrease,
    remove,
    setPickup,
    pickup,
    setDelivery,
    delivery,
  } = useContext(CartContext);
  const availableStores = stores(cartItems);
  const [deliveryOpt, setDeliveryOpt] = useState(delivery);
  const { total, grandTotal, shipping, tax } = cost;
  const handleDeliveryChange = (e) => {
    // e.preventDefault();
    setDelivery(e.target.value);
    setDeliveryOpt(e.target.value);
    setPickup(availableStores[e.target.getAttribute("loc")]);
  };

  return (
    <>
      <p style={{ paddingLeft: "10px" }}>
        <label style={{ color: "black" }}>
          <input
            checked={deliveryOpt === "ship"}
            loc="ship"
            onChange={handleDeliveryChange}
            name="delivery"
            type="radio"
            value="ship"
          />
          <span>Shipping</span>
          <span className="right" style={{ fontSize: "18px" }}>
            <CurrencyFormat {...currencyAttr} value={round(shipping, 2)} />
          </span>
        </label>
      </p>
      {availableStores.map((el, i) => (
        <p style={{ paddingLeft: "10px" }} key={i}>
          <label style={{ color: "black" }}>
            <input
              checked={
                deliveryOpt === "pickup" && el.company === pickup.company
              }
              loc={i}
              onChange={handleDeliveryChange}
              name="delivery"
              type="radio"
              value="pickup"
            />
            <span>
              {el.action} :<br />
              {el.company}
              {el.action !== "Optim Courier" && (
                <>
                  <br />
                  {el.address1}
                </>
              )}
            </span>
          </label>
        </p>
      ))}
    </>
  );
};

const CartPage = () => {
  const EMS_EMAIL = sessionStorage.getItem("EMS_EMAIL");
  const [discount, setDiscount] = useState(null);
  const [totalDiscount, setTotalDiscount] = useState(0);
  // Axios get user EMS Info

  const { cost, itemCount, cartItems, increase, decrease, remove } =
    useContext(CartContext);
  const cart = cartItems;
  const { grandTotal, tax } = cost;

  const getUserEMSInfo = async () => {
    const localhost = "http://localhost:3360";
    const domain = "https://bahamajoes.com/api";
    const res = await axios.get(`${domain}/users/ems/${EMS_EMAIL}`);
    setTotalDiscount(parseInt(res.data.results[0].discount_amount));
    if (grandTotal - parseInt(res.data.results[0].discount_amount) < 0) {
      setDiscount(0);
    } else {
      setDiscount(grandTotal - parseInt(res.data.results[0].discount_amount));
    }
  };

  const savings = cartItems.reduce(
    (total, i) => (total += (i.base_price - i.price) * i.quantity),
    0
  );

  const base_total = cartItems.reduce(
    (total, i) => (total += i.base_price * i.quantity),
    0
  );

  const getImage = (product) => {
    const image = product.attributes.filter(
      (element) => element.name.toLowerCase() === "color"
    );
    if (image.length > 0) return image[0].image;
    return product.images[0].image;
  };

  useEffect(() => {
    getUserEMSInfo();
  }, []);
  const EMS = JSON.parse(sessionStorage.getItem("EMS"));

  return (
    <div className="row">
      <div className="center-align">
        <br />
        <br />
        <img src={PgTitle} />
        <br />
        <br />
      </div>
      <AdminNav />
      <div className="container">
        <div className="col s12 m12 l8">
          <h4>Item(s) in Cart: {itemCount}</h4>
          {cart.map((item, i) => (
            <div key={i} className="card horizontal z-depth-1">
              <div className="card-image">
                <img
                  src={`/assets/products/medium/${getImage(item)}`}
                  style={{ maxHeight: "190px" }}
                />
              </div>
              <div className="card-stacked">
                <div className="card-content">
                  <span className="remove" onClick={() => remove(i)}>
                    Remove
                  </span>
                  <p style={{ lineHeight: "1" }}>
                    <b>{item.name}</b>
                  </p>
                  {item.attributes.length > 0 && (
                    <div
                      className="small"
                      style={{
                        lineHeight: "14px",
                        fontSize: "16px",
                        marginTop: "5px",
                      }}
                    >
                      <small>
                        {item.attributes.map((attr, z) => (
                          <span key={attr.value}>
                            {`${attr.name} : ${
                              attr.value ? attr.value : "asd"
                            }`}
                            <br />
                          </span>
                        ))}
                      </small>
                    </div>
                  )}
                </div>
                <div className="card-action">
                  <span onClick={() => decrease(i)} className="qt-minus">
                    -
                  </span>
                  <span className="qt">{item.quantity}</span>
                  <span onClick={() => increase(i)} className="qt-plus">
                    +
                  </span>
                  <span className="full-price right">
                    <CurrencyFormat
                      {...currencyAttr}
                      value={round(item.quantity * item.price, 2)}
                    />
                  </span>
                  <span className="price right">
                    {item.base_price > item.price ? (
                      <>
                        <del style={{ color: "grey", paddingRight: "10px" }}>
                          <CurrencyFormat
                            {...currencyAttr}
                            value={round(item.base_price, 2)}
                          />
                        </del>
                        <span style={{ color: "green" }}>
                          <CurrencyFormat
                            {...currencyAttr}
                            value={round(item.price, 2)}
                          />
                        </span>
                      </>
                    ) : (
                      <CurrencyFormat
                        {...currencyAttr}
                        value={round(item.price, 2)}
                      />
                    )}
                  </span>
                </div>
              </div>
            </div>
          ))}
          <div className="clearfix"></div>
        </div>
        {itemCount > 0 && (
          <div className="col s12 m12 l4">
            <h4>Cart Summary</h4>
            <form>
              <p>
                Merchandise Subtotal{" "}
                <span className="right">
                  <CurrencyFormat
                    {...currencyAttr}
                    value={round(base_total, 2)}
                  />
                </span>
              </p>

              {savings > 0 && (
                <p style={{ color: "green" }}>
                  <b>
                    DISCOUNT SAVINGS
                    <span className="right">
                      <CurrencyFormat
                        {...currencyAttr}
                        value={round(-savings, 2)}
                      />
                    </span>
                  </b>
                </p>
              )}

              <SelectShipping />
              {/* <p style={{ marginBottom: "0" }}>Add Promo Code</p>
									<div
											style={{
												position: "relative",
											}}>
											<input type="text" placeholder="Code" className="rightTagInput" name="searchTerm" />
											<button className="btn btn-small">APPLY</button>
									</div> */}
              <div className="clearfix"></div>

              {EMS && EMS ? (
                <>
                  <p className="totalText">
                    Total After Tax (USD):{" "}
                    <span className="right">
                      <CurrencyFormat
                        {...currencyAttr}
                        value={round(grandTotal + tax, 2)}
                      />
                    </span>
                    <p>
                      Your Credit (USD):{" "}
                      <span className="right">
                        <CurrencyFormat
                          {...currencyAttr}
                          value={round(totalDiscount, 2)}
                        />
                      </span>
                    </p>
                    <p>
                      Total After Credit (USD):{" "}
                      <span className="right">
                        <CurrencyFormat
                          {...currencyAttr}
                          value={round(discount, 2)}
                        />
                      </span>
                    </p>
                  </p>

                  <div className="center">
                    <Link to="/thankyou" className="btn">
                      Proceed to Secure Checkout
                    </Link>
                  </div>
                </>
              ) : (
                <>
                  <p className="totalText">
                    Total Before Tax (USD):
                    <span className="right">
                      <CurrencyFormat
                        {...currencyAttr}
                        value={round(grandTotal - tax, 2)}
                      />
                    </span>
                  </p>
                  <div className="center">
                    <Link to="secure.checkout" className="btn">
                      Proceed to Secure Checkout
                    </Link>
                  </div>
                </>
              )}
            </form>
          </div>
        )}
        ;<div className="clearfix"></div>
        <br />
        <br />
      </div>
    </div>
  );
};

export default CartPage;
