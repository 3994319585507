import React, { useState, useContext, useEffect } from 'react';
import RegisterForm from './register';
import LoginForm from './login';
import ResetForm, { DoResetForm } from './reset';
import { AuthContext } from '../../context/auth-provider';
import { useLocation, useNavigate } from 'react-router-dom';

import './login.scss';

const LoginPage = ({ auth, ...props }) => {
  // const [username, setUsername] = useState("");
  // const [password, setPassword] = useState("");
  const location = useLocation();
  let path = location.pathname.split('/')[1].toLowerCase();

  const [form, setForm] = useState(path);
  const [state, setState] = useState('login');
  const toggle = {
    login: 'register',
    register: 'login',
  };
  const navigate = useNavigate();

  const { user, token } = useContext(AuthContext);

  useEffect(() => {
    if (navigate && token.length > 0 && user && user.id && user.id > 0) {
      navigate('/account/redir');
    }
  }, [navigate]);

  const openLogin = (e) => {
    if (e !== form) setForm(toggle[form]);
  };

  useEffect(() => {
    setForm(path);
  }, [path]);

  return (
    <div className="login-box store">
      <div className="container z-depth-2">
        <div className="form-div">
          <div className="form-structor">
            {form === 'reset' && (
              <>
                <div
                  className={form === 'register' ? 'login slide-up' : 'login'}
                >
                  <div className="centerx">
                    <p className="form-title">Password Reset</p>
                    <DoResetForm {...props} auth={auth} setState={setState} />
                  </div>
                </div>
              </>
            )}
            {form !== 'reset' && (
              <>
                {state === 'reset' && (
                  <div
                    className={form === 'register' ? 'login slide-up' : 'login'}
                  >
                    <div className="centerx">
                      <p className="form-title">Password Reset</p>
                      <ResetForm {...props} auth={auth} setState={setState} />
                    </div>
                  </div>
                )}
                {state === 'login' && (
                  <>
                    <div
                      className={
                        form === 'register' ? 'login slide-up' : 'login'
                      }
                    >
                      <div className="centerx">
                        <p
                          className="form-title"
                          id="login"
                          onClick={(e) => openLogin(e.target.id)}
                        >
                          <span>or</span>Account Login
                        </p>
                        <LoginForm {...props} auth={auth} setState={setState} />
                      </div>
                    </div>
                    <div
                      className={
                        form === 'login' ? 'signup slide-up' : 'signup'
                      }
                    >
                      <p
                        className="form-title"
                        id="signup"
                        onClick={(e) => openLogin(e.target.id)}
                      >
                        <span>or</span>
                        Register
                      </p>
                      <RegisterForm
                        {...props}
                        auth={auth}
                        openLogin={openLogin}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
