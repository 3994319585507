import React from 'react';
import CurrencyFormat from 'react-currency-format';

const round = (number, decimalPlaces) =>
  Number(Math.round(number + 'e' + decimalPlaces) + 'e-' + decimalPlaces);
const currencyAttr = {
  decimalScale: 2,
  fixedDecimalScale: true,
  displayType: 'text',
  thousandSeparator: true,
  prefix: '$',
};

const Page = ({ click, p, discount }) => {
  // const getSize = () => {
  //    return { margin: '0 auto', width: 'auto', height: window.innerWidth / 5 };
  // };

  // const [size, setSize] = React.useState(getSize());

  // React.useEffect(() => {
  //    setSize(getSize());
  // }, []);

  let imagesFiltered = [];
  let error = false;

  try {
    imagesFiltered = p.images.filter(
      (el) => el.image_path.indexOf(p.colors[0]['image']) > 0
    );
  } catch (e) {
    error = true;
    console.log('1', { e });
  }

  let imagePath = '';
  try {
    imagePath =
      p.images.length > 0
        ? p.images[0]['image_path']
        : `/assets/products/medium/${p.colors[0].image}`;
  } catch (e) {
    error = true;
    console.log('2', { e });
  }

  try {
    imagePath =
      imagesFiltered.length > 0
        ? imagesFiltered[0].image_path
        : `/assets/products/medium/${p.colors[0].image}`;
  } catch (e) {
    error = true;
    console.log('3', { e });
  }

  return (
    <div>
      <div
        onClick={() => click(p)}
        className="col s6 m4 l3"
        style={{ padding: '0', position: 'relative' }}
      >
        <div
          className="singleItem"
          style={p.outofstock ? { opacity: 0.5 } : {}}
        >
          <div className="square">
            <div className="sq-content">
              <figure className="effect-lily valign-wrapper">
                {imagePath.length > 0 && (
                  <img
                    className="responsive-img"
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      margin: '0px auto',
                    }}
                    src={`https://bahamajoes.com${imagePath}`}
                    alt="img12"
                  />
                )}
              </figure>
            </div>
          </div>

          <div
            className="center-align"
            style={{ minHeight: '85px', cursor: 'pointer' }}
          >
            <p className="" style={{ fontWeight: '200', marginTop: '10px' }}>
              {p.name}
              <br />
              <span style={{ fontWeight: '600' }}>
                {discount && (
                  <>
                    <del style={{ paddingRight: '10px', color: 'grey' }}>
                      <CurrencyFormat
                        {...currencyAttr}
                        value={round(p.price, 2)}
                      />
                    </del>
                    <span style={{ color: 'green' }}>
                      <CurrencyFormat
                        {...currencyAttr}
                        value={round(p.price * ((100 - discount) / 100), 2)}
                      />
                    </span>
                  </>
                )}
                {!discount && (
                  <CurrencyFormat {...currencyAttr} value={round(p.price, 2)} />
                )}
              </span>
            </p>
          </div>
        </div>
        {p.outofstock > 0 && (
          <>
            <div
              style={{
                position: 'absolute',
                left: '5%',
                top: '100px',
                background: 'rgba(255,255,255,.7)',
                color: 'rgba(0,0,0,.7)',
                width: '90%',
                textAlign: 'center',
                padding: '30px 0',
              }}
            >
              <b
                style={{
                  // border: '1px solid lightgrey',
                  padding: '2px 10px',
                }}
              >
                OUT OF STOCK
              </b>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Page;
