import React from 'react';
import ReactDOM from 'react-dom';
// import { Form } from '../Form';
import FocusTrap from 'focus-trap-react';

export const Modal = React.forwardRef(({ onClickOutside, onKeyDown, modalRef, buttonRef, closeModal, onSubmit, Form, prefill = null }, refs) => {
   if (!refs) return;
   return ReactDOM.createPortal(
      <FocusTrap>
         <aside tag="aside" role="dialog" tabIndex="-1" aria-modal="true" className="modal-cover" onClick={onClickOutside} onKeyDown={onKeyDown}>
            <div className="modal-area" ref={refs[0]}>
               <button ref={refs[1]} aria-label="Close Modal" aria-labelledby="close-modal" className="_modal-close" onClick={closeModal}>
                  <span id="close-modal" className="_hide-visual">
                     Close
                  </span>
                  <svg className="_modal-close-icon" viewBox="0 0 40 40">
                     <path d="M 10,10 L 30,30 M 30,10 L 10,30" />
                  </svg>
               </button>
               <div className="modal-body">
                  <Form onSubmit={onSubmit} prefill={prefill} />
               </div>
            </div>
         </aside>
      </FocusTrap>,
      document.body
   );
});

export default Modal;
