// import tShirts from './tshirt.jpg';
// import sweatshirt from './sweatshirt.jpg';
// import hoodies from './hoodie.jpg';
// import workwear from './workwear.jpg';
// import youthApparel from './youth.jpg';
// import infantApparel from './infant.jpg';
// import camoflageApparel from './camouflage.jpg';
// import allApparel from './shopall.jpg';

import ShortSleeveTShirts from './1-short.jpg';
import SoftFashionTshirts from './2-fashion.jpg';
import LongSleeveTshirts from './3-long.jpg';
import Performance from './4-performance.jpg';

import YouthTShirts from './5-youth.jpg';
import LadiesTshirts from './6-ladies.jpg';
import HoodiesSweatshirts from './7-hoodies.jpg';
import ComfortColors from './8-comfortcolors.jpg';

import TankTops from './9-tanktops.jpg';
import ToteBags from './10-totes.jpg';
import MadeinUSATshirts from './11-madeinUSA.jpg';
import ShopAllTshirts from './12-all.jpg';

export const categories = [
  // {
  // 	name: "T-Shirts",
  // 	subcategory_labels: ["mens", "ladies", "youth", "boys", "girls"],
  // 	image: tShirts,
  // 	subcategories: [
  // 		{
  // 			name: "Short Sleeve",
  // 			mens: {
  // 				image:
  // 					"https://promomaster.wjserver450.com/assets/Image/Category/DT1200_blue_model_front.jpg",
  // 				link:
  // 					"https://shop.bahamajoes.com/category/57/Mens-Short-Sleeve-T-Shirts.html",
  // 			},
  // 			ladies: {
  // 				image:
  // 					"https://promomaster.wjserver450.com/assets/Image/Category/DT234C_BrightWhite_Model_front_052012.jpg",
  // 				link:
  // 					"https://shop.bahamajoes.com/category/566/Ladies-Short-Sleeve-T-Shirts.html",
  // 			},
  // 		},
  // 		{
  // 			name: "Long Sleeve",
  // 			mens: {
  // 				link:
  // 					"https://shop.bahamajoes.com/category/562/Mens-Long-Sleeve-T-Shirts.html",
  // 				image:
  // 					"https://e41bf2dd83a28945d1a0-4eae669b9df2f424e8c2759bd627d84d.ssl.cf5.rackcdn.com/detailsbig/7930_00_p.jpg",
  // 			},
  // 			ladies: {
  // 				link:
  // 					"https://shop.bahamajoes.com/category/1104/Ladies-Long-Sleeve-T-Shirts.html",
  // 				image:
  // 					"https://f49f7a99576626a7a4f9-6ad05d6fae9750295d152a24570d770f.ssl.cf5.rackcdn.com/detailsbig/887L_blackdarkgrey_model_front_072014.jpg",
  // 			},
  // 		},
  // 		{
  // 			name: "Cotton Tees",
  // 			mens: {
  // 				link:
  // 					"https://shop.bahamajoes.com/category/1160/Mens-Cotton-T-Shirts.html",
  // 				image:
  // 					"https://promomaster.wjserver450.com/assets/Image/Category/DM3000.jpg",
  // 			},
  // 			ladies: {
  // 				link:
  // 					"https://shop.bahamajoes.com/category/1161/Ladies-Cotton-T-Shirts.html",
  // 				image:
  // 					"https://promomaster.wjserver450.com/assets/Image/Category/DM3501.jpg",
  // 			},
  // 		},
  // 		{
  // 			name: "Youth Tees",
  // 			boys: {
  // 				image:
  // 					"https://promomaster.wjserver450.com/assets/Image/Category/PC61Y.jpg",
  // 				link: "https://shop.bahamajoes.com/category/1153/Boys-T-Shirts.html",
  // 			},
  // 			girls: {
  // 				image:
  // 					"https://promomaster.wjserver450.com/assets/Image/Category/DT5001YG.jpg",
  // 				link: "https://shop.bahamajoes.com/category/1154/Girls-T-Shirts.html",
  // 			},
  // 		},
  // 		{
  // 			name: "No Sleeve/Tank",
  // 			mens: {
  // 				link:
  // 					"https://shop.bahamajoes.com/category/563/Mens-No-Sleeve-Tank-Tops.html",
  // 				image:
  // 					"https://e41bf2dd83a28945d1a0-4eae669b9df2f424e8c2759bd627d84d.ssl.cf5.rackcdn.com/detailsbig/39tkr_00_p.jpg",
  // 			},
  // 			ladies: {
  // 				link:
  // 					"https://shop.bahamajoes.com/category/1103/Ladies-No-Sleeve-Tank-Tops.html",
  // 				image:
  // 					"https://f49f7a99576626a7a4f9-6ad05d6fae9750295d152a24570d770f.ssl.cf5.rackcdn.com/detailsbig/6751L_black_model_front_012016.jpg",
  // 			},
  // 		},
  // 		{
  // 			name: "Jerseys",
  // 			mens: {
  // 				link: "https://shop.bahamajoes.com/category/53/Mens-Jerseys.html",
  // 				image:
  // 					"https://promomaster.wjserver450.com/assets/Image/Category/T200_carolinabluenavy_model_front_072014.jpg",
  // 			},
  // 			ladies: {
  // 				image:
  // 					"https://promomaster.wjserver450.com/assets/Image/Category/DM136L.jpg",
  // 				link: "https://shop.bahamajoes.com/category/1175/Ladies-Jerseys.html",
  // 			},
  // 			youth: {
  // 				image:
  // 					"https://promomaster.wjserver450.com/assets/Image/Category/1358.jpg",
  // 				link: "https://shop.bahamajoes.com/category/1358/Youth-Jerseys.html",
  // 			},
  // 		},
  // 		{
  // 			name: "Blend Tees",
  // 			mens: {
  // 				image:
  // 					"https://promomaster.wjserver450.com/assets/Image/Category/1162.jpeg",
  // 				link:
  // 					"https://shop.bahamajoes.com/category/1162/Mens-Blend-T-Shirts.html",
  // 			},
  // 			ladies: {
  // 				image:
  // 					"https://promomaster.wjserver450.com/assets/Image/Category/1163.jpeg",
  // 				link:
  // 					"https://shop.bahamajoes.com/category/1163/Ladies-Blend-T-Shirts.html",
  // 			},
  // 		},
  // 		{
  // 			name: "Ring Spun",
  // 			mens: {
  // 				image:
  // 					"https://promomaster.wjserver450.com/assets/Image/Category/PC450.jpg",
  // 				link:
  // 					"https://shop.bahamajoes.com/category/1165/Mens-Ring-Spun-T-Shirts.html",
  // 			},
  // 			ladies: {
  // 				image:
  // 					"https://promomaster.wjserver450.com/assets/Image/Category/LPC450V.jpg",
  // 				link:
  // 					"https://shop.bahamajoes.com/category/1166/Ladies-Ring-Spun-T-Shirts.html",
  // 			},
  // 		},
  // 	],
  // },
  // {
  // 	name: "Sweatshirt",
  // 	image: sweatshirt,
  // 	subcategories: [],
  // },
  // {
  // 	name: "Hoodies",
  // 	image: hoodies,
  // 	subcategories: [],
  // },
  // {
  // 	name: "Workwear",
  // 	image: workwear,
  // 	subcategories: [],
  // },
  // {
  // 	name: "Youth Apparel",
  // 	image: youthApparel,
  // 	subcategories: [],
  // },
  // {
  // 	name: "Infant Apparel",
  // 	image: infantApparel,
  // 	subcategories: [],
  // },
  // {
  // 	name: "Camoflage Apparel",
  // 	image: camoflageApparel,
  // 	subcategories: [],
  // },
  // {
  // 	name: "All Apparel",
  // 	image: allApparel,
  // 	subcategories: [],
  // },
  {
    name: 'Short Sleeve T-Shirts',
    image: ShortSleeveTShirts,
    subcategories: [],
    link: 'https://shop.bahamajoes.com/category/1137/Short-Sleeve-T-Shirts.html',
  },
  {
    name: 'Soft Fashion T-shirts',
    image: SoftFashionTshirts,
    subcategories: [],
    link: 'https://shop.bahamajoes.com/category/1150/Fashion-Tees.html',
  },
  {
    name: 'Long Sleeve T-shirts',
    image: LongSleeveTshirts,
    subcategories: [],
    link: 'https://shop.bahamajoes.com/category/1138/Long-Sleeve-T-Shirts.html',
  },
  {
    name: 'Performance',
    image: Performance,
    subcategories: [],
    link: 'https://shop.bahamajoes.com/category/1141/Performance-T-Shirts.html',
  },
  {
    name: 'Youth T-Shirts',
    image: YouthTShirts,
    subcategories: [],
    link: 'https://shop.bahamajoes.com/category/1148/Youth-Tees.html',
  },
  {
    name: 'Ladies T-shirts',
    image: LadiesTshirts,
    subcategories: [],
    link: 'https://shop.bahamajoes.com/category/1139/T-Shirts.html',
  },
  {
    name: 'Hoodies & Sweatshirts',
    image: HoodiesSweatshirts,
    subcategories: [],
    link: 'https://shop.bahamajoes.com/category/1237/Sweatshirts.html',
  },
  {
    name: 'Comfort Colors',
    image: ComfortColors,
    subcategories: [],
    link: 'https://shop.bahamajoes.com/category/1139/T-Shirts.html',
  },
  {
    name: 'Tank Tops',
    image: TankTops,
    subcategories: [],
    link: 'https://shop.bahamajoes.com/category/1140/No-Sleeve--and--Tank-Tops.html',
  },
  {
    name: 'Tote Bags',
    image: ToteBags,
    subcategories: [],
    link: 'https://shop.bahamajoes.com/category/104/Bags--and--Sacks.html',
  },
  {
    name: 'Made in USA T-shirts',
    image: MadeinUSATshirts,
    subcategories: [],
    link: 'https://shop.bahamajoes.com/category/1139/T-Shirts.html',
  },
  {
    name: 'Shop All T-shirts',
    image: ShopAllTshirts,
    subcategories: [],
    link: 'https://shop.bahamajoes.com/category/1139/T-Shirts.html',
  },
];
