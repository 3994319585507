import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Outlet } from 'react-router-dom';

const HomeLayout = (props) => {
  return (
    <div id="wrapper">
      <Header template="home" />
      <Outlet />
      {/* {props.children} */}
      <Footer template="home" />
    </div>
  );
};

export default HomeLayout;
