import React from 'react';
import './style.scss';

const Page = () => {
   const calcHeight = (e) => {
      const h = e ? e.target.contentWindow.document.body.offsetHeight + 200 : window.innerHeight;

      return `${h - 200}px`;
   };

   const [height, setHeight] = React.useState(calcHeight());

   const doCalcHeight = (e) => setHeight(calcHeight(e));

   React.useEffect(() => {
      window.addEventListener('resize', doCalcHeight);
      setHeight(calcHeight());

      return () => window.removeEventListener('resize', doCalcHeight);
   }, []);

   return (
      <>
         <iframe
            onLoad={doCalcHeight}
            id="iframeDessigner"
            src="/lumise/cart.php?fffframe"
            frameBorder="0"
            scrolling="no"
            style={{ overflow: 'hidden', width: '100%', height }}
         ></iframe>
      </>
   );
};

export default Page;
