import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loading from '../../components/Loader';
// import './store.css';

const CartPage = () => {
  const navigate = useNavigate();
  // const [orders, setOrders] = useState(null);
  const { oi: orderid } = useParams();
  const [order, setOrder] = useState('');

  useEffect(() => {
    const fetchOrders = () => {
      const getStorage = () => localStorage.getItem('orders');
      const storage = JSON.parse(getStorage());
      if (storage && storage.length > 0) {
        // const _order = storage.reduce((e) => e.order && e.order.orderid && e.order.orderid === orderid);

        setOrder(storage[storage.length - 1]);
        // setOrder(_order);
      } else {
        setOrder(null);
      }
    };

    fetchOrders();
  }, []);

  if (!order && (order === null || order === [])) navigate('/');
  if (!order) return <center>Loading . . .</center>;

  return (
    <>
      <div className="container row">
        <h1 className="center">Order Confirmation</h1>
        <center>
          <img
            src="https://bahamajoes.thesabresolution.com/assets/logos/black-logo.png"
            style={{ height: '200px', width: 'auto' }}
            alt=""
          />
        </center>
      </div>
      <div
        className="login-box store"
        style={{ paddingTop: '10px', paddingBottom: '0' }}
      >
        <div
          className="container z-depth-2"
          style={{ height: '400px', padding: '10px', textAlign: 'center' }}
        >
          <div className="row">
            <div className="col s12">
              <h4>
                Thanks for your order{' '}
                {`${order.billing.firstname} ${order.billing.lastname}`}
              </h4>
              <p>
                Order ID is <b>{orderid}</b>
                <br />
                We'll send a confirmation email shortly to {order.billing.email}
                .
              </p>
              <p>
                <Link className="btn" to="/">
                  Continue Shopping
                </Link>
              </p>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </>
  );
};

export default CartPage;
