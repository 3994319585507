import React, { useContext } from 'react';
import { Navigate, Route, useLocation, useNavigate } from 'react-router-dom';
//import withAuthentication, { useAuthentication } from "./context/auth-provider";
import { AuthContext } from './context/auth-provider';
import M from 'materialize-css';

import HomePageLayout from './Layout/homePage';
import DefaultLayout from './Layout/default';

//

// export function LoginRoute({ component: Component, ...rest }) {
//    const auth = useAuthentication();
//    const history = useNavigate();

//    if (auth.user) {
//       history.push('/authenticated');
//    }

//    return (
//       <Route
//          {...rest}
//          render={(props) => (
//             <LoginPageLayout>
//                <Component {...props} />
//             </LoginPageLayout>
//          )}
//       />
//    );
// }

export function HomeRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <HomePageLayout>
          <Component {...props} />
        </HomePageLayout>
      )}
    />
  );
}

// export function ProductRoute({ component: Component, ...rest }) {
//    return (
//       <Route
//          {...rest}
//          render={(props) => (
//             <ProductPageLayout>
//                <Component {...props} />
//             </ProductPageLayout>
//          )}
//       />
//    );
// }

// export function AdminRoute({ component: Component, ...rest }) {
//    return (
//       <Route
//          {...rest}
//          render={(props) => (
//             <AdminLayout history={props.history}>
//                <Component {...props} />
//             </AdminLayout>
//          )}
//       />
//    );
// }

export function PrivateRoute({ component: Component, ...rest }) {
  const { user, token } = useContext(AuthContext);
  const navigate = useNavigate();

  if (user && user.id && token && token.length > 0) {
    // console.log(auth.user);
    return (
      <HomeRoute {...rest} component={(props) => <Component {...props} />} />
    );
  }

  M.toast({ html: 'Authentication required!', classes: 'red white-text' });
  navigate('/login');
  return null;
}

export const RequireAuth = ({ children }) => {
  const { user, token } = useContext(AuthContext);
  const location = useLocation();

  if (!user || !user.id || !token || !token.length === 0) {
    M.toast({ html: 'Authentication required!', classes: 'red white-text' });
    // navigate('/login');
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <DefaultLayout>
          <Component {...props} />
        </DefaultLayout>
      )}
    />
  );
};
