import React, { useEffect, useContext } from "react";
import { AuthContext } from "../../context/auth-provider";
import { Link } from "react-router-dom";
import Modal from "../../components/Modal";
import ArrowToTop from "../ScrollTopArrow";
import SearchBar from "../SearchBar";
import Favicon from "react-favicon";
import FaviconImg from "./favicon.ico";
import "./hero.css";
import NavLinks from "./navlinks";
import M from "materialize-css";
import logo from "./Bahama-LOGO.png";
import { CartContext } from "../../context/CartContext";
import emailIcon from "./icons/BJ-email-BASE.png";
import facebookIcon from "./icons/BJ-facebook-BASE.png";
import instagramIcon from "./icons/BJ-instagram-BASE.png";
import twitterIcon from "./icons/BJ-twitter-BASE.png";

import emailIconHover from "./icons/BJ-email-ROLL.png";
import facebookIconHover from "./icons/BJ-facebook-ROLL.png";
import instagramIconHover from "./icons/BJ-instagram-ROLL.png";
import twitterIconHover from "./icons/BJ-twitter-ROLL.png";
// import LoginPage from "../../containers/LoginPage";

const Home = (props) => {
	const { cartItems } = useContext(CartContext);
	const { user, logout, showLoginForm, hideLoginForm, form, ...rest } =
		useContext(AuthContext);

	useEffect(() => {
		M.Sidenav.init(document.getElementById("mobile-nav"));
		const elems = document.querySelectorAll(".collapsible");
		M.Collapsible.init(elems, {
			accordion: false,
			onOpenStart: function (e) {
				e.querySelector(".material-icons").innerHTML = "remove";
			},
			onCloseStart: function (e) {
				e.querySelector(".material-icons").innerHTML = "add";
			},
		});
	}, []);

	const closeMobileNav = () => {
		let elem = document.getElementById("mobile-nav");
		let instance = M.Sidenav.getInstance(elem);
		instance.close();
	};

	return (
		<div>
			<div
				className=""
				style={{ backgroundColor: "#363636", color: "#edc11e" }}
			>
				<Favicon url={FaviconImg} />
				<div className="container">
					{/* <Modal open={form.open} mode={form.mode} onClose={hideLoginForm} onLogin={hideLoginForm}>
               <LoginPage onLogin={hideLoginForm} />
            </Modal> */}
					<div className="nav-wrapper">
						<header
							className="row"
							style={{
								position: "relative",
								zIndex: 100,
								marginBottom: "0",
								minHeight: "130px",
							}}
						>
							{/* <div className="hide-on-med-and-down">
							<ul className="topNav">
								<li>
									<Link to="/">912-233-0060</Link>
								</li>
								<li>
									<Link to="/">My Account</Link>
								</li>
								<li>
									<Link to="/">My Wishlist</Link>
								</li>
								<li>
									{user ? (
										<span style={{ cursor: "pointer" }} onClick={logout}>
											Log Out
										</span>
									) : (
										<span style={{ cursor: "pointer" }} onClick={showLoginForm}>
											Log In
										</span>
									)}
								</li>
								<li>
									<Link to="/">Register</Link>
								</li>
								<li>
									<Link to="/cart">
										View Cart{" "}
										{cartItems.length > 0 &&
											`(${cartItems.reduce(
												(total, i) => total + i.quantity,
												0
											)})`}
									</Link>
								</li>
							</ul>
						</div> */}
							<div className="clearfix"></div>
							<div
								style={{
									float: "right",
									display: "flex",
									alignItems: "center",
									margin: "35px 10px 15px",
								}}
								className="valign-wrapper"
							>
								<div
									className="hide-on-med-and-down"
									style={{ marginRight: "20px" }}
								>
									<SearchBar />
								</div>
								<div
									style={{
										display: "inline-block",
										fontSize: "24px",
										fontWeight: "400",
										paddingRight: "13px",
									}}
									className="hide-on-small-only"
								>
									<a
										href="tel:912-233-0060"
										style={{
											color: "#FBAF44",
											fontFamily: '"Roboto", sans-serif',
										}}
									>
										912.233.0060
									</a>
								</div>
								<div className="valign-wrapper ssIcon hide-on-med-and-down">
									<Link
										to="/Contact-Us"
										className="eIcon"
										style={{ marginRight: "15px" }}
									>
										{/* <i className="far fa-envelope"></i> */}
										<img
											src={emailIcon}
											onMouseOver={(e) =>
												(e.currentTarget.src = emailIconHover)
											}
											onMouseOut={(e) => (e.currentTarget.src = emailIcon)}
											alt=""
										/>
									</Link>
									<a
										className="fbIcon"
										target="_blank"
										href="https://www.facebook.com/ShopBahamaJoes"
									>
										{/* <i className="fab fa-facebook-f"></i> */}
										<img
											src={facebookIcon}
											onMouseOver={(e) =>
												(e.currentTarget.src = facebookIconHover)
											}
											onMouseOut={(e) => (e.currentTarget.src = facebookIcon)}
											alt=""
										/>
									</a>
									<a
										className="igIcon"
										target="_blank"
										href="https://www.instagram.com/shopbahamajoes/"
									>
										{/* <i className="fab fa-instagram"></i> */}
										<img
											src={instagramIcon}
											onMouseOver={(e) =>
												(e.currentTarget.src = instagramIconHover)
											}
											onMouseOut={(e) => (e.currentTarget.src = instagramIcon)}
											alt=""
										/>
									</a>
									<a
										className="twIcon"
										target="_blank"
										href="https://twitter.com/shopbahamajoes"
									>
										{/* <i className="fab fa-twitter"></i> */}
										<img
											src={twitterIcon}
											onMouseOver={(e) =>
												(e.currentTarget.src = twitterIconHover)
											}
											onMouseOut={(e) => (e.currentTarget.src = twitterIcon)}
											alt=""
										/>
									</a>
								</div>
							</div>
							<div className="clearfix"></div>
							<nav className="transparent" style={{ boxShadow: "none" }}>
								<Link className="brand-logo" to="/">
									<img src={logo} alt="" className="logoAdj" />
								</Link>
								<ul
									className="right hide-on-med-and-down"
									style={{ margin: "10px 0px 0 0" }}
								>
									<NavLinks closeMobileNav={closeMobileNav} />
								</ul>
								<a
									href="#!"
									data-target="mobile-nav"
									className="sidenav-trigger"
								>
									<i className="material-icons">menu</i>
								</a>
							</nav>
						</header>
					</div>
				</div>
				<ArrowToTop />
				<ul className="sidenav uppercaseTxt" id="mobile-nav">
					<li style={{ height: "130px", backgroundColor: "#edc11e" }}>
						<Link className="" to="/" onClick={() => closeMobileNav()}>
							<img
								src={logo}
								alt=""
								className="responsive-img"
								style={{ marginTop: "23px" }}
							/>
						</Link>
					</li>
					<NavLinks closeMobileNav={closeMobileNav} />
					<li>
						<Link
							className=""
							to="/Corporate-Stores"
							onClick={() => closeMobileNav()}
						>
							Corporate Stores
						</Link>
					</li>
					<li>
						<Link className="" to="/Careers" onClick={() => closeMobileNav()}>
							Careers
						</Link>
					</li>
					<li>
						<Link
							className=""
							to="/Privacy-Policy"
							onClick={() => closeMobileNav()}
						>
							Privacy Policy
						</Link>
					</li>
					{/* <li>
               <Link className="" to="/Feedback" onClick={() => closeMobileNav()}>
                  Feedback
               </Link>
            </li> */}
					<li>
						<Link
							className=""
							to="/Contact-Us"
							onClick={() => closeMobileNav()}
						>
							Store Locations
						</Link>
					</li>
					<li>
						<Link
							className=""
							to="/Contact-Us"
							onClick={() => closeMobileNav()}
						>
							Contact Us
						</Link>
					</li>
				</ul>
				<Modal />
			</div>
			<div
				className="hide-on-large-only"
				style={{
					backgroundColor: "#ffc107",
					padding: "10px",
					borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
				}}
			>
				<div className="container" style={{ marginRight: "20px" }}>
					<SearchBar />
				</div>
			</div>
		</div>
	);
};

export default React.memo(Home);
