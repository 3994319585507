import Axios from "axios";
import { API_URL } from "../../config";

export const getAddresses = (token) => {
   return Axios({
      method: "GET",
      url: `${API_URL}users.cfc?method=getAddresses`,
      headers: {
         "Content-Type": "application/json",
         Authorization: token,
      },
   }).then((r) => r.status === 200 && r.data);
};

export const getOrders = (token) => {
   return Axios({
      method: "GET",
      url: `${API_URL}users.cfc?method=getOrders`,
      headers: {
         "Content-Type": "application/json",
         Authorization: token,
      },
   }).then((r) => r.status === 200 && r.data);
};
