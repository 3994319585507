import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./styles/materializeOverride.scss";
import "./styles/materialize.modern.admin.scss";
import "./styles/app.css";
import App from "./app";
//import { Provider } from './context/auth-provider';
import CartContextProvider from "./context/CartContext";
// redux store
import { store } from "./app/store";
import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<CookiesProvider>
				<CartContextProvider>
					<Router>
						<App />
					</Router>
				</CartContextProvider>
			</CookiesProvider>
		</Provider>
	</React.StrictMode>,
	document.getElementById("root")
);
