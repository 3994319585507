import { CONST_USER, CONST_TOKEN, CONST_GUEST } from './keys';
import { getWithExpiry } from '../tokens';

export const createReducer = () => AuthReducer;

let user = getWithExpiry(CONST_USER) ? getWithExpiry(CONST_USER) : null;
let token = getWithExpiry(CONST_TOKEN) ? getWithExpiry(CONST_TOKEN) : '';
let guest = getWithExpiry(CONST_GUEST) ? getWithExpiry(CONST_GUEST) : '';

export const initialState = {
   user: null || user,
   token: null || token,
   loading: false,
   continueAsGuest: false || guest,
   errors: null,
   form: {
      mode: 'login',
      open: false,
   },
};

export const AuthReducer = (initialState, action) => {
   switch (action.type) {
      case 'REQUEST_LOGIN':
         return {
            ...initialState,
            loading: true,
         };
      case 'LOGIN_SUCCESS':
         return {
            ...initialState,
            user: action.payload.user,
            token: action.payload.token,
            loading: false,
         };
      case 'CONTINUE_AS_GUEST':
         return {
            ...initialState,
            continueAsGuest: true
         };
      case 'LOGOUT':
         return {
            ...initialState,
            user: null,
            token: '',
         };
      case 'SHOW_LOGIN_FORM':
         return {
            ...initialState,
            form: {
               ...initialState.form,
               open: true,
            },
         };

      case 'HIDE_LOGIN_FORM':
         return {
            ...initialState,
            form: {
               ...initialState.form,
               open: false,
            },
         };

      case 'LOGIN_ERROR':
         return {
            ...initialState,
            loading: false,
            errors: action.errors,
         };
      default:
         return initialState;
   }
};
