import React from "react";
import { Link } from "react-router-dom";
import PgTitle from "./welcome.png";
import store from "./store.jpg";
import cup from "./cup.png";
import hat from "./hat.png";
import shirt from "./shirt.png";
import print from "./print.jpg";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

const Page = () => {
	const userEmail = useSelector((state) => state.userEmail);

	return (
		<div>
			<Helmet>
				<title>About : Bahama Joe&apos;s</title>
			</Helmet>
			<div className="container">
				<div className="center-align">
					<br />
					<br />
					<img alt="" src={PgTitle} className="responsive-img" />
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col s12 m12 l10 offset-l1">
						<h4 className="center" style={{ fontSize: "1.8rem" }}>
							We are Savannah's trusted partner for custom screen
							printing, custom embroidery, and personalized
							promotional products.{userEmail}
						</h4>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col s12 m12 l10 offset-l1 center">
						<img
							alt=""
							src={store}
							style={{ width: "100%" }}
							className="responsive-img"
						/>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col s12 m12 l8 offset-l2">
						<h4 className="center">Your Vision. Our Products.</h4>
						<p className="leftAlignAdj">
							Founded in Savannah, Georgia in 1977, Bahama Joe’s
							has been driving the local Savannah custom screen
							printing and embroidery industry, ever since -
							offering custom t-shirts and personalized
							promotional products.
						</p>

						<p className="leftAlignAdj">
							Today, Bahama Joe’s is the leading custom screen
							printing t-shirt provider in the Savannah and
							surrounding low country area. Bahama Joe’s steadfast
							success is rooted in offering quality products,
							custom screen printing and embroidery with quick
							turnaround times, and friendly customer service.
							Bahama Joe’s continues to build on the positive
							reputation established over 40 years ago.
						</p>
						<p className="leftAlignAdj">
							If you need custom screen printed t-shirts, custom
							embroidered polos, hats, or your company logo on
							personalized promotional products for your next
							event, party, tradeshow, or conference, let Bahama
							Joe’s be your local Savannah partner.
						</p>
					</div>
				</div>
			</div>
			<div style={{ backgroundColor: "#eee" }}>
				<div className="container row">
					<br />
					<div className="col s12 m12 l8 offset-l2">
						<a
							href="https://shop.bahamajoes.com/category/26/Everything-Else.html"
							className="col s4 m4 l4 center"
						>
							<img alt="" src={cup} className="responsive-img" />

							<p style={{ margin: "0" }}>Promotional Products</p>
						</a>
						<Link to="/Embroidery" className="col s4 m4 l4 center">
							<img alt="" src={hat} className="responsive-img" />

							<p style={{ margin: "0" }}>Custom Embroidery</p>
						</Link>
						<Link
							to="/ScreenPrinting"
							className="col s4 m4 l4 center"
						>
							<img
								alt=""
								src={shirt}
								className="responsive-img"
							/>

							<p style={{ margin: "0" }}>
								Custom Screen Printing
							</p>
						</Link>
					</div>
					<div className="clearfix"></div>
					<br />
					<br />
				</div>
			</div>
			<br />
			<br />
			<div className="container">
				<div className="row">
					<div className="col s12 m12 l10 offset-l1 center">
						<img
							alt=""
							src={print}
							style={{ width: "100%" }}
							className="responsive-img"
						/>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col s12 m12 l6 offset-l3 center">
						<br />
						<h4>We’re successful when you’re successful.</h4>
						<p style={{ marginTop: "0" }}>
							You can rely on us to help you make that first
							impression count. Select from our{" "}
							<a
								href="https://shop.bahamajoes.com/category/26/Everything-Else.html"
								className="bahama-pink-text"
							>
								wide variety of products
							</a>{" "}
							or call us at{" "}
							<a
								style={{ whiteSpace: "nowrap" }}
								href="tel:9122330060"
							>
								(912) 233-0060
							</a>{" "}
							to get your order started today.
						</p>
					</div>
				</div>
			</div>

			<br />
			<br />
		</div>
	);
};

export default Page;
