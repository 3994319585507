import React, { createContext, useReducer, useEffect } from 'react';
import { createReducer, sumItems } from './CartReducer';

export const CartContext = createContext();

const cart_storage = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [];
const checkout_storage = localStorage.getItem('checkout') ? JSON.parse(localStorage.getItem('checkout')) : [];
const _delivery = localStorage.getItem('delivery') ? localStorage.getItem('delivery') : 'ship';
const pickup = localStorage.getItem('pickup') ? JSON.parse(localStorage.getItem('pickup')) : {};

const delivery = _delivery === 'ship' || _delivery === 'pickup' ? _delivery : 'ship';

const initialState = {
   cartItems: cart_storage,
   checkout: false,
   delivery,
   pickup,
   ...sumItems({ delivery, cartItems: cart_storage }),
   ...checkout_storage,
};

const CartContextProvider = ({ children }) => {
   // const memorizedReducer = useCallback(() => createReducer(), []);
   const [state, dispatch] = useReducer(createReducer(), initialState);

   useEffect(() => {
      localStorage.setItem('cart', JSON.stringify(state.cartItems.length > 0 ? state.cartItems : []));
   }, [state.cartItems]);

   useEffect(() => {
      localStorage.setItem('pickup', JSON.stringify(state.pickup ? state.pickup : {}));
   }, [state.pickup]);

   useEffect(() => {
      localStorage.setItem('delivery', state.delivery);
   }, [state.delivery]);

   useEffect(() => {
      localStorage.setItem(
         'checkout',
         JSON.stringify({
            shipping: state.shipping,
            billing: state.billing,
            payment: state.payment,
         })
      );
   }, [state.shipping, state.billing, state.payment]);

   const addProduct = (payload) => {
      return new Promise((resolve, reject) => {
         dispatch({ type: 'ADD_ITEM', payload });
         resolve(true);
      });
      // M.toast({ html: `${payload.name} added to your cart!` });
   };

   const increase = (payload) => {
      return new Promise((resolve, reject) => {
         dispatch({ type: 'INCREASE', payload });
         resolve(true);
      });
   };

   const decrease = (payload) => {
      return new Promise((resolve, reject) => {
         dispatch({ type: 'DECREASE', payload });
         resolve(true);
      });
   };

   const remove = (payload) => {
      return new Promise((resolve, reject) => {
         dispatch({ type: 'REMOVE_ITEM', payload });
         resolve(true);
      });
   };

   const setPayment = (payload) => {
      return new Promise((resolve, reject) => {
         dispatch({ type: 'SETPAYMENT', payload });
         resolve(true);
      });
   };

   const setBilling = (payload) => {
      return new Promise((resolve, reject) => {
         dispatch({ type: 'SETBILLING', payload });
         resolve(true);
      });
   };

   const setShipping = (payload) => {
      return new Promise((resolve, reject) => {
         dispatch({ type: 'SETSHIPPING', payload });
         resolve(true);
      });
   };

   const setDelivery = (payload) => {
      return new Promise((resolve, reject) => {
         dispatch({ type: 'SETDELIVERY', payload });
         resolve(true);
      });
   };

   const setPickup = (payload) => {
      return new Promise((resolve, reject) => {
         dispatch({ type: 'SETPICKUP', payload });
         resolve(true);
      });
   };

   const clear = (payload) => {
      return new Promise((resolve, reject) => {
         dispatch({ type: 'CLEAR', payload });
         resolve(true);
      });
   };

   const contextValues = {
      addProduct,
      clear,
      increase,
      decrease,
      setShipping,
      setBilling,
      setDelivery,
      setPickup,
      setPayment,
      remove,
      ...state,
   };

   return <CartContext.Provider value={contextValues}>{children}</CartContext.Provider>;
};

export default CartContextProvider;
