import React, { useEffect } from 'react';
import Slider from 'react-slick';
import { useWindowSize } from '../../hooks/useWindowSize';

const defaultSliderAConfig = {
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  fade: true,
  autoplay: false,
  centerMode: true,
};

const defaultSliderBConfig = {
  dots: false,
  autoplay: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  focusOnSelect: true,
  centerMode: true,
};

export const MultipleSlider = ({
  images,
  configa,
  configb,
  goto = 0,
  setGoTo = () => console.log('set go to'),
  handleSliderChange = (s) => console.log('slider changes', { s }),
}) => {
  const [sliderA, setSliderA] = React.useState(null);
  const [sliderB, setSliderB] = React.useState(null);

  const infinite = images.length > 2 ? true : false;

  useEffect(() => {
    console.log({ goto }, sliderA, sliderB);
    if (goto > -1 && sliderA && sliderB) {
      sliderA.slickGoTo(goto);
      // sliderB.slickGoTo(goto);
      setGoTo(null);
    }
  }, [goto, sliderA, sliderB, setGoTo]);

  const [sliderConfigA, setSliderConfigA] = React.useState({
    ...defaultSliderAConfig,
  });

  const [sliderConfigB, setSliderConfigB] = React.useState({
    ...defaultSliderBConfig,
    infinite,
    beforeChange: handleSliderChange,
    // asNavFor: sliderForRef,
  });

  React.useEffect(() => {
    if (configa && configb) {
      setSliderConfigA({ ...defaultSliderAConfig, ...configa });
      setSliderConfigB({
        ...defaultSliderBConfig,
        infinite,
        ...configb,
        beforeChange: handleSliderChange,
        afterChange: (a, b) => console.log({ a }, { b }),
      });
    }
  }, [images, configa, configb, handleSliderChange]);

  useEffect(() => {
    // const containerW = document.querySelector('.index-slider').clientWidth;
    // const childrenW = 132 * images.length;
    // const infinite = containerW < childrenW;
    setSliderConfigB({
      ...sliderConfigB,
      asNavFor: sliderA,
      infinite,
      beforeChange: handleSliderChange,
      afterChange: (a, b) => console.log({ a }, { b }),
    });
  }, [sliderA]);
  useEffect(() => {
    setSliderConfigA({
      ...sliderConfigA,
      asNavFor: sliderB,
      afterChange: (a, b) => console.log({ a }, { b }),
      beforeChange: handleSliderChange,
    });
  }, [sliderB]);

  return (
    <>
      <Slider ref={(slider) => setSliderA(slider)} {...sliderConfigA}>
        {images.map((i, x) => (
          <div key={`slider-${i}-${x}`}>
            <img src={i} alt="" className="responsive-img" />
          </div>
        ))}
      </Slider>
      <Slider
        className="index-slider"
        ref={(slider) => setSliderB(slider)}
        {...sliderConfigB}
      >
        {images.map((i, x) =>
          infinite ? (
            <div key={`slider-${x}-${i}`}>
              <img
                src={i}
                alt=""
                // style={{ height: '150px' }}
                style={{ width: '100%', height: 'auto' }}
                className="responsive-idmg"
              />
            </div>
          ) : (
            <div
              key={`slider-${x}-${i}`}
              className="no-infinite"
              onClick={() => handleSliderChange(x, x)}
            >
              <img
                src={i}
                alt=""
                // style={{ height: '150px' }}
                style={{ width: '100%', height: 'auto' }}
                className="responsive-idmg"
              />
            </div>
          )
        )}
      </Slider>
    </>
  );
};

export const SingleSlider = ({ images, configa, configb }) => {
  const [sliderA, setSliderA] = React.useState(null);
  const [sliderB, setSliderB] = React.useState(null);

  const [sliderConfigA, setSliderConfigA] = React.useState({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    autoplay: false,
    centerMode: true,
    // asNavFor: sliderNavRef,
  });

  const [sliderConfigB, setSliderConfigB] = React.useState({
    dots: true,
    autoplay: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    focusOnSelect: true,
    centerMode: true,
    // asNavFor: sliderForRef,
  });

  React.useEffect(() => {
    configa && setSliderConfigA(configa);
    configb && setSliderConfigB(configb);
  }, []);

  useEffect(() => {
    setSliderConfigB({ ...sliderConfigB, asNavFor: sliderA });
  }, [sliderA]);
  useEffect(() => {
    setSliderConfigA({ ...sliderConfigA, asNavFor: sliderB });
  }, [sliderB]);

  return (
    <>
      <Slider {...sliderConfigA}>
        {images.map((i, x) => (
          <div key={x}>
            <img src={i} alt="" className="responsive-img" />
          </div>
        ))}
      </Slider>
      {/* <Slider ref={(slider) => setSliderB(slider)} {...sliderConfigB}>
            {images.map((i, x) => (
               <div key={x}>
                  <img src={i} alt="" className="responsive-img" />
               </div>
            ))}
         </Slider> */}
    </>
  );
};

export default MultipleSlider;
