import React, { useState, useEffect } from 'react';
//import withAuthentication from "./context/auth-provider";
import { RequireAuth } from './routes';
import { AuthProvider } from './context/auth-provider';
import IntuitProvider from './context/IntuitContext';
import HomePage from './containers/HomePage';
//import ProductPage from "./containers/ProductPage";
import ContactPage from './containers/ContactPage';
import CareersPage from './containers/CareersPage';
import PrivacyPolicyPage from './containers/PrivacyPolicyPage';
import CorporateStoresPage from './containers/CorporateStoresPage';
import CartPage from './containers/AuthorizeNetCartPage';
import AboutPage from './containers/AboutPage';
import ScreenPrintingPage from './containers/ScreenPrintingPage';
import EmbroideryPage from './containers/EmbroideryPage';
import SchoolUniformsPage from './containers/SchoolUniformsPage';
// import DesignPage from './containers/DesignPage';
import DesignCartPage from './containers/DesignPage/cart';
import SchoolsPage from './containers/SchoolsPage';
// import CartCheckoutPage from "./containers/CartPage/cart.checkout";
// import CartReviewPage from "./containers/CartPage/cart.review";
import CartConfirmationPage from './containers/AuthorizeNetCartPage/cart.confirmation';
import LoginPage from './containers/LoginPage';
import PartnersPage from './containers/PartnersPage';
import AccountPage from './containers/AccountPage';
import EULAPage from './containers/EULAPage';
// import AuthorizeNetCartPage from "./containers/AuthorizeNetCartPage";
import AuthorizeNetCartCheckoutPage from './containers/AuthorizeNetCartPage/cart.checkout';
import EMSVerify from './containers/EMSVerifyPage';

import NotFoundPage from './containers/NotFoundPage';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	useLocation,
} from 'react-router-dom';
import HomePageLayout from './Layout/homePage';
import FireDepartment from './containers/FireDepartment';
import CartPageConfrmation from './containers/CartPage/cart.confirmation';

export const ScrollToTop = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return null;
};

const RedirectFB = () => {
	const location = useLocation();

	// const uri = `https://shop.bahamajoes.com`;
	const uri = `https://shop.bahamajoes.com${location.pathname}${location.search}`;
	window.location.href = uri;
	return <></>;
};

const localStorage_EMS = JSON.parse(sessionStorage.getItem("EMS"));

const Layout = () => { };

const App = () => {
	return (
		<AuthProvider>
			<ScrollToTop />
			<Routes>
				<Route element={<HomePageLayout />}>
					<Route path="/" element={<HomePage />} />
					<Route path="/Contact-Us" element={<ContactPage />} />
					<Route path="/Careers" element={<CareersPage />} />
					<Route path="/Privacy-Policy" element={<PrivacyPolicyPage />} />

					<Route path="/EULA" element={<EULAPage />} />
					<Route path="/Corporate-Stores" element={<CorporateStoresPage />} />
					<Route
						path="/Corporate-Stores/:store"
						element={<CorporateStoresPage />}
					/>
					<Route path="/cart" element={<CartPage />} />
					<Route
						path="/cart/Secure.Checkout"
						element={<AuthorizeNetCartCheckoutPage />}
					/>
					<Route
						path="/Cart.Confirmation/:oi"
						element={<CartConfirmationPage />}
					/>
					<Route path="/About" element={<AboutPage />} />
					<Route path="/ScreenPrinting" element={<ScreenPrintingPage />} />
					<Route path="/Embroidery" element={<EmbroideryPage />} />
					<Route path="/School-Uniforms" element={<RedirectSU />} />
					{/* <Route path="/Design" element={<DesignPage />} /> */}
					<Route path="/l_cart" element={<DesignCartPage />} />
					<Route path="/login" element={<LoginPage />} />
					<Route path="/register" element={<LoginPage />} />
					<Route path="/reset/:guid" element={<LoginPage />} />
					<Route path="/Schools/:school" element={<RedirectSU />} />
					<Route path="/Partners/:school" element={<PartnersPage />} />
					<Route path="/EMSVerify" element={<FireDepartment />} />
					<Route path="/FireDepartmentVierfy" element={<FireDepartment />} />
					<Route path="/thankyou" element={<CartPageConfrmation />} />

					<Route
						path="/account"
						element={
							<RequireAuth>
								<AccountPage />
							</RequireAuth>
						}
					/>
					<Route
						path="/account/redir"
						element={
							
							localStorage_EMS ?  <SchoolsPage />  : <RequireAuth>	<AccountPage /></RequireAuth>
							
						}
					/>
					<Route
						path="/Schools/chatham-county-ems"
						element={
							<>
								
									{localStorage_EMS && localStorage_EMS.school === "chatham-county-ems" ? <SchoolsPage /> : <EMSVerify />}
								
							</>
						}
					/>
					<Route
						path="/account/:tab"
						element={
							<RequireAuth>
								<AccountPage />
							</RequireAuth>
						}
					/>
					<Route
						path="/Schools/Chatham-Fire-Department"
						element={
							
								localStorage_EMS && localStorage_EMS.school == "Chatham-Fire-Department" ?  <SchoolsPage /> : <FireDepartment />
							
						}
					/>
					<Route path="/product" element={<RedirectFB />} />
					<Route path="/product/*" element={<RedirectFB />} />

					<Route path="*" element={<NotFoundPage />} />
				</Route>
			</Routes>
		</AuthProvider>
	);
};

{
	/* 
  <HomeRoute exact path="/" component={<HomePage />} />
<HomeRoute
  exact
  path="/Corporate-Stores"
  component={<CorporateStoresPage />}
/>
<HomeRoute
  path="/Corporate-Stores/:store"
  component={CorporateStoresPage}
/>
<HomeRoute exact path="/cart" component={CartPage} />
<HomeRoute
  exact
  path="/Secure.Checkout"
  component={AuthorizeNetCartCheckoutPage}
/>
<HomeRoute
  path="/Cart.Confirmation/:oi"
  component={CartConfirmationPage}
/>
<HomeRoute exact path="/About" component={AboutPage} />
<HomeRoute
  exact
  path="/ScreenPrinting"
  component={ScreenPrintingPage}
/>
<HomeRoute exact path="/Embroidery" component={EmbroideryPage} />
<HomeRoute
  exact
  path="/School-Uniforms"
  component={SchoolUniformsPage}
/>
<HomeRoute exact path="/Design" component={DesignPage} />
<HomeRoute exact path="/l_cart" component={DesignCartPage} />
<HomeRoute exact path="/login" component={LoginPage} />
<HomeRoute exact path="/register" component={LoginPage} />
<HomeRoute exact path="/reset/:guid" component={LoginPage} />
<HomeRoute
  exact
  path="/Schools/:school"
  component={(props) => <SchoolsPage {...props} />}
/>
<HomeRoute
  exact
  path="/Partners/:school"
  component={(props) => <PartnersPage {...props} />}
/>
<PrivateRoute exact path="/account" component={AccountPage} />
<PrivateRoute exact path="/account/:tab" component={AccountPage} />
	
<HomeRoute path="/product" component={RedirectFB} />
<HomeRoute path="/product/*" component={RedirectFB} /> */
}
{
	/* <Route component={NotFoundPage} /> */
}
function RedirectSU() {
	window.location.replace("https://bahamajoesuniforms.myshopify.com/")
}
export default App;
