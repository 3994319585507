import React from 'react';
import { Link } from 'react-router-dom';
import PgTitle from './careers.png';
import pdf from './EEO_Supplement.pdf';
import pet003 from './pet003.jpg';
import pet004 from './pet004.jpg';
import pet005 from './pet005.jpg';
import pet006 from './pet006.jpg';
import { Helmet } from 'react-helmet';

const Page = () => {
  return (
    <div>
      <Helmet>
        <title>Careers : Bahama Joe&apos;s</title>
      </Helmet>
      <div className="container">
        <div className="center-align">
          <br />
          <br />
          <img src={PgTitle} />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col s12 m12 l8 offset-l2 leftAlignAdj">
            <h5 style={{ textTransform: 'uppercase' }}>
              Employment Opportunities with Bahama Joe’s
            </h5>
            <h4 className="bahama-green-text">What does Bahama Joe’s do?</h4>
            <p>
              Bahama Joe's offers custom screen printing, custom embroidery, and
              personalized promotional products for small businesses,
              corporations, and individuals ordering in bulk for parties,
              weddings, events, or organized teams.
            </p>

            <p>
              Bahama Joe’s steadfast success is rooted in offering quality
              products, with on demand turnaround times, and friendly customer
              service. Today, Bahama Joe’s is the leading custom t-shirt screen
              printer in Savannah, Georgia.
            </p>
            <h5>
              Careers in screen printing, embroidery and personalized
              promotional products
            </h5>
            <p>
              Bahama Joe's embraces the opportunity to think bigger and aim
              higher. We are confident in knowing our custom screen printing and
              custom embroidery skills produce high quality custom t-shirts,
              polos, hats, bags, and personalized promotional products. With
              strong leadership and bold vision, Bahama Joe’s offers quality
              products with personal customer service, from sales, to building
              custom designs, to production, to delivery.
            </p>
            <h5>Career overview</h5>
            <p>
              Bahama Joe’s is always looking for people with talent and a
              passion to learn. We offer a wide variety of careers with varying
              levels of experiences and training.
            </p>
            <ul className="discStyle left-align">
              <li>Embroidery</li>
              <li>Graphic Design Artist</li>
              <li>Internships</li>
              <li>Marketing</li>
              <li>Production</li>
              <li>Purchasing</li>
              <li>Receiving</li>
              <li>Sales</li>
              <li>Screen Printing</li>
              <li>Web Design</li>
            </ul>
            <p>
              Share your cover letter and resume with us today by emailing us at{' '}
              <a href="mailto:shopbahamajoes@gmail.com">
                shopbahamajoes@gmail.com
              </a>
              .
            </p>
            <p>
              Equal Employment Opportunity Statement
              <br />
              The policy of Bahama Joe's is to extend opportunities to qualified
              applicants and employees on an equal basis regardless of an
              individual’s age, race, color, sex, religion, national origin,
              disability, sexual orientation, gender identity/expression or
              veteran status.
            </p>
            <p>
              <a href={pdf} target="_blank">
                Click here to view EEO is the Law Poster & Supplement
              </a>
            </p>
            <h4 className="bahama-green-text">Why work with Bahama Joe’s?</h4>
            <p>
              Bahama Joe's combines the personal care of a startup with the
              industry-leading knowledge of 40+ years of experience in Savannah,
              Georgia. We offer careers that are built on a strong foundation
              and are motivated by hard work and new opportunity. Explore a few
              of the many reasons to drive your career with Bahama Joe’s:
            </p>
            <ul className="discStyle left-align">
              <li>
                <b>Located in Savannah, Georgia.</b> We build local
                relationships with individuals, small businesses and
                corporations. Our products have a direct impact on our local
                economy. We see our products in-use across Savannah and the low
                country every day.
              </li>

              <li>
                <b>Benefits.</b> We offer full-time positions with optional
                benefit packages ranging from medical, to vision, to dental
                plans.
              </li>
              <li>
                <b>Mentorship and training.</b> Our size and expertise offers
                employees access to industry-leading mentorship and on-site
                training for a career filled with growth and learning.
              </li>
              <li>
                <b>Opportunity for growth.</b> We started out as a one-person
                business and have become local industry leaders in custom screen
                printing and custom embroidery. We look for talented people,
                looking to grow their careers, as we grow as a company.
              </li>
              <li>
                <b>Hours of operations.</b> Our typical work schedule is managed
                from 8:00am to 5:00pm, Monday thru Friday. We work hard during
                the week to meet production deadlines and customer’s schedules
                to have our weekends off work. We encourage our employees to
                have a family-work life balance.
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* <div className="row container paddingTileAdj">
				<div className="grid">
					<div className="col s6 m4 l4 animatedParent animateOnce">
						<figure className="effect-lily card animated fadeInUpShort">
							<img src={pet002} alt="img12" />
							<figcaption>
								<div>
									<h2>Custom Embroidery</h2>
									<p>
										Bahama Joe's uses the best-in-class embroidery machinery,
										Barudan, to produce high quality products.
									</p>
								</div>
								<a href="#embroidery">View more</a>
							</figcaption>
						</figure>
					</div>
					<div className="col s6 m4 l4 animatedParent animateOnce">
						<figure className="effect-lily card animated fadeInUpShort">
							<img src={pet002} alt="img12" />
							<figcaption>
								<div>
									<h2>Custom Screen Printing</h2>
									<p>
										Using in-house, state-of the art M&R equipment, Bahama Joe's
										produces custom screen printing orders on demand.
									</p>
								</div>
								<a href="#screen-printing">View more</a>
							</figcaption>
						</figure>
					</div>
					<div className="col s6 m4 l4 animatedParent animateOnce">
						<figure className="effect-lily card animated fadeInUpShort">
							<img src={pet003} alt="img12" />
							<figcaption>
								<div>
									<h2>Custom Promotional Products</h2>
									<p>
										Take your logo and advertise with Bahama's Joe's promotional
										products. Make a statement with your company logo.
									</p>
								</div>
								<a href="#Burial-Services">View more</a>
							</figcaption>
						</figure>
					</div>
					<div className="col s6 m4 l4 animatedParent animateOnce">
						<figure className="effect-lily card animated fadeInUpShort">
							<img src={pet004} alt="img12" />
							<figcaption>
								<div>
									<h2>Quick Turnaround Times</h2>
									<p>
										Bahama Joe's streamlines orders to meet customer deadlines.
										Screen printing and embroidery orders produced on demand.
									</p>
								</div>
								<a href="#Pet-Funeral">View more</a>
							</figcaption>
						</figure>
					</div>
					<div className="col s6 m4 l4 animatedParent animateOnce">
						<figure className="effect-lily card animated fadeInUpShort">
							<img src={pet005} alt="img12" />
							<figcaption>
								<div>
									<h2>Friendly Customer Service</h2>
									<p>
										Customer satisfaction is top priority for Bahama Joe's. We
										pride ourselves in being a local company.
									</p>
								</div>
								<a href="#Pet-Funeral">View more</a>
							</figcaption>
						</figure>
					</div>
					<div className="col s6 m4 l4 animatedParent animateOnce">
						<figure className="effect-lily card animated fadeInUpShort">
							<img src={pet006} alt="img12" />
							<figcaption>
								<div>
									<h2>High Quality Products</h2>
									<p>
										Bahama Joe's offers 1000s of high quality products. We match
										your vision to our products.
									</p>
								</div>
								<a href="#Pet-Funeral">View more</a>
							</figcaption>
						</figure>
					</div>
				</div>
			</div>
			<br />
			<br /> */}

      {/* <div
				className="media-panel"
				id="screen-printing"
				style={{ backgroundColor: "#363636" }}
			>
				<div className="media-panel__wrapper">
					<div className="media-panel__image" style={{ left: "0" }}>
						<br />
					</div>
					<div
						className="media-panel__text-wrapper"
						style={{ right: "0", left: "auto" }}
					>
						<div className="media-panel__text-inner white-text">
							<h2>Custom Screen Printing T-Shirts</h2>

							<p>
								With more than four decades of custom screen printing
								experience, we have streamlined the custom screen printing
								process for customers. Bahama Joe's provides quality products,
								access to our in-house design team and quick screen printing
								turnaround times.
							</p>
							<br />
							<Link to="/login" className="cyanBtn">
								SHOP NOW
							</Link>
						</div>
					</div>
				</div>
			</div>

			<div
				className="media-panel"
				id="embroidery"
				style={{ backgroundColor: "#363636" }}
			>
				<div className="media-panel__wrapper">
					<div className="media-panel__image">
						<br />
					</div>
					<div className="media-panel__text-wrapper">
						<div className="media-panel__text-inner white-text">
							<h2>Custom Embroidery</h2>
							<p>
								Custom embroidery incorporates a brand or logo into the design
								of polos, hats or bags by stitching directly on to the fabric.
								By giving customers another way to express their brands, and
								continuing to offer personal customer service, Bahama Joe’s
								immediately noticed an increase in demand for custom embroidery.
							</p>

							<br />
							<Link to="/login" className="cyanBtn">
								SHOP NOW
							</Link>
						</div>
					</div>
				</div>
			</div>

			<div className="media-panel" style={{ backgroundColor: "#363636" }}>
				<div className="media-panel__wrapper">
					<div className="media-panel__image" style={{ left: "0" }}>
						<br />
					</div>
					<div
						className="media-panel__text-wrapper"
						style={{ right: "0", left: "auto" }}
					>
						<div className="media-panel__text-inner white-text">
							<h2>Personalized Promotional Products</h2>
							<p>
								In early 2000s, Bahama Joe’s started selling personalized
								promotional products as another form of branding for local
								Savannah companies. Offering 1000s of promotional products,
								Bahama Joe’s take pride in personally working with customers to
								find the perfect items to help promotion their brand, design or
								logo.
							</p>

							<p>
								Bahama Joe’s has everything from cost effective promotional
								items, to one-of-kind high end promotional products.
								Personalized promotional products can be used for meetings,
								gifts, events, product branding, tradeshows, and giveaways.
							</p>
						</div>
					</div>
				</div>
			</div>
			<br />
			<br /> */}
      {/* <div className="row container">
				<div className="col s12 m12 l12">
					<h1 className="" style={{ color: "#363636" }}>
						Why Bahama Joe's ?
					</h1>
					<h2>
						Savannah's Custom Screen Printing and Embroidery Industry Leader
					</h2>
					<p>
						Founded in Savannah, Georgia in 1977, Bahama Joe’s has been driving
						the local Savannah custom screen printing and embroidery industry,
						ever since - offering custom t-shirts and personalized promotional
						products.
					</p>

					<p>
						Today, Bahama Joe’s is the leading custom screen printing t-shirt
						provider in the Savannah and surrounding low country area. Bahama
						Joe’s steadfast success is rooted in offering quality products,
						custom screen printing and embroidery with quick turnaround times,
						and friendly customer service. Bahama Joe’s continues to build on
						the positive reputation established over 40 years ago.
					</p>

					<p>
						If you need custom screen printed t-shirts, custom embroidered
						polos, hats, or your company logo on personalized promotional
						products for your next event, party, tradeshow, or conference, let
						Bahama Joe’s be your local Savannah partner.
					</p>

					<p>
						Bahama Joe’s is successful when our customers are successful. So,
						you can rely on us to help you make that first impression today.
						Select from our wide variety of products online or call us at (912)
						233-0060 to get your order started today.
					</p>
				</div>
			</div> */}
      <br />
      <br />
    </div>
  );
};

export default Page;
