import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "../EMSVerifyPage/index.module.css";


const FireDepartment = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("")

    const verifyEmail = () => {
        const domain = 'https://bahamajoes.com/api'
        const localhost = 'http://localhost:3360'
        axios
          .get(`${domain}/users/fireDepartment/${email}`)
          .then((result) => {
            if (result.data.results.length !== 0) {
              sessionStorage.removeItem("EMS");
              sessionStorage.setItem(
                "EMS",
                JSON.stringify({ school: "Chatham-Fire-Department" })
              );
              sessionStorage.setItem("EMS_EMAIL", email);
              navigate("/Schools/Chatham-Fire-Department");
              window.location.reload();
            } else {
              navigate("/FireDepartmentVierfy");
              setMessage("Try again");
            }
          })
          .catch((err) => console.log(err));
    }
    return (
        <div className={styles.outerContainer}>
            <div className={styles.innerContainer}>
                <h5>Welcome!</h5>
                <p>
                    Enter your email address below to begin shopping.<br />
                    Please contact Bahama Joe's at (912) 233-0060 <br />if you have any problems accessing the store.
                </p>

                <div>
                    <input
                        className={styles.input}
                        type="text"
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <button onClick={verifyEmail}>Submit</button>
                <h6>{message ? message : ""}</h6>
            </div>
        </div>
    )
}
export default FireDepartment;
