import React, { createContext, useState } from 'react';
import moment from 'moment';

export const IntuitContext = createContext();

const initState = async () => {
   return;
};

const IntuitProvider = ({ children }) => {
   // const memorizedReducer = useCallback(() => createReducer(), []);
   const [state, setState] = useState({
      access_token: {
         value: '',
         expires: moment(),
      },
   });

   const contextValues = {
      ...state,
   };

   return <IntuitContext.Provider value={contextValues}>{children}</IntuitContext.Provider>;
};

export default IntuitProvider;
