import React from 'react';
// import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import M from 'materialize-css';
import axios from 'axios';
import { API_URL } from '../../config';

const Register = ({ openLogin, ...props }) => {
   const { handleSubmit, register, errors, getValues } = useForm();
   // const [username, setUsername] = useState("");
   // const [password, setPassword] = useState("");

   // const history = useHistory();

   const onSubmit = (values) => {
      const { password, confirmPassword } = values;
      // const { auth, history } = props;

      if (password !== confirmPassword) {
         M.toast({ html: 'Passwords, must match', classes: 'red white-text' });
         return false;
      }

      axios({
         method: 'POST',
         url: API_URL + 'users.cfc?method=register',
         data: { ...values, role: 'basic' },
         headers: { 'Content-Type': 'application/json' },
      })
         .then((resp) => resp.status === 200 && resp.data)
         .then((data) => {
            if (data.success) {
               M.toast({
                  html: 'Thank you for your registering.',
               });
               document.getElementById('register-form').reset();
               openLogin('login');
            } else {
               M.toast({
                  html: 'Unable to compelete setup, does your account already exist?',
               });
            }
         });
      // .finally((data) => history.push('/login'));
   };

   return (
      <form name="register-form" id="register-form" onSubmit={handleSubmit(onSubmit)}>
         <div className="row form-holder">
            <div className="input-field col s12">
               <input
                  type="text"
                  className="input"
                  ref={register({
                     required: 'First Name is required!',
                  })}
                  name="firstname"
                  id="firstname"
                  placeholder="First Name"
               />
               {/* <label className="active" htmlFor="firstname">
						firstname
					</label> */}
               <span className="helper-text red-text">{errors.firstname && errors.firstname.message}</span>
            </div>
            <div className="input-field col s12">
               <input
                  type="text"
                  className="input"
                  ref={register({
                     required: 'Last Name is required!',
                  })}
                  name="lastname"
                  id="lastname"
                  placeholder="Last Name"
               />
               {/* <label className="active" htmlFor="lastname">
						lastname
					</label> */}
               <span className="helper-text red-text">{errors.lastname && errors.lastname.message}</span>
            </div>
            <div className="input-field col s12">
               <input
                  type="text"
                  className="input"
                  ref={register({
                     required: 'Email is required!',
                  })}
                  name="email"
                  id="email"
                  placeholder="Email"
               />
               {/* <label className="active" htmlFor="email">
						email
					</label> */}
               <span className="helper-text red-text">{errors.email && errors.email.message}</span>
            </div>
            {/* <div className="input-field col s12">
               <input
                  type="text"
                  className="input"
                  ref={register({
                     required: 'Username is required!',
                     pattern: {
                        value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
                        message: 'Invalid Email Address',
                     },
                  })}
                  name="username"
                  id="reg_username"
                  placeholder="User Name"
               />
               <span className="helper-text red-text">
                  {errors.username && errors.username.message}
               </span>
            </div>*/}
            <div className="input-field col s12">
               <input type="password" ref={register({ required: 'Password is required!' })} className="input" name="password" id="reg_password" placeholder="Password" />
               <span className="helper-text red-text">{errors.password && errors.password.message}</span>
            </div>
            <div className="input-field col s12">
               <input
                  type="password"
                  ref={register({
                     required: 'Password is required!',
                     validate: {
                        matchesPreviousPassword: (value) => {
                           const { password } = getValues();
                           return password === value || 'Passwords should match!';
                        },
                     },
                  })}
                  name="confirmPassword"
                  id="confirmPassword"
                  className="input"
                  placeholder="Retype Password"
               />
               <span className="helper-text red-text">{errors.confirmPassword && errors.confirmPassword.message}</span>
            </div>
         </div>
         <button type="submit" className="btn submit-btn">
            Register
         </button>
      </form>
   );
};

export default Register;
