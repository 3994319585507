import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { AuthContext } from '../../context/auth-provider';
import M from 'materialize-css';
import { API_URL } from '../../config';
import Axios from 'axios';

export default ({ setState }) => {
  // const { handleSubmit, register, setError, errors } = useForm();
  const { handleSubmit, register, errors } = useForm();
  const [postErrors, setPostErrors] = useState('');
  // const [password, setPassword] = useState("");
  // const history = useNavigate();
  const { startReset } = useContext(AuthContext);

  const onSubmit = (values) => {
    const { unr: username } = values;
    // const { from } = this.auth.location.state || {
    // 	from: { pathname: '/authenticated' },
    // };

    startReset({ username }).then((response) => {
      console.log({ response });
      if (!response) {
        setPostErrors('Invalid Account');
        return;
      }
      M.toast({ html: 'Please check your email for a reset link' });
      setState('login');
      // navigate('/login');
    });
  };

  return (
    <form name="login-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="form-holder">
        <div className="center-align helper-text red-text">
          {postErrors && postErrors}
        </div>
        <div className="input-field col s6">
          <input
            type="text"
            ref={register({
              required: 'Username is required!',
              // pattern: {
              //    value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
              //    message: 'Invalid Email Address',
              // },
            })}
            className="input"
            name="unr"
            id="unr"
            placeholder="email@domain.com"
          />
          {/* <label className="active" htmlFor="username">
												Username
											</label> */}
          <span className="helper-text red-text">
            {errors.un && errors.un.message}
          </span>
        </div>
      </div>
      <button className="btn submit-btn">Reset</button>
      <p className="center-align">
        <a href="#!" onClick={() => setState('login')}>
          <small>Return to Login</small>
        </a>
      </p>

      <p className="center-align">
        If you are having trouble logging in, you may need to clear your
        browser's cache. Please click the link below for instructions for your
        system/browser.
        <br />
        <br />
        <a
          href="https://www.whatismybrowser.com/guides/how-to-clear-cookies-browsing-history-and-cache/auto"
          target="_blank"
          className="goldBtn"
          rel="noopener noreferrer"
        >
          Instructions
        </a>
      </p>
    </form>
  );
};

export const DoResetForm = ({ match, setState }) => {
  // const { handleSubmit, register, setError, errors } = useForm();
  const { handleSubmit, register, errors, getValues } = useForm();
  const [postErrors, setPostErrors] = useState('');
  // const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { handleReset } = useContext(AuthContext);

  const { guid } = useParams();

  const onSubmit = (values) => {
    const { new_pw: password } = values;
    // const { from } = this.auth.location.state || {
    // 	from: { pathname: '/authenticated' },
    // };

    handleReset({ password, guid }).then((response) => {
      console.log({ response });
      if (!response) {
        setPostErrors('Unable to reset your password');
        return;
      }
      M.toast({ html: 'Your password has been reset!' });
      // setState('login');
      navigate('/login');
    });
  };

  useEffect(() => {
    const checkGUID = () => {
      return Axios({
        url: `${API_URL}users.cfc?method=checkGUID`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: { guid },
      }).then((r) => r.status === 200 && r.data);
    };

    guid &&
      guid.length > 0 &&
      checkGUID()
        .then((d) => !d.success && navigate('/login'))
        .catch((e) => navigate('/login'));
  }, [guid, navigate]);

  return (
    <form name="login-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="form-holder">
        <div className="center-align helper-text red-text">
          {postErrors && postErrors}
        </div>
        <div className="input-field col s6">
          <input
            type="password"
            ref={register({ required: 'Password is required!' })}
            className="input"
            name="new_pw"
            id="new_pw"
            placeholder="Password"
          />
          <span className="helper-text red-text">
            {errors.pw && errors.pw.message}
          </span>
        </div>
        <div className="input-field col s6">
          <input
            type="password"
            ref={register({
              validate: {
                passwordEqual: (value) =>
                  value === getValues().new_pw || 'Passwords must match!',
              },
            })}
            className="input"
            name="confirm_pw"
            id="confirm_pw"
            placeholder="Confirm Password"
          />
          <span className="helper-text red-text">
            {errors.confirm_pw && errors.confirm_pw.message}
          </span>
        </div>
      </div>
      <button className="btn submit-btn">Reset</button>
      <p className="center-align">
        <a href="#!" onClick={() => setState('login')}>
          <small>Return to Login</small>
        </a>
      </p>
    </form>
  );
};
