import React, { useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useForm } from 'react-hook-form';
// import AddressesTab from './addresses.bak';
import AddressesTab from './addresses';
import M from 'materialize-css';
import 'react-tabs/style/react-tabs.css';
import Axios from 'axios';
import { API_URL } from '../../config';
import { AuthContext } from '../../context/auth-provider';
import { getAddresses, getOrders } from './api';
import { OrdersTab } from './orders';
import title_image from './images/BJ-MyAccount.png';
import { Helmet } from 'react-helmet';

const Wrapper = () => {
  const [[orders, profile, addresses, loading], setDetails] = useState([
    [],
    {},
    [],
    true,
  ]);

  const { token, user } = useContext(AuthContext);

  useEffect(() => {
    Axios.all([getOrders(token), getAddresses(token)]).then(
      Axios.spread((orders, addresses) => {
        setDetails([orders.data, user, addresses.data, false]);
      })
    );
  }, []);

  const args = { orders, profile, addresses, loading };

  return <Page {...args} />;
};

const Page = ({ orders, profile, addresses, loading }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { logout } = useContext(AuthContext);

  const tabFocusText = location.pathname.split('/')[2];

  const whatTab = (t = '') => {
    switch (t.toLowerCase()) {
      case 'orders':
        return 0;
        break;
      case 'profile':
        return 1;
        break;
      case 'addresses':
        return 2;
        break;
      default:
        return 0;
    }
  };

  return (
    <>
      <Helmet>
        <title>Account : Bahama Joe&apos;s</title>
      </Helmet>
      <div className="">
        <div className="container center-align">
          <div className="col s12 m12 l12">
            <button
              onClick={() => {
                logout();
                navigate('/login');
              }}
              style={{ marginTop: '20px' }}
              className="btn right"
              to="/logout"
            >
              LOGOUT
            </button>
            <br />
            <br />
            <img src={title_image} alt="" />
            <br />
            <br />
          </div>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="row">
          <Tabs
            defaultIndex={whatTab(tabFocusText)}
            onSelect={(index) => console.log(index)}
          >
            <TabList>
              <Tab>Orders</Tab>
              <Tab>Profile</Tab>
              <Tab>Address Book</Tab>
            </TabList>
            <TabPanel>
              {!loading ? (
                <OrdersTab addresses={addresses} orders={orders} />
              ) : (
                <Loader />
              )}
            </TabPanel>
            <TabPanel>
              {!loading ? <ProfileTab profile={profile} /> : <Loader />}
            </TabPanel>
            <TabPanel>
              {!loading ? <AddressesTab addresses={addresses} /> : <Loader />}
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </>
  );
};

const Loader = () => (
  <div className="progress">
    <div className="indeterminate"></div>
  </div>
);

const ProfileTab = ({ profile }) => {
  const { firstname, lastname, email } = profile;
  const { token } = useContext(AuthContext);

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      firstname,
      lastname,
      email,
      password: '',
    },
  });

  const onSubmit = (data) => {
    Axios({
      method: 'POST',
      url: `${API_URL}users.cfc?method=updateAccount`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data,
    })
      .then((r) => r.status == 200 && r.data)
      .then((d) => {
        M.toast({ html: d.message });
      });
  };

  return (
    <>
      <div
        className="card"
        style={{ width: '500px', margin: '0 auto', maxWidth: '100%' }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="card-content">
            <span className="card-title">User Info</span>
            <div className="row">
              <div className="col s12 input-field" style={{ marginTop: '0' }}>
                <label style={{ position: 'relative' }} htmlFor="firstname">
                  First Name
                </label>
                <input
                  id="firstname"
                  name="firstname"
                  ref={register({
                    required: 'First name is required',
                  })}
                />
                <span className="red-text helper-text">
                  {errors && errors.firstname && errors.firstname.message}
                </span>
              </div>
              <div className="col s12 input-field" style={{ marginTop: '0' }}>
                <label style={{ position: 'relative' }} htmlFor="lastname">
                  Last Name
                </label>
                <input
                  id="lastname"
                  name="lastname"
                  ref={register({
                    required: 'Last name is required',
                  })}
                />
                <span className="red-text helper-text">
                  {errors && errors.lastname && errors.lastname.message}
                </span>
              </div>
              <div className="col s12 input-field" style={{ marginTop: '0' }}>
                <label style={{ position: 'relative' }} htmlFor="email">
                  Email
                </label>
                <input
                  id="email"
                  type="email"
                  name="email"
                  ref={register({
                    required: true,
                    pattern: /^\S+@\S+$/i,
                  })}
                />
                <span className="red-text helper-text">
                  {errors && errors.email && errors.email.message}
                </span>
              </div>
              <div className="col s12 input-field" style={{ marginTop: '0' }}>
                <label style={{ position: 'relative' }} htmlFor="email">
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  ref={register}
                />
                <span className="red-text helper-text">
                  {errors && errors.password && errors.password.message}
                </span>
              </div>
            </div>
          </div>
          <div className="card-action" style={{ padding: '20px' }}>
            <button type="submit" className="btn">
              UPDATE
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Wrapper;
