import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import M from 'materialize-css';
import BillingPage from './cart.checkout.form';
import { AuthContext } from '../../context/auth-provider';
import PgTitle from './imgs/BJ-checkout.png';
import { AdminNav } from '../SchoolsPage';
import { CartContext } from '../../context/CartContext';

const Page = () => {
	const { user, continueAsGuest } = useContext(AuthContext);
	const { cartItems } = useContext(CartContext);

	const allOptimTitlemax =
		cartItems.filter((el) => {
			if (el.school.toLowerCase().indexOf('optim') > -1) return true;
			if (el.school.toLowerCase().indexOf('titlemax') > -1) return true;
			return false;
		}).length === cartItems.length;

	if (!allOptimTitlemax && !user && !continueAsGuest) return <AskForGuest />;

	return <BillingPage />;
};

const AskForGuest = () => {
	const { login, setContinueAsGuest } = useContext(AuthContext);

	const { handleSubmit, register, errors } = useForm({
		defaultValues: {},
	});

	const onSubmit = (form) => {
		login(form).then((response) => {
			if (!response) {
				M.toast({ html: 'Invalid Credentials' });
				return;
			}
			M.toast({
				html: `Welcome back ${response.user.firstname} ${response.user.lastname}!`,
			});
			// history.push('/cart-');
		});
	};

	return (
		<div className="">
			<div className="center-align">
				<br />
				<br />
				<img src={PgTitle} />
				<br />
				<br />
			</div>
			{/* <AdminNav /> */}
			<div className="container">
				<div className="row">
					<form name="contactForm" onSubmit={handleSubmit(onSubmit)}>
						<div className="col s12 m4 offset-m4 z-depth-1">
							<div className="row">
								<h4 className="center-align">Registered Customers</h4>
								<div className="input-field col s12 m10 offset-m1">
									<input
										name="username"
										id="username"
										type="text"
										ref={register({ required: 'Username is required' })}
									/>
									<label htmlFor="username">
										Username <small>(email)</small>
									</label>
									<span className="red-text helper-text">
										{errors && errors.username && errors.username.message}
									</span>
								</div>
								<div className="input-field col s12 m10 offset-m1">
									<input
										name="password"
										id="password"
										type="password"
										ref={register({ required: 'Password is required' })}
									/>
									<label htmlFor="password">Password</label>
									<span className="red-text helper-text">
										{errors && errors.password && errors.password.message}
									</span>
								</div>
								<div className="col s12 center-align">
									<button type="submit" className="btn green white-text">
										Login
									</button>
								</div>
							</div>
							<div className="row">
								<hr />
								<h4 className="center-align">Guests and New Customers</h4>
								<div className="col s12 center-align">
									<button
										type="button"
										onClick={setContinueAsGuest}
										className="btn green white-text"
									>
										Continue as guest
									</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default Page;
