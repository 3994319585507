import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import Axios from 'axios';
import M from 'materialize-css';
import { SingleSlider, MultipleSlider } from '../../components/SlickSlider';
import ProductItem from '../../components/ProductItem';
import CurrencyFormat from 'react-currency-format';
import { CartContext } from '../../context/CartContext';
import { AuthContext } from '../../context/auth-provider';
import { AdminNav } from '../SchoolsPage';

import { useWindowSize } from '../../hooks/useWindowSize';
import { Helmet } from 'react-helmet';

let url = window.location.href;
let arr = url.split('/');
let arr2 = arr[2].split(':');
const URL = arr[0] + '//' + arr2[0] + '/cfc/api/';

const round = (number, decimalPlaces) =>
	Number(Math.round(number + 'e' + decimalPlaces) + 'e-' + decimalPlaces);
const currencyAttr = {
	decimalScale: 2,
	fixedDecimalScale: true,
	displayType: 'text',
	thousandSeparator: true,
	prefix: '$',
};

const settingFor = {
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: false,
	fade: true,
	autoplay: false,
	// asNavFor: sliderNavRef,
};
const settingNav = {
	dots: false,
	autoplay: false,
	speed: 500,
	slidesToShow: 3,
	responsive: [
		{
			breakpoint: 700,
			settings: {
				slidesToShow: 2,
			},
		},
	],
	slidesToScroll: 1,
	focusOnSelect: true,
	// asNavFor: sliderForRef,
};

const mergeColorImages = (images, colors) => {
	const imagesMerge = [
		...images,
		...colors.map((el) => ({
			...el,
			image_path: `/assets/products/medium/${el.image}`,
		})),
	];

	const noDuplicates = imagesMerge.reduce((arr, el) => {
		// remove a matching item
		const cleaned = arr.filter(
			(x) => x.image_path.indexOf(el.image_path) === -1
		);

		return cleaned.concat([el]);
	}, []);

	return noDuplicates;
};

const ProductSection = ({ initProduct, productClose, theme, school }) => {
	const { addProduct } = useContext(CartContext);

	const [product, setProduct] = useState(initProduct);

	// const [sliderImages, setSliderImages] = useState([]);
	const [goto, setGoTo] = useState(null);
	// const [oProduct, setOProduct] = useState({ quantity: 1 });

	const sizes = product.attributes.filter(
		(i) => i.attribute.toLowerCase() === 'size'
	);

	// console.log(mergeColorImages(product.images, product.colors));
	const mergedImages = mergeColorImages(product.images, product.colors);
	const sliderImages = mergedImages.reduce((all, i) => {
		return all.concat([`https://bahamajoes.com/${i.image_path}`]);
	}, []);

	useEffect(() => {
		setProduct({
			...initProduct,
			colors: initProduct.colors,
			color: initProduct.colors[0],
			size: sizes[0],
			quantity: 1,
		});

		setGoTo(0);
		// handleChange({ color: initProduct.colors[0] });
		let elems = document.querySelectorAll('.dropdown-trigger');
		M.Dropdown.init(elems, {});
	}, [initProduct]);

	useEffect(() => {
		let elems = document.querySelectorAll('.dropdown-trigger');
		M.Dropdown.init(elems, {});
	}, []);

	useEffect(() => {
		if (!product.color) return;

		sliderImages.forEach((el, i) => {
			if (el.includes(product.color.image)) {
				setGoTo(i);
			}
		});
	}, [product.color]);

	const addProductToCart = () => {
		const adjustment = product.size.price_adjustment;

		const productToAdd = {
			...product,
			school,
			attributes: [
				{ ...product.color, name: 'Color', value: product.color.label },
				{ ...product.size, name: 'Size', value: product.size.name },
			],
			price: product.price + adjustment,
			base_price: product.price,
			adjustment: adjustment,
		};

		parseInt(product.quantity) > 0 &&
			addProduct(productToAdd).then(() =>
				M.toast({
					classes: 'green white-text',
					html: `${productToAdd.name} added to the cart!`,
				})
			);
	};

	const s = {
		outOfStock: {
			color: 'red',
			fontWeight: 'bold',
			fontSize: '30px',
			textTransform: 'uppercase',
		},
	};

	const outOfStockMessage = (id) => {
		return 'OUT OF STOCK';
	};

	const handleChange = (value) => {
		setProduct((p) => ({ ...p, ...value }));
	};

	const handleSliderChange = (o, s, e) => {
		console.log(s, o, e);
		if (s === -1) return;
		while (s >= sliderImages.length) {
			s -= sliderImages.length;
		}

		setGoTo(s);
		const image = sliderImages[s].split('/');

		const color = initProduct.colors.filter((el) =>
			el.image.includes(image[image.length - 1])
		)[0];
		if (color) handleChange({ color });
	};

	return (
		<>
			<Helmet>
				<title>Partners : Bahama Joe&apos;s</title>
			</Helmet>
			<a onClick={productClose} className="closeBtn btn">
				Close{' '}
				<i
					className="fas fa-times"
					style={{ top: '2px', position: 'relative' }}
				></i>
			</a>
			<div className="container row valign-wrapper displayAdj">
				<div className="col s12 m5 l5">
					{sliderImages.length === 1 ? (
						<SingleSlider
							images={sliderImages}
							configa={settingFor}
							configb={settingNav}
						/>
					) : (
						<MultipleSlider
							images={sliderImages}
							configa={settingFor}
							configb={settingNav}
							{...{ goto, handleSliderChange, setGoTo }}
						/>
					)}
				</div>
				<div className="col s12 m7 l7 productInfoAdj">
					<div className="hero-text" style={{ paddingTop: '20px' }}>
						<h2
							className=""
							style={{
								margin: '0 0px 40px',
							}}
						>
							{product.name}
						</h2>
						<div
							dangerouslySetInnerHTML={{
								__html: product.description,
							}}
						/>

						<p style={{ fontSize: '20px', fontWeight: '600' }}>
							<CurrencyFormat
								{...currencyAttr}
								value={round(product.price, 2)}
							/>
						</p>
						{/* <p style={{ fontSize: '20px', fontWeight: '600' }}>${product.price}</p> */}

						<div className="col s12 m5 l5">
							<a
								className="dropdown-trigger selectStyle"
								href="#"
								data-target="dropdown1"
							>
								Color:{' '}
								{product &&
									product.color &&
									product.color.label.length > 0 &&
									product.color.label}
							</a>
							{product.colors && (
								<ul
									id="dropdown1"
									className="dropdown-content"
									style={{ maxHeight: '500px' }}
								>
									{product.colors.map((c, x) => (
										<li onClick={(e) => handleChange({ color: c })} key={x}>
											{c.label}
											<span
												style={{
													backgroundColor: c.color,
												}}
												className="whiteBoxColor"
											></span>
										</li>
									))}
								</ul>
							)}
						</div>
						<div className="col s12 m4 l4">
							<a
								className="dropdown-trigger selectStyle"
								href="#"
								data-target="dropdown20"
							>
								Size:{' '}
								{product.size &&
									product.size.name.length > 0 &&
									product.size.name}
							</a>
							{sizes && (
								<ul id="dropdown20" className="dropdown-content">
									{sizes.map((s, z) => (
										<li onClick={(e) => handleChange({ size: s })} key={z}>
											{s.name}
											{s.price_adjustment > 0 && (
												<span className="extraMoneyBox">
													<CurrencyFormat
														{...currencyAttr}
														prefix="+ $"
														value={round(s.price_adjustment, 2)}
													/>
												</span>
											)}
										</li>
									))}
								</ul>
							)}
						</div>
						<div className="col s12 m3 l3">
							<a
								className="dropdown-trigger selectStyle"
								href="#"
								data-target="dropdown3"
							>
								Quantity: {product.quantity > 0 && product.quantity}
							</a>
							<ul id="dropdown3" className="dropdown-content">
								{[1, 2, 3, 4, 5, 10].map((c, i) => (
									<li key={i} onClick={() => handleChange({ quantity: c })}>
										{c}
									</li>
								))}
							</ul>
						</div>
						{(product.sizingChart && product.sizingChart.length > 0) ||
							(product.shippingDoc && product.shippingDoc.length > 0 && (
								<div className="col s12 m6 l6">
									<p>
										{product.sizingChart && product.sizingChart.length > 0 && (
											<a
												href={product.sizingChart}
												style={{
													color: 'white',
													textDecoration: 'underline',
												}}
												target="_blank"
											>
												Size Chart
											</a>
										)}
										{product.shippingDoc && product.shippingDoc.length > 0 && (
											<>
												/
												<a
													href={product.shippingDoc}
													style={{
														color: 'white',
														textDecoration: 'underline',
													}}
													target="_blank"
												>
													Shipping and Handling
												</a>
											</>
										)}
									</p>
								</div>
							))}

						<div className="clearfix"></div>
						<div className="row">
							<div className="col s12 center-align">
								{product.outofstock === 0 && (
									<button
										onClick={addProductToCart}
										className="btn"
										style={theme.button}
									>
										ADD TO CART
									</button>
								)}
								{product.outofstock > 0 && (
									<div className="out-of-stock-notice" style={s.outOfStock}>
										{outOfStockMessage(product.id)}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const School = ({ school }) => {
	const { theme, products } = school;

	const [open, setOpen] = useState(false);
	const [product, setProduct] = useState(null);

	const { user, logout, token, ...rest } = useContext(AuthContext);

	const productOpen = (prod) => {
		console.log('do open');
		setProduct(prod);
		open
			? scrollTop()
			: setTimeout(() => {
					scrollTop();
			  }, 150);

		setOpen(true);
	};
	const productClose = () => {
		setProduct(null);
	};

	const scrollTop = () =>
		window.scrollTo({
			top: 150,
			left: 0,
			behavior: 'smooth',
		});

	const categories = products.reduce((tot, x) => {
		return tot.indexOf(x.cname) < 0 ? tot.concat([x.cname]) : tot;
	}, []);

	useEffect(() => {
		setProduct(null);
	}, [school]);

	const { cartItems } = useContext(CartContext);

	return (
		<>
			{/* <div className="pgTitle">
            <div className="row container center" style={{ ...theme }}>
               <div style={{ padding: '20px 0' }}></div>
            </div>
         </div> */}
			{/* <div className="parallax-container">
            <div className="parallax">
               <img src="https://cdn.inksoft.com/images/publishers/11094/stores/blessed_sacrament_school/img/customContent/School_Welcome_Inksoft_Banners_(5).png" />
            </div>
         </div> */}
			{/* {school.hero.length > 0 && <Parallax noShade source={`` + school.hero} />} */}
			{school.hero.length > 0 && (
				<div className="row container center" style={{ ...theme }}>
					<img
						src={`` + school.hero}
						style={{
							maxWidth: '100%',
							maxHeight: '400px',
							width: 'auto',
						}}
					/>
				</div>
			)}

			<AdminNav />

			<div
				className={product ? 'openProduct' : 'closeProduct'}
				style={{
					backgroundColor: '#fff',
					position: 'relative',
					zIndex: '1',
					borderBottom: '2px solid #606060a1',
					boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.51)',
				}}
			>
				{product && (
					<ProductSection
						school={school.name}
						theme={theme}
						initProduct={product}
						productClose={productClose}
					/>
				)}
			</div>

			<div style={{ backgroundColor: '#fff' }}>
				<br />
				{categories.map((cat, i) => (
					<div key={i} className="row container">
						<div className="col s12">
							<h2
								className="center-align"
								style={{
									marginBottom: '10px',
									marginTop: '20px',
								}}
							>
								{cat}
							</h2>
							{school.name.toLowerCase().indexOf('optim') > -1 && (
								<div className="center-align" style={{ marginBottom: '20px' }}>
									<Link
										className="btn btn-small"
										to={`/Corporate-Stores/optim`}
									>
										Back To main page
									</Link>
								</div>
							)}
							<div className="grid">
								{products
									.filter((x) => x.cname === cat)
									.map((p) => (
										<ProductItem click={productOpen} p={p} key={p.id} />
									))}
							</div>
						</div>
					</div>
				))}
				<br />
				<br />
				<br />
				<br />
			</div>
		</>
	);
};

const Page_Loader = ({ match }) => {
	const [[partner, loaded], setPartner] = useState([null, false]);
	const navigate = useNavigate();
	const params = useParams();

	useEffect(() => {
		const loadPartner = () => {
			Axios({
				url: `${URL}partners.cfc?method=getPartnerWProducts&url=${params.school}`,
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			})
				.then((res) => res.status === 200 && res.data.id && res.data)
				.then((d) => {
					d.id > 0 ? setPartner([d, true]) : navigate.push('/Corporate-Stores');
				})
				.catch((e) => console.log({ e }));
		};

		loadPartner();
	}, [params]);

	if (loaded && !partner) {
		// navigate.push('/');
	}

	// redirect to inksoft
	// if (match && match.params && match.params.school && match.params.school.toLowerCase() === 'optim') {
	//    setPartner([null, false]);
	//    window.open('https://bahamajoes.shop/optim/shop/home', '_blank');
	// }

	// if (match && match.params && match.params.school && match.params.school.toLowerCase() === 'titlemax') {
	//    setPartner([null, false]);
	//    window.open('https://bahamajoes.shop/titlemax_cares/shop/home', '_blank');
	// }
	return (
		<>
			{partner ? (
				<div className="store">
					<School school={partner} />
				</div>
			) : (
				<div
					className="progress-container container center-align valign-wrapper store"
					style={{ padding: '40px 0' }}
				>
					<div
						className="preloader-wrapper big active"
						style={{ margin: '0 auto' }}
					>
						<div className="spinner-layer spinner-blue-only">
							<div className="circle-clipper left">
								<div className="circle"></div>
							</div>
							<div className="gap-patch">
								<div className="circle"></div>
							</div>
							<div className="circle-clipper right">
								<div className="circle"></div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Page_Loader;
