import React, { useState, useEffect, useContext } from "react";
import { useLocation, Link, useParams } from "react-router-dom";
import { CartContext } from "../../context/CartContext";
import Axios from "axios";
import M from "materialize-css";
import { SingleSlider, MultipleSlider } from "../../components/SlickSlider";
import ProductItem from "../../components/ProductItem";
import CurrencyFormat from "react-currency-format";
import { AuthContext } from "../../context/auth-provider";
import cartImg from "./BJ-Cart.png";
import cartImgHover from "./BJ-Cart-ROLL.png";
// import Parallax from "../../components/LazyParallax";
import { API_URL } from "../../config";
import { Helmet } from "react-helmet";

let url = "https://bahamajoes.com/";
let arr = url.split("/");
let arr2 = arr[2].split(":");
const URL = arr[0] + "//" + arr2[0] + "/cfc/api/";

const round = (number, decimalPlaces) =>
  Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
const currencyAttr = {
  decimalScale: 2,
  fixedDecimalScale: true,
  displayType: "text",
  thousandSeparator: true,
  prefix: "$",
};

const settingFor = {
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  fade: true,
  autoplay: false,
  // asNavFor: sliderNavRef,
};
const settingNav = {
  dots: false,
  autoplay: false,
  speed: 500,
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
  slidesToScroll: 1,
  focusOnSelect: true,
  // asNavFor: sliderForRef,
};

const Links = ({ id }) => {
  const [[data, loading], setData] = useState([[], false]);

  const Fetch = (i = 0) => {
    setData([[], true]);
    Axios({
      method: "GET",
      url: `${API_URL}partners.cfc?method=getLinks&id=${i}`,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.status === 200 && r.data)
      .then((d) => setData([d, false]))
      .catch((e) => {
        console.log({ e });
        setData([[], false]);
      });
  };

  useEffect(() => id && id > 0 && Fetch(id), []);

  useEffect(() => id && id > 0 && Fetch(id), [id]);

  console.log({ loading });

  return data.map((el, i) => {
    const link =
      el.link.indexOf("http") === -1 ? `/assets/pdf/${el.link}` : el.link;
    return (
      <a
        key={i}
        href={link}
        target="_blank"
        className="btn btnFontSizeAdj"
        style={{ marginRight: "15px" }}
      >
        {el.label}
      </a>
    );
  });
};

const mergeColorImages = (images, colors) => {
  const imagesMerge = [
    ...images,
    ...colors.map((el) => ({
      ...el,
      image_path: `/assets/products/medium/${el.image}`,
    })),
  ];

  const noDuplicates = imagesMerge.reduce((arr, el) => {
    // remove a matching item
    const cleaned = arr.filter(
      (x) => x.image_path.indexOf(el.image_path) === -1
    );

    return cleaned.concat([el]);
  }, []);

  return noDuplicates;
};

const hasDiscount = true;
const discount = 15;

const ProductSection = ({ initProduct, productClose, theme, school }) => {
  const { addProduct } = useContext(CartContext);

  const [product, setProduct] = useState(initProduct);
  const [EMS_fileds, setEMS_fileds] = useState({});

  // const product = initProduct;

  // const [sizes, setSizes] = useState([]);
  // const [sliderImages, setSliderImages] = useState([]);
  const [goto, setGoTo] = useState(null);

  // const [product, setProduct] = useState({ quantity: 1 });

  const sizes = product.attributes.filter(
    (i) => i.attribute.toLowerCase() === "size"
  );

  const certifications = product.attributes.filter(
    (i) => i.attribute.toLowerCase() === "certification"
  );

  const mergedImages = mergeColorImages(product.images, product.colors);
  const sliderImages = mergedImages.reduce((all, i) => {
    return all.concat([`https://bahamajoes.com/${i.image_path}`]);
  }, []);
  const hasCertifications = certifications && certifications.length > 0;

  useEffect(() => {
    let elems = document.querySelectorAll(".dropdown-trigger");

    M.Dropdown.init(elems, {});
  }, []);

  useEffect(() => {
    console.log({ initProduct });
    setProduct({
      ...initProduct,
      colors: initProduct.colors,
      color: initProduct.colors[0],
      // sizes: initProduct.sizes,
      size: initProduct.sizes[0],
      certification:
        "certifications" in initProduct && initProduct.certifications.length > 0
          ? initProduct.certifications[0]
          : [],
      // size: sizes[0],
      quantity: 1,
    });

    setGoTo(0);
    // handleChange({ color: initProduct.colors[0] });
    let elems = document.querySelectorAll(".dropdown-trigger");
    M.Dropdown.init(elems, {});
  }, [initProduct]);

  useEffect(() => {
    if (!product.color) return;

    sliderImages.forEach((el, i) => {
      if (el.includes(product.color.image)) {
        setGoTo(i);
      }
    });
  }, [product.color]);

  const addProductToCart = () => {
    console.log(product);

    const adjustment = product && product.size.price_adjustment;

    const p = hasDiscount
      ? round((product.price + adjustment) * ((100 - 15) / 100), 2)
      : product.price + adjustment;

    const attributes = [
      { ...product.color, name: "Color", value: product.color.label },
      { ...product.size, name: "Size", value: product.size.name },
    ];

    if (hasCertifications) {
      attributes.push({
        ...product.certification,
        name: "Certification",
        value: product.certification.name,
      });
    }

    const _product = {
      school,
      quantity: product.quantity,
      name: product.name,
      id: product.id,
      color: product.color,
      attributes,
      discountprice: product.discountprice,
      displayorder: product.displayorder,
      images: product.images,
      price: p,
      base_price: product.price,
      adjustment: adjustment,
      shortdescription: product.shortdescription,
      size: product.size,
      certification: product.certification,
      sku: product.sku,
      vendor: product.vendor,
    };

    parseInt(_product.quantity) > 0 &&
      addProduct(_product).then(() =>
        M.toast({
          classes: "green white-text",
          html: `${_product.name} added to the cart!`,
        })
      );
  };

  const handleChange = (value) => {
    setProduct((p) => ({ ...p, ...value }));
  };

  const handleSliderChange = (o, s, e) => {
    console.log(s, o, e);
    if (s === -1) return;
    while (s >= sliderImages.length) {
      s -= sliderImages.length;
    }

    setGoTo(s);
    const image = sliderImages[s].split("/");

    const color = initProduct.colors.filter((el) =>
      el.image.includes(image[image.length - 1])
    )[0];
    if (color) handleChange({ color });
  };

  const EMS_EMAIL = sessionStorage.getItem("EMS_EMAIL");
  const rr = JSON.parse(sessionStorage.getItem("EMS"));

  const params = useParams();

  useEffect(() => {
    if (sessionStorage.getItem("EMS_EMAIL")) {
      const domain = "https://bahamajoes.com/api";
      const localhost = "http://localhost:3360";
      Axios.get(`${domain}/users/ems/${EMS_EMAIL}`)
        .then((res) => {
          setEMS_fileds(res.data.results[0]);
          // console.log("RES from hereeeeee", res.data.results[0]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Schools : Bahama Joe&apos;s</title>
      </Helmet>
      <a onClick={productClose} className="closeBtn btn">
        Close{" "}
        <i
          className="fas fa-times"
          style={{ top: "2px", position: "relative" }}
        ></i>
      </a>
      <div className="container row displayAdj">
        <div className="col s12 m5 l5">
          {sliderImages.length === 1 ? (
            <SingleSlider
              images={sliderImages}
              configa={settingFor}
              configb={settingNav}
            />
          ) : (
            <MultipleSlider
              images={sliderImages}
              configa={settingFor}
              configb={settingNav}
              {...{ goto, handleSliderChange, setGoTo }}
            />
          )}
        </div>
        <div className="col s12 m7 l7 productInfoAdj">
          <div className="hero-text" style={{ paddingTop: "20px" }}>
            <h2
              className=""
              style={{
                margin: "0 0px 40px",
              }}
            >
              {product.name}
            </h2>
            <div
              dangerouslySetInnerHTML={{
                __html: product.description,
              }}
            />

            <p style={{ fontSize: "20px", fontWeight: "600" }}>
              {hasDiscount && (
                <>
                  {/* <span style={{ paddingRight: '20px', textDecoration: 'line-through' }}>
                              <CurrencyFormat {...currencyAttr} value={round(product.price, 2)} />
                           </span> */}
                  <del
                    style={{
                      paddingRight: "10px",
                      color: "grey",
                    }}
                  >
                    <CurrencyFormat
                      {...currencyAttr}
                      value={round(product.price, 2)}
                    />
                  </del>
                  <span style={{ color: "green" }}>
                    <CurrencyFormat
                      {...currencyAttr}
                      value={round(product.price * ((100 - discount) / 100), 2)}
                    />
                  </span>
                </>
              )}
              {!hasDiscount && (
                <CurrencyFormat
                  {...currencyAttr}
                  value={round(product.price, 2)}
                />
              )}
            </p>

            <div className="col s12 m4 l4">
              <a
                className="dropdown-trigger selectStyle"
                href="#"
                data-target="dropdown1"
              >
                Color:{" "}
                {product &&
                  product.color &&
                  product.color.label.length > 0 &&
                  product.color.label}
              </a>
              {product.colors && (
                <ul id="dropdown1" className="dropdown-content">
                  {product.colors.map((c, x) => (
                    <li
                      // onClick={() => setProduct({ ...product, color: c })}
                      onClick={(e) => handleChange({ color: c })}
                      key={x}
                    >
                      {c.label}
                      <span
                        style={{
                          backgroundColor: c.color,
                        }}
                        className="whiteBoxColor"
                      ></span>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="col s12 m5 l5">
              <a
                className="dropdown-trigger selectStyle"
                href="#"
                data-target="dropdown20"
              >
                Size:{" "}
                {/* {product.size &&
									product.size.name.toString().length > 0 &&
									product.size.name.toString()} */}
                {product &&
                  product.size &&
                  product.size.name.length > 0 &&
                  product.size.name}
              </a>
              {sizes && (
                <ul id="dropdown20" className="dropdown-content">
                  {sizes.map((s, z) => (
                    <li
                      onClick={() =>
                        setProduct({
                          ...product,
                          size: s,
                        })
                      }
                      key={z}
                    >
                      {s.name}
                      {s.price_adjustment > 0 && (
                        <span className="extraMoneyBox">
                          <CurrencyFormat
                            {...currencyAttr}
                            prefix="+ $"
                            value={round(s.price_adjustment, 2)}
                          />
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            {hasCertifications && (
              <div className="col s12 m5 l5">
                <a
                  className="dropdown-trigger selectStyle"
                  href="#"
                  data-target="dropdown30"
                >
                  Certification:{" "}
                  {product &&
                    product.certification &&
                    "name" in product.certification &&
                    product.certification.name.toString()}
                </a>

                <ul id="dropdown30" className="dropdown-content">
                  {certifications.map((s, z) => (
                    <li
                      onClick={() =>
                        setProduct({
                          ...product,
                          certification: s,
                        })
                      }
                      key={z}
                    >
                      {s.name}
                      {s.price_adjustment > 0 && (
                        <span className="extraMoneyBox">
                          <CurrencyFormat
                            {...currencyAttr}
                            prefix="+ $"
                            value={round(s.price_adjustment, 2)}
                          />
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <div className="col s12 m3 l3">
              <a
                className="dropdown-trigger selectStyle"
                href="#"
                data-target="dropdown3"
              >
                Quantity: {product.quantity > 0 && product.quantity}
              </a>
              <ul id="dropdown3" className="dropdown-content">
                {[1, 2, 3, 4, 5, 6, 7 ,8 ,9, 10].map((c, i) => (
                  <li
                    key={i}
                    onClick={() =>
                      setProduct({
                        ...product,
                        quantity: c,
                      })
                    }
                  >
                    {c}
                  </li>
                ))}
              </ul>
            </div>

            {(rr && rr.school == "chatham-county-ems") ||
            (rr && rr.school == "Chatham-Fire-Department") ? (
              <>
                <div className="col s12 m3 l6">
                  <a className="selectStyle">
                    Classification: {EMS_fileds && EMS_fileds.class}
                  </a>
                </div>
                <div className="col s12 m3 l6">
                  <a
                    className="dropdown-trigger selectStyle"
                    href="#"
                    data-target="dropdown4"
                  >
                    Certifications: {product && product.certifications}
                  </a>
                  <ul id="dropdown4" className="dropdown-content">
                    {["None", "EMT", "EMT-I", "PARAMEDIC"].map((c, i) => (
                      <li
                        key={i}
                        onClick={() =>
                          setProduct({
                            ...product,
                            certifications: c,
                          })
                        }
                      >
                        {c}
                      </li>
                    ))}
                  </ul>
                </div>

                {/* <div className="col s13 m3 l6" style={{ marginRight: "2px" }}>
                  <a className="selectStyle">
                    Credit Available: {EMS_fileds && ( EMS_fileds.discount_amount >= 1000? 'Unlimited' : EMS_fileds.discount_amount) }
                  </a>
                </div> */}
              </>
            ) : (
              <p></p>
            )}

            <div className="clearfix"></div>
            <div className="row">
              <div className="col s12 center-align">
                <button
                  onClick={addProductToCart}
                  className="btn"
                  style={theme.button}
                >
                  ADD TO CART
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const School = ({ school }) => {
  const { theme, products } = school;

  const { user, logout, token, ...rest } = useContext(AuthContext);

  const [open, setOpen] = useState(false);
  const [product, setProduct] = useState(null);

  const productOpen = (prod) => {
    console.log("set", { prod });
    setProduct(prod);
    open
      ? scrollTop()
      : setTimeout(() => {
          scrollTop();
        }, 150);

    setOpen(true);
  };
  const productClose = () => {
    setProduct(null);
  };

  const scrollTop = () =>
    window.scrollTo({
      top: 150,
      left: 0,
      behavior: "smooth",
    });

  const categories = products.reduce((tot, x) => {
    return tot.indexOf(x.cname) < 0 ? tot.concat([x.cname]) : tot;
  }, []);

  useEffect(() => {
    setProduct(null);
  }, [school]);

  const { cartItems } = useContext(CartContext);

  return (
    <>
      {/* <div className="pgTitle">
            <div className="row container center" style={{ ...theme }}>
               <div style={{ padding: '20px 0' }}></div>
            </div>
         </div> */}
      {/* <div className="parallax-container">
            <div className="parallax">
               <img src="https://cdn.inksoft.com/images/publishers/11094/stores/blessed_sacrament_school/img/customContent/School_Welcome_Inksoft_Banners_(5).png" />
            </div>
         </div> */}
      {/* {school.hero.length > 0 && <Parallax noShade source={`` + school.hero} />} */}

      {school.hero.length > 0 && (
        <div className="row container center" style={{ ...theme }}>
          <img
            src={`` + school.hero}
            style={{
              maxWidth: "100%",
              maxHeight: "400px",
              width: "auto",
              verticalAlign: "bottom",
            }}
          />
        </div>
      )}

      <AdminNav />

      <div
        className={product ? "openProduct" : "closeProduct"}
        style={{
          backgroundColor: "#fff",
          position: "relative",
          zIndex: "1",
          borderBottom: "2px solid #606060a1",
          boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.51)",
        }}
      >
        {product && (
          <ProductSection
            school={school.name}
            theme={theme}
            initProduct={product}
            productClose={productClose}
          />
        )}
      </div>
      <div style={{ backgroundColor: "#fff" }}>
        <br />
        <br />
        <div className="row container valign-wrapper">
          <div className="col s12">
            <p className="center-align">
              <Links id={school.id} />
              <span className="ssIcon">
                {school.facebook && school.facebook.length > 0 && (
                  <a
                    className="fbIcon"
                    style={{
                      color: "black",
                      height: "33px",
                      width: "33px",
                      fontSize: "21px",
                      padding: "5px",
                    }}
                    target="_blank"
                    href={school.facebook}
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                )}
                {school.instagram && school.instagram.length > 0 && (
                  <a
                    className="igIcon"
                    style={{
                      color: "black",
                      height: "33px",
                      width: "33px",
                      fontSize: "22px",
                      padding: "5px",
                    }}
                    target="_blank"
                    href={school.instagram}
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                )}
                {school.twitter && school.twitter.length > 0 && (
                  <a
                    className="twIcon"
                    style={{
                      color: "black",
                      height: "33px",
                      width: "33px",
                      fontSize: "21px",
                      padding: "5px",
                    }}
                    target="_blank"
                    href={school.twitter}
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                )}
              </span>
            </p>
          </div>
        </div>
        <br />
        <br />
        {categories.map((cat, i) => (
          <div key={i} className="row container">
            <div className="col s12">
              <h2 className="center-align">{cat}</h2>
              <div className="grid">
                {products
                  .filter((x) => x.cname === cat)
                  .map((p) => (
                    <ProductItem
                      click={productOpen}
                      p={p}
                      key={p.id}
                      discount={hasDiscount ? discount : null}
                    />
                  ))}
              </div>
            </div>
          </div>
        ))}
        <br />
        <br />
        <br />
      </div>
    </>
  );
};

const Page_Loader = () => {
  const [[partner, loaded], setPartner] = useState([null, false]);

  let params = useParams();

  const EMS = JSON.parse(sessionStorage.getItem("EMS"));

  const loadPartner = () => {
    Axios({
      url: `${URL}partners.cfc?method=getPartnerWProducts&url=${
        params.school == undefined ? EMS.school : params.school
      }`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.status === 200 && res.data.id && res.data)
      .then((d) => setPartner([d, true]))
      .catch((e) => console.log({ e }));
  };

  useEffect(() => {
    params && loadPartner();
  }, [params]);

  return (
    <>
      {partner ? (
        <div className="store">
          <School school={partner} />
        </div>
      ) : (
        <div
          className="progress-container container center-align valign-wrapper store"
          style={{ padding: "40px 0" }}
        >
          <div
            className="preloader-wrapper big active"
            style={{ margin: "0 auto" }}
          >
            <div className="spinner-layer spinner-blue-only">
              <div className="circle-clipper left">
                <div className="circle"></div>
              </div>
              <div className="gap-patch">
                <div className="circle"></div>
              </div>
              <div className="circle-clipper right">
                <div className="circle"></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const AdminNav = () => {
  const { user, token, logout } = useContext(AuthContext);
  const { cartItems } = useContext(CartContext);
  const { pathname } = useLocation();

  const removeAuthLinks =
    (cartItems.length > 0 &&
      cartItems.filter((el) => {
        if (el.school.toLowerCase().indexOf("optim") > -1) return true;
        if (el.school.toLowerCase().indexOf("titlemax") > -1) return true;
        return false;
      }).length === cartItems.length) ||
    pathname.toLowerCase().indexOf("optim") > -1 ||
    pathname.toLowerCase().indexOf("titlemax") > -1;

  return (
    <div
      className="container row"
      style={{
        backgroundColor: "#3a3a3a",
      }}
    >
      <div className="col s12" style={{ padding: "0" }}>
        <div>
          {/* <ul
            className="topNav white-text hide-on-med-and-down"
            style={{ float: "left", margin: "17px 0" }}
         >
            <li>School Uniform Store: 4755 Waters Avenue</li>
            <li>912.355.6960</li>
            <li>
               <Link to="/">
                  Hours: Monday - Thursday 9 - 5:30 | Friday 9 - 5:00 | Saturday
                  9 - 1:00
               </Link>
            </li>
         </ul> */}
          <ul
            className="topNav"
            style={{
              margin: "12px 0",
              width: "100%",
              textAlign: "center",
            }}
          >
            {!removeAuthLinks && (
              <>
                {user && user.id && user.id > 0 && token && token.length > 0 ? (
                  <>
                    <li style={{ border: "0" }}>
                      <Link
                        to="/account"
                        className="valign-wrapper"
                        style={{ position: "relative" }}
                      >
                        <i
                          className="material-icons"
                          style={{
                            marginRight: "10px",
                          }}
                        >
                          account_circle
                        </i>
                        My Account
                      </Link>
                    </li>
                    {user && (
                      <li
                        style={{
                          border: "none",
                          float: "right",
                        }}
                      >
                        <span
                          className="btn"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={logout}
                        >
                          Log Out
                        </span>
                      </li>
                    )}
                  </>
                ) : (
                  <>
                    <li style={{ border: "0" }}>
                      <Link
                        to="/Login"
                        style={{
                          border: "0px solid white",
                          padding: "5px 7px",
                        }}
                      >
                        Sign In
                      </Link>
                    </li>
                    <li style={{ border: "0" }}>
                      <Link to="/Register">Register</Link>
                    </li>
                  </>
                )}
              </>
            )}
            <li>
              <Link
                to="/cart"
                style={{
                  position: "relative",
                  display: "inline-block",
                }}
              >
                <img src={cartItems.length > 0 ? cartImgHover : cartImg} />
                <span className="cartNumItem">
                  {cartItems.length > 0 &&
                    `${cartItems.reduce((total, i) => total + i.quantity, 0)}`}
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Page_Loader;
