import React, { useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { CartContext } from "../../context/CartContext";
import M from "materialize-css";
import loadAccpetJS from "./scripts/loadAccept";
import creditcards from "./imgs/credit-cards.png";
import { months, years } from "./variables";

// jamesk@thesabresolution.com authorizenet public key
// const PUBLIC_KEY = '7VL48RS9HEQ6WvxefNRzagVzt24P2RJAV5tjB9u7kjxM49u8YmYF6e8ga8QpvVy7';

// jamesk@thesabresolution.com authorizenet loginid
// const API_LOGIN_ID = '8zt6Sj8FLgY5';
// const API_LOGIN_ID = '2285ahNXf';
// const PUBLIC_KEY =
//   '4sNfbB9vF74PFhAU94rf77s4X39GNQaBQ6tX9vLxCWyp5ACCLRZJFEuSp6y25aVH';

const API_LOGIN_ID = "549XPuAsAcr";
const PUBLIC_KEY =
	"8T8wu5Tv3cY7q2UeyBH63jFAFEEnZ957Y2B3f3aB992j2kKzmeG94jfYTBNcadfe";

const PaymentWrapper = ({ onEdit }) => {
	const { billing, payment, setPayment } = useContext(CartContext);
	const { handleSubmit, register, errors } = useForm(
		payment && payment.card && { defaultValues: payment.card }
	);
	const [accept, setAccept] = useState(null);
	const [editing, setEditing] = useState(true);
	const [token, setToken] = useState(null);
	const [card, setCard] = useState(null);
	const [tokenIterator, setTokenIterator] = useState(0);

	useEffect(() => {
		loadAccpetJS(() => setAccept(window.Accept));
		if (editing) onEdit(true);
	}, []);

	const onSubmit = (v) => {
		const authData = {
			clientKey: PUBLIC_KEY,
			apiLoginID: API_LOGIN_ID,
		};

		const cardData = {
			fullName:
				(v && v.name) ||
				`${"" || billing.firstname} ${"" || billing.lastname}`,
			cardNumber: (v && v.number && v.number) || v.number,
			month: ("0" + v.month).slice(-2),
			year: (v.year && v.year).slice(-2),
			cardCode: v.cvc && v.cvc,
			zip: billing && billing.zip ? billing.zip : "",
		};

		const secureData = {
			authData,
			cardData,
		};

		const acceptResponseHandler = (r) => {
			try {
				if (r.messages.resultCode === "Ok") {
					// successful token
					setToken({ token: r.opaqueData });
					setCard(cardData);
					setEditing(false);
					onEdit(false);
				} else {
					r.messages.message.map((e) => {
						M.toast({ html: e.text });
					});
				}
			} catch (e) {
				console.log({ e });
			}
		};

		accept && accept.dispatchData(secureData, acceptResponseHandler);
	};

	useEffect(() => {
		if (token) {
			setPayment(token);
		}
	}, [token]);

	const handleOnEditCard = () => {
		setEditing(true);
		onEdit(true);
	};

	const handleOnCancelEditCard = () => {
		setEditing(false);
		onEdit(false);
	};

	return (
		<>
			<div className="row">
				<div className="col s12">
					<h5 style={{ marginTop: "0" }}>
						<b>Payment details</b>
						<span className="right">
							{!editing && token && card && (
								<a
									href="#"
									onClick={handleOnEditCard}
									className="link"
								>
									Edit card
								</a>
							)}
						</span>
					</h5>
				</div>
			</div>
			{token && card && !editing && (
				<>
					<b>{cardType(card.cardNumber)}</b> :{" "}
					{`* * * * ${card.cardNumber
						.substr(card.cardNumber.length - 4)
						.split("")
						.join(" ")} : `}
					<b>exp</b>
					{` ${card.month}/${card.year.substr(card.year.length - 2)}`}
				</>
			)}
			{editing && (
				<form name="paymentForm" onSubmit={handleSubmit(onSubmit)}>
					<div
						className="row"
						style={{ margin: "0", padding: "0 .75rem" }}
					>
						All major credit cards accepted:
						<br />
						<img src={creditcards} style={{ width: "210px" }} />
					</div>

					<div className="row" style={{ margin: "0" }}>
						<div
							className="input-field col s12"
							style={{ marginBottom: "0" }}
						>
							<input
								id="name"
								type="text"
								ref={register({
									required: "Cardholder name is required",
								})}
								name="name"
							/>
							<label htmlFor="name">Card Holder Name</label>
							<span className="red-text helper-text">
								{errors.name && errors.name.message}
							</span>
						</div>
					</div>
					<div className="row" style={{ margin: "0" }}>
						<div
							className="input-field col s12"
							style={{ marginBottom: "0" }}
						>
							<Input
								maxLength="16"
								type="number"
								register={register({
									required: "Card Number is required",
								})}
								label="Card Number"
								name="number"
								error={errors && errors.number && errors.number}
							/>
						</div>
					</div>
					<div className="row" style={{ margin: "0" }}>
						<div
							className="input-field col s4"
							style={{ marginBottom: "0" }}
						>
							<Select
								register={register({
									required: "Expiration Month is required",
								})}
								label="Expiration Month"
								name="month"
								error={errors && errors.month && errors.month}
								options={months}
							/>
						</div>
						<div
							className="input-field col s4"
							style={{ marginBottom: "0" }}
						>
							<Select
								register={register({
									required: "Expiration Year is required",
								})}
								label="Year"
								name="year"
								error={errors && errors.year && errors.year}
								options={years}
							/>
						</div>
						<div
							className="input-field col s4"
							style={{ marginBottom: "0" }}
						>
							<Input
								register={register({
									required: "Security Code is required",
								})}
								label="CVV"
								name="cvc"
								error={errors && errors.cvc && errors.cvc}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col s12">
							<button type="submit" className="btn">
								Validate
							</button>
							{token && (
								<button
									type="button"
									className="btn btn-small"
									onClick={handleOnCancelEditCard}
								>
									Cancel
								</button>
							)}
						</div>
					</div>
				</form>
			)}
		</>
	);
};

const cardType = (number) => {
	// visa
	var re = new RegExp("^4");
	if (number.match(re) != null) return "Visa";

	// Mastercard
	// Updated for Mastercard 2017 BINs expansion
	if (
		/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
			number
		)
	)
		return "Mastercard";

	// AMEX
	re = new RegExp("^3[47]");
	if (number.match(re) != null) return "AMEX";

	// Discover
	re = new RegExp(
		"^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)"
	);
	if (number.match(re) != null) return "Discover";

	// Diners
	re = new RegExp("^36");
	if (number.match(re) != null) return "Diners";

	// Diners - Carte Blanche
	re = new RegExp("^30[0-5]");
	if (number.match(re) != null) return "Diners - Carte Blanche";

	// JCB
	re = new RegExp("^35(2[89]|[3-8][0-9])");
	if (number.match(re) != null) return "JCB";

	// Visa Electron
	re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
	if (number.match(re) != null) return "Visa Electron";

	return "Card";
};

const Select = React.memo(({ register, name, label, error, options }) => {
	const selected = options.reduce(
		(tot, el) => el.options && el.options.select,
		""
	);

	useEffect(() => {
		M.AutoInit();
	}, [options, selected]);

	return (
		<>
			<select
				ref={register && register}
				id={name}
				name={name}
				defaultValue={selected}
			>
				{options.map((el, i) => (
					<option key={i} value={el.value} {...el.options}>
						{el.label}
					</option>
				))}
			</select>
			<label htmlFor={name}>{label}</label>
			<span className="red-text helper-text">
				{error && error.message}
			</span>
		</>
	);
});

const Input = React.memo(
	({ register, name, label, error, type = "text", ...rest }) => {
		return (
			<>
				<input
					ref={register && register}
					id={name}
					name={name}
					type={type}
					{...rest}
				/>
				<label className="active" htmlFor={name}>
					{label}
				</label>
				<span className="red-text helper-text">
					{error && error.message}
				</span>
			</>
		);
	}
);

export default PaymentWrapper;
