import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Axios from 'axios';
import M from 'materialize-css';
import PgTitle from './locations.png';
// import foLogo from "../../images/frederick-logo-FOOTER.png";
// import cards from "../../images/credit-cards.png";
import { Helmet } from 'react-helmet';

let url = window.location.href;
let arr = url.split('/');
let arr2 = arr[2].split(':');
const URL = arr[0] + '//' + arr2[0] + '/cfc/api/';

const Page = (props) => {
  const location = useLocation();

  const form = {
    name: '',
    email: '',
    phone: '',
    message: '',
  };
  const { register, handleSubmit, errors } = useForm({ defaultValues: form });

  const [message, setMessage] = useState(null);
  const [files, setFiles] = useState([1, 2, 3]);

  const uploadFile = (file) => {
    const data = new FormData();

    data.append('files', file);

    return Axios({
      url: '/cfc/api/upload.cfc?method=upload',
      method: 'post',
      data,
    }).then((r) => r.status === 200 && r.data);
  };

  const sendContactForm = (values, e) => {
    let files_uploaded = [];

    files.forEach((el) => {
      if (values[`file_${el}`].length) {
        files_uploaded[files_uploaded.length] = uploadFile(
          values[`file_${el}`][0]
        );
      }
    });

    Axios.all(files_uploaded).then(
      Axios.spread(function (upload1, upload2, upload3) {
        // Both requests are now complete
        console.log({ upload1 });
        console.log({ upload2 });
        console.log({ upload3 });

        const uploaded = [upload1, upload2, upload3];

        const _values = { ...form, uploaded, ...values };

        console.log({ _values });

        Axios({
          method: 'POST',
          url: `${URL}webmail.cfc?method=email`,
          headers: {
            'Content-Type': 'application/json',
          },
          data: _values,
        })
          .then((res) => res.status === 200 && res.data)
          .then((data) => {
            if (data.success) {
              e.target.reset();
              M.toast({
                html: 'Thanks, we will get back to you soon!',
              });
              setMessage('Thanks, we will get back to you soon!');
            }
          })
          .catch((e) => console.log(e));
      })
    );
  };

  useEffect(() => {
    let timer1 = setTimeout(() => setMessage(''), 8 * 1000);

    // this will clear Timeout
    // when component unmount like in willComponentUnmount
    // and show will not change to true
    return () => {
      clearTimeout(timer1);
    };
  }, [message]);

  return (
    <div>
      <Helmet>
        <title>Contact &amp; Location : Bahama Joe&apos;s</title>
      </Helmet>
      <div id="Contact-Us" style={{ backgroundColor: '#d8d8d8' }}>
        {location.pathname.indexOf('/wj') > -1 && (
          <div className="container">
            <div className="row">
              <div className="s12 center-align">
                <p
                  style={{
                    fontSize: '20px',
                    padding: '20px',
                    margin: 0,
                  }}
                  className="warning red white-text darken-2"
                >
                  <b>
                    We are upgrading our online ordering system. While we
                    perform this update, please call us at{' '}
                    <a className="white-text" href="tel:(912) 233-0060">
                      (912) 233-0060
                    </a>{' '}
                    or send us a note using the Contact form to talk about your
                    Screenprinting and Embroidery needs. This issue will be
                    resolved as soon as possible - thank you for choosing Bahama
                    Joe's!
                  </b>
                </p>
              </div>
            </div>
          </div>
        )}
        <div className="row center container flexAdj">
          <div className="col s12 m6 l6">
            <div className="center-align">
              <br />
              <br />
              <img src={PgTitle} />
            </div>
            <h4 className="bahama-green-text" style={{ margin: '30px 0 0 0' }}>
              Corporate Offices
            </h4>
            <p style={{ marginTop: '0' }}>
              198 W Chatham Blvd. Savannah, GA 31408
              <br />
              <b>Customer Service hours:</b>{' '}
              <div className="hide-on-med-and-up" style={{ lineHeight: '0' }}>
                <br />
              </div>
              Monday to Friday 8 - 5
              <br />
              <b>Phone:</b>{' '}
              <a href="tel:912.233.0060" style={{ color: 'black' }}>
                912.233.0060
              </a>
            </p>
            <p style={{ marginTop: '0' }}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3380.3227036453263!2d-81.15924258483537!3d32.087562981185634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88fb9faa775e7e2f%3A0xe66b11754326714d!2sBahama%20Joe&#39;s!5e0!3m2!1sen!2sus!4v1618850229866!5m2!1sen!2sus"
                width="100%"
                height="150"
                style={{ border: '0' }}
                allowfullscreen=""
                loading="lazy"
              ></iframe>
              {/* <a href="https://g.page/ShopBahamaJoes?share" target="_blank">
								Driving Directions
							</a> */}
            </p>
            <h4 className="bahama-green-text" style={{ margin: '30px 0 0 0' }}>
              School Uniform Store
            </h4>
            <p style={{ marginTop: '0' }}>
              4755 Waters Ave. Savannah, GA 31404
              <br />
              <b>Customer Service hours:</b>{' '}
              <div className="hide-on-med-and-up" style={{ lineHeight: '0' }}>
                <br />
              </div>
              Monday to Thursday 9 - 5:30
              <br />
              Friday 9 - 5 and{' '}
              <div className="hide-on-med-and-up" style={{ lineHeight: '0' }}>
                <br />
              </div>
              Saturday 9 - 1
              <br />
              <b>Phone:</b>{' '}
              <a href="tel:912.355.6960" style={{ color: 'black' }}>
                912.355.6960
              </a>
              <br />
            </p>
            <p style={{ marginTop: '0' }}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13529.68011375383!2d-81.0927223!3d32.0308205!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x79cbf739db0f8903!2sBahama%20Joe&#39;s%20Uniform%20Store!5e0!3m2!1sen!2sus!4v1618850518732!5m2!1sen!2sus"
                width="100%"
                height="150"
                style={{ border: '0' }}
                allowfullscreen=""
                loading="lazy"
              ></iframe>
              {/* <a href="https://goo.gl/maps/k9dQNPZFsyW77thGA" target="_blank">
								Driving Directions
							</a> */}
            </p>
            <p>
              <a href="mailto:weborders@bahamajoes.com">
                weborders@bahamajoes.com
              </a>
            </p>
          </div>
          <div className="col s12 m6 l6">
            <div className="white contactPadAdj">
              <h2
                className="bahama-green-text"
                style={{ margin: '30px 0 0 0' }}
              >
                Send us a Message!
              </h2>
              <br />
              <form onSubmit={handleSubmit(sendContactForm)}>
                <div>
                  <div className="input-field col s12 m12 l12">
                    <input
                      ref={register({
                        required: 'Name is required',
                      })}
                      id="name"
                      name="name"
                      type="text"
                      className="validate"
                    />
                    <label htmlFor="name">Name *</label>
                    {errors && errors.name && (
                      <span className="red-text helper-text">
                        {errors.name.message}
                      </span>
                    )}
                  </div>
                  <div className="input-field col s12 m12 l12">
                    <input
                      ref={register({
                        required: 'Email is required',
                      })}
                      name="email"
                      id="email"
                      type="text"
                      className="validate"
                    />
                    <label htmlFor="email">Email *</label>
                    {errors && errors.email && (
                      <span className="red-text helper-text">
                        {errors.email.message}
                      </span>
                    )}
                  </div>
                  <div className="input-field col s12 m12 l12">
                    <input
                      ref={register}
                      id="phone"
                      name="phone"
                      type="text"
                      className="validate"
                    />
                    <label htmlFor="phone">Phone</label>
                  </div>
                  <div className="input-field col s12">
                    <textarea
                      name="message"
                      ref={register}
                      id="message"
                      type="text"
                      className="materialize-textarea validate"
                      style={{ height: '150px' }}
                    ></textarea>
                    <label htmlFor="message">Message</label>
                  </div>
                  <div className="clearfix"></div>
                  {files.map((i, z) => (
                    <div className="file-field input-field col s12" key={z}>
                      <div className="btn">
                        <span>File {i}</span>
                        <input ref={register} name={`file_${i}`} type="file" />
                      </div>
                      <div className="file-path-wrapper">
                        <input
                          ref={register}
                          name="filew"
                          className="file-path validate"
                          type="text"
                          placeholder="Upload image file"
                        />
                      </div>
                    </div>
                  ))}
                  <div className="clearfix"></div>
                  <br />
                  <div className="col s12 m12 l12 center">
                    <button
                      type="submit"
                      name="submit"
                      className="btn amber grey-text text-darken-3"
                    >
                      SEND
                    </button>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </form>
              <br />
              {/* <p>
								This site is protected by reCAPTCHA and the Google Privacy
								Policy and Terms of Service apply.
							</p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Page;
