import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import btmImage from './bottom-image.png';
import custom from './CUSTOM.png';
import ready from './READY.png';

import { categories } from './mock';
import { Helmet } from 'react-helmet';

const Holder = () => {
  const [category, setCategory] = useState('');

  useEffect(() => {
    setCategory('');
  }, []);

  return (
    <>
      <Helmet>
        <title>Screen Printing : Bahama Joe&apos;s</title>
      </Helmet>
      <br />
      <br />
      <div className="container">
        <div className="center-align">
          <img src={custom} className="responsive-img" />
        </div>

        <div className="row">
          <div className="col s12 m12 l8 offset-l2 center-align">
            <h2>MAKE A GREAT FIRST IMPRESSION...</h2>
            <p style={{ margin: '20px 0' }}>
              with custom screen printed apparel from Bahama Joe&apos;s. We
              offer thousands of products, from caps to tees, that can be custom
              screen printed, as well as a full art department to help you get
              started.
            </p>
          </div>
        </div>
        <br />
        <div className="row">
          {!category &&
            categories.map((category, i) => (
              <CategoryLink category={category} onClick={setCategory} />
            ))}
          {category &&
            categories
              .filter((x) => x.name === category)[0]
              .subcategories.map((category, i) => (
                <ProductsLink
                  category={category}
                  clabels={['mens', 'ladies', 'youth', 'boys', 'girls']}
                />
              ))}
        </div>
      </div>
      <div
        className="white-text center-align"
        style={{ backgroundColor: 'rgb(54, 54, 54)' }}
      >
        <div className="container">
          <div className="row">
            <div className="col s12 m12 l8 offset-l2">
              <br />
              <h4 style={{ marginBottom: '0' }}>
                Bahama Joe's offeres thousands of blank products.
              </h4>
              <p style={{ marginTop: '0' }}>
                Select the perfect items to apply your custom design or business
                logo. All our apparel can be customized to fit your vision. We
                specialize in custom screen printing and custom embroidery and
                offer fast turn around times. Select from t-shirts, outerwear,
                hats, pants, athletic apparel, uniforms and more! We have
                customizable apparel available for all occasions and budgets.
                All of our custom apparel is made to order and produced in
                Savannah, Georgia.
              </p>
              <div className="center-align">
                <a
                  href="https://shop.bahamajoes.com/category/26/Everything-Else.html"
                  className="btn amber grey-text text-darken-3"
                >
                  See all products
                </a>
              </div>
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
      <ReadyToOrder />
      <div className="white-text center-align bahama-yellow grey-text text-darken-3">
        <div className="container">
          <div className="row" style={{ marginBottom: '0' }}>
            <div className="col s12 m12 l8 offset-l2">
              <br />
              <h4 style={{ marginBottom: '0' }}>
                Your order is ready, on-time and without surprises. Because we
                are Savannah's custom screen printing experts
              </h4>
              <p style={{ marginTop: '0' }}>
                Bahama Joe's has produced more than 3.5 million custom screen
                printed t-shirt orders just in the past few years - making
                Bahama Joe's the leading custom t-shirt provider in the Savannah
                area. We maintain a business model that specializes in personal
                customer service, custom t-shirts and dependable production
                times. Our custom screen printing facility offers over 10,000 sq
                ft of custom screenprinting work space, with best in class
                equipment.
              </p>
              <br />
            </div>
          </div>
        </div>
      </div>
      <img src={btmImage} style={{ width: '100%' }} />
    </>
  );
};

export const ReadyToOrder = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col s12 m12 l8 offset-l2 center">
            <br />
            <img src={ready} />
            <h4 className="bahama-green-text">
              It's as easy as{' '}
              <span className="hide-on-med-and-up">
                <br />
              </span>
              one, two, three.
            </h4>

            <h5>
              <span className="bahama-green-text">ONE:</span>{' '}
              <span className="hide-on-med-and-up">
                <br />
              </span>
              Choose your product
            </h5>

            <p style={{ marginTop: '0' }}>
              Bahama Joe's offeres{' '}
              <a href="https://shop.bahamajoes.com/category/26/Everything-Else.html">
                <span className="bahama-pink-text">thousands of products</span>
              </a>{' '}
              that can be custom screen printed. Select product(s) that will
              best showcase your business, brand , or event. Shop online or ask
              a{' '}
              <Link to="/Contact-us">
                <span className="bahama-pink-text">
                  customer service expert
                </span>
              </Link>{' '}
              to help your.
            </p>

            <h5>
              <span className="bahama-green-text">TWO:</span>{' '}
              <span className="hide-on-med-and-up">
                <br />
              </span>
              Create your design and choose placement
            </h5>

            <p style={{ marginTop: '0' }}>
              Bahama Joe's offeres a full art department to help you with your
              custom screen printing design. Our experts help with art
              placement, sizing, image quality or create custom screen printing
              designs from scratch. Or you can create your own design in our{' '}
              <Link to="/Design">
                <span className="bahama-pink-text">DESIGN STUDIO</span>
              </Link>
              . Then select the best location to showcase your brand, logo or
              design.
            </p>

            <h5>
              <span className="bahama-green-text">THREE:</span>{' '}
              <span className="hide-on-med-and-up">
                <br />
              </span>
              Proof your design
            </h5>

            <p style={{ marginTop: '0' }}>
              Bahama joe's will show you a digital proof of your custom screen
              print design before it is printed on the final products. This is
              the time to double check and finalize colors, design locataions,
              and image quality.
            </p>
            <br />
          </div>
        </div>
      </div>
    </>
  );
};

const CategoryLink = ({ category, onClick }) => {
  return (
    <>
      <div
        className="col s6 m4 l3 center-align"
        style={{ marginBottom: '20px' }}
      >
        <div className="center-align ssquare" style={{ position: 'relative' }}>
          <div style={{}}>
            <a href={category.link}>
              <img
                src={category.image}
                className="responsive-img productImgAdj"
                alt=""
              />
            </a>
            <div
              className="valign-wrapper center-align"
              style={{ height: '60px' }}
            >
              <h5 style={{ width: '100%' }} className="center-align">
                {category.name}
              </h5>
            </div>
          </div>
        </div>
        <a
          className="btn btn-small amber grey-text text-darken-3"
          href={category.link}
        >
          LET'S GO
        </a>
      </div>
    </>
  );
};

const ProductsLink = ({ category, clabels }) => {
  const defaultActive = clabels.reduce(
    (total, x) => (total === '' && x in category ? x : total),
    ''
  );
  const [active, setActive] = useState(defaultActive);

  if (!category[active]) {
    return <></>;
  }

  return (
    <>
      <div className="col s12 m3 center-align">
        <img
          src={category[active].image}
          style={{ maxHeight: '250px' }}
          alt=""
        />
        <h4>{category.name}</h4>
        <div className="row">
          <div className="col s12 center-align">
            {clabels.map((c) => (
              <>
                {c in category && (
                  <a
                    href={category[c].link}
                    onMouseEnter={() => setActive(c)}
                    onMouseLeave={() => setActive(defaultActive)}
                    className="btn btn-small amber grey-text text-darken-3"
                  >
                    {c}
                  </a>
                )}
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Holder;
