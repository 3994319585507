export const sumItems = ({ cartItems = [], shipping, delivery }) => {
  let state = shipping && shipping.state ? shipping.state : null;

  let itemCount = parseFloat(
    cartItems.reduce((total, product) => total + product.quantity, 0)
  );
  let total = parseFloat(
    cartItems
      .reduce((total, product) => total + product.price * product.quantity, 0)
      .toFixed(2)
  );
  let shippingCost =
    delivery === 'pickup'
      ? 0
      : calcShipping(total, cartItems.length > 0 && cartItems[0].purl);

  const forceTax =
    cartItems.filter((e) =>
      e.school
        ? e.school.toLowerCase().indexOf('jedson') > -1 ||
          e.school.toLowerCase().indexOf('titlemax') > -1
        : false
    ).length > 0;

  // let tax =
  //   (forceTax ||
  //   delivery == 'pickup' ||
  //   (state && ( state.toLowerCase() == 'ga' ||  state.toLowerCase() == "georgia")))
  //     ? total * 0.07
  //     : 0;
// 11/22/2022 according to call with Stephen, we're setting up tax to be always calculated
      let tax = total * 0.07;
      
  let grandTotal = total + shippingCost + tax;

  let cost = {
    shipping: shippingCost,
    total,
    tax,
    grandTotal,
  };

  return { itemCount, cost };
};

const calcShipping = (total, url = '') => {
  if (url.toString().toLowerCase().indexOf('optim') > -1)
    return total > 0 && total < 150 ? 15 : 0;

  if (url.toString().toLowerCase().indexOf('titlemax') > -1)
    return total > 0 && total < 150 ? 15 : 0;

  return total > 0 && total < 100 ? 10 : 0;
};

export const createReducer = () => CartReducer;

export const CartReducer = (state, action) => {
  switch (action.type) {
    case 'SETBILLING':
      return { ...state, billing: action.payload };
    case 'SETSHIPPING':
      return {
        ...state,
        shipping: action.payload,
        ...sumItems({ ...state, shipping: { ...action.payload } }),
      };
    case 'SETDELIVERY':
      return {
        ...state,
        delivery: action.payload,
        ...sumItems({ ...state, delivery: action.payload }),
      };
    case 'SETPICKUP':
      return { ...state, pickup: action.payload, ...sumItems({ ...state }) };
    case 'SETPAYMENT':
      return { ...state, payment: action.payload };
    case 'ADD_ITEM':
      state.cartItems.push(action.payload);

      return {
        ...state,
        cartItems: [...state.cartItems],
        ...sumItems({ ...state, cartItems: [...state.cartItems] }),
      };
    case 'REMOVE_ITEM':
      state.cartItems.splice(action.payload, 1);
      return {
        ...state,
        cartItems: [...state.cartItems],
        ...sumItems({ ...state }),
      };
    case 'INCREASE':
      state.cartItems[action.payload].quantity++;
      return {
        ...state,
        cartItems: [...state.cartItems],
        ...sumItems({ ...state }),
      };
    case 'DECREASE':
      state.cartItems[action.payload].quantity > 1 &&
        state.cartItems[action.payload].quantity--;
      return {
        ...state,
        cartItems: [...state.cartItems],
        ...sumItems({ ...state }),
      };
    case 'CHECKOUT':
      return {
        cartItems: [],
        checkout: true,
        ...sumItems({ ...state, cartItems: [] }),
      };
    case 'CLEAR':
      return {
        cartItems: [],
        payment: {},
        ...sumItems({ ...state, cartItems: [] }),
      };
    default:
      return state;
  }
};
