import React from 'react';
import { Link } from 'react-router-dom';
import PgTitle from './privacy.png';
import pdf from './EEO_Supplement.pdf';
import { Helmet } from 'react-helmet';

const Page = () => {
  return (
    <div>
      <Helmet>
        <title>EULA : Bahama Joe&apos;s</title>
      </Helmet>
      <div className="container">
        <div className="center-align">
          <br />
          <br />
          <h1>END USER LICENCE AGREEMENT</h1>
          {/* <img src={PgTitle} alt="" /> */}
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col s12 m12 l8 offset-l2">
            <p>
              Please read this End-User License Agreement ("Agreement")
              carefully before clicking the "I Agree" button, downloading or
              using <a href="https://bahamajoes.com">https://bahamajoes.com</a>{' '}
              ("Application").
            </p>

            <p>
              Bahama Joes, LLC (“Bahama Joes,” “we” or “us”) respects the
              privacy of its mobile application users and the visitors to its
              website, the home page of which is located at www.bahamajoes.com
              (collectively the “Site”). This Privacy Policy is intended to
              inform you of our policies and practices regarding the gathering,
              use and disclosure of Personal Information you submit through our
              Site. This Policy is hereby incorporated in our Terms of Use, and
              by registering for any of our services or by downloading,
              installing or using Bahama Joes mobile applications and services
              you accept the terms of this Privacy Policy. Personal information
              (“Personal Information”) is information (other than business)
              about you that is personally identifiable to you, like your name,
              address, or personal phone number, as well as other non-public
              information that is associated with the foregoing.
            </p>
            <h5>Consent</h5>
            <p>
              By using the Site, you signify you understand we have a Privacy
              Policy and you expressly consent to the processing of your
              Personal Information according to this Privacy Policy. Your
              Personal Information may be processed by Bahama Joes in the
              country where it was collected as well as other countries
              (including the United States) where laws regarding processing of
              Personal Information may be less stringent than the laws in your
              country.
            </p>
            <h5>Your Information</h5>
            <p>
              The Bahama Joes services (“Services”) are password-protected. To
              use the Services, you must sign up and provide us with contact
              information. If you are a registered user, we track your first
              name, last name, User ID, password, address, phone number, email
              address and relevant associated company information. We may, in
              the future, include other requests for information from you to
              help us to improve the services offered through our Bahama Joes
              Site. We reserve the right to add pertinent information to our
              tracking capabilities.
            </p>

            <p>Automatically Gathered Information</p>

            <p>
              Bahama Joes may collect browser type and browser software version
              to tailor the presentation of the Site and the services to your
              platform and to maintain a record of your activity on the Site. As
              an automatic process, we may record a log IP addresses that access
              the Site. We may also collect IP information. We can and will use
              IP addresses to identify a user when, in our sole discretion, we
              determine that it is necessary to enforce compliance with our
              Terms of Use or to protect our Service, Site, and/or registered
              users.
            </p>
            <h5>Cookies</h5>
            <p>
              Cookies are tiny data files that are stored by your browser on
              your computer hard drive. We use cookies, to track user trends and
              patterns and to otherwise improve the overall user experience on
              the Site. You always have the option of disabling cookies via your
              browser preferences. If you disable cookies on your browser,
              please note that some parts of our Site may not function exactly
              the same (e.g. as effectively or as quickly).
            </p>
            <h5>How We Use/Disclose Information</h5>
            <p>
              Bahama Joes uses information for its own internal purposes and to
              provide value added customer services, including contacting you
              via email to inform you about updates to our services and
              providing you with information about your use of the Site, as well
              as to support the features and functions of the application. While
              you agree to share your profile and information for the purposes
              of supporting the capabilities of the Bahama Joes platform, we
              will not share your individual non-business, personal contact
              information, within the Site and we will not rent or sell your
              Personal Information to anyone without your consent. Bahama Joes
              may use your Personal Information for purposes of supporting
              customer use of the Site. We may provide you with updates,
              announcements, newsletters, business contacts, etc. via email or
              through the application. You may opt out of receiving email
              information at any time.
            </p>
            <h5>
              We may share your Personal Information in the following
              circumstances:
            </h5>
            <ul className="discStyle">
              <li>If you have directed us to do so.</li>
              <li>
                If we are transmitting messages on your behalf to customers or
                other third parties specified by you or we have engaged to
                facilitate the Service and/or
              </li>
              <li>
                If we are required by law or in response to a court order.
              </li>
            </ul>
            <p>
              Bahama Joes cooperates with law enforcement inquiries, as well as
              other third parties to enforce laws, such as: intellectual
              property rights, fraud, theft, harassment and other rights. We can
              (and you authorize us to) disclose any information about you if we
              believe in good faith that such disclosure is necessary (a) to
              comply with relevant laws or to respond to subpoenas or warrants
              served on us: (b) to protect or defend the rights or property of
              Bahama Joes or third parties: or (c) to assist in an
              investigation. Bahama Joes will notify you in writing, within the
              limit of the law, when any such disclosure is made.
            </p>

            <p>
              Although we presently are singularly owned and operated, we may in
              the future have subsidiaries, joint ventures, or other companies
              under a common control (collectively, “Affiliates”). We may share
              some or all of your Personal Information with these Affiliates, in
              which case we will require our Affiliates to honor this Privacy
              Policy. If our company or our assets related to the Site are
              acquired by another company, that company will possess the
              Personal Information collected by us.
            </p>

            <p>
              On a regular basis, we may opt to disclose usage information in
              aggregate form to third parties, including manufacturers, for a
              number of business purposes, including marketing and market
              research.
            </p>

            <p>
              We do not intentionally gather Personal Information about visitors
              who are under the age of 16.
            </p>
            <h5>Links</h5>
            <p>
              Bahama Joes is not responsible for the privacy practices or the
              content of any other Web sites to which the Site links or to that
              link to the Site. In the course of using the Services as a
              registered Site user, you may provide other information, such as
              messages, contacts, uploaded documents, and submittals, to us
              (“Customer Data”). Our Terms & Conditions Agreement and this
              Privacy Statement set forth our obligations regarding Customer
              Data. Through the use of our Services, you (or Bahama Joes, at
              your direction) may deliver Customer Data to one or more third
              parties, and we are not responsible for the use or disclosure of
              any such information by any third party. To the extent we use
              third parties to facilitate our Services or disclose information
              to third parties, such third parties adhere to their own privacy
              customs and policies, and Bahama Joes does not have any
              responsibility for their practices.
            </p>
            <h5>Security</h5>
            <p>
              We will use commercially reasonable methods to maintain the
              confidentiality of your personal information and to protect the
              loss, misuse and alteration of the information under our control.
              These measures include physical security procedures in place at
              our premises, and login usernames and passwords. With current,
              available technology, we cannot guarantee the security of your
              data as it travels over the Internet.
            </p>
            <h5>Trustwave</h5>
            <p>
              <script
                type="text/javascript"
                src="https://sealserver.trustwave.com/seal.js?code=7ae1e9c37e5e482ea86a397f2ab7eb49"
              ></script>
            </p>
            <p>
              Trusted Commerce is an online indication graphic or "seal of
              approval" that you, or your company, can place on your website and
              e-commerce shopping cart to indicate that you are taking steps to
              secure credit card information.
            </p>
            <p>
              The Trusted Commerce seal is powered by Trustwave's server. It
              provides an updated image each time the seal code is deployed
              during a browser session. If a user clicks on the Trusted Commerce
              seal, a secure certificate explaining the seal displays, verifying
              the authenticity and currency of the Trusted Commerce seal.
            </p>
            <h5>Corrections/Updates</h5>
            <p>
              In the event you wish to correct or change your personal
              information you provided to us, you may do so by logging into your
              account, or by contacting us at{' '}
              <a href="mailto:weborders@bahamajoes.com">
                weborders@bahamajoes.com
              </a>
            </p>
            <h5>Passwords</h5>
            <p>
              Your password is your private entry key into the Services. You
              should never discuss your password with anyone and you should
              change it periodically. When you have finished accessing the
              Services, do not forget to log off. This prevents anyone else from
              accessing your information if you leave your computer or mobile
              device unattended.
            </p>
            <h5>Changes to Policy</h5>
            <p>
              We may update this Privacy Policy from time to time as our
              services change and expand. If we make substantial changes to this
              Privacy Policy, we will post the updated policy on this page and
              we will post notice that this Privacy Policy has changed on the
              home page of the Site and in this policy. Once any changes that we
              make to this Privacy Policy become effective, your continued use
              of the Site after such time will signify your acceptance of the
              new terms.
            </p>
            <h5>Contact Info</h5>
            <p>
              If you have any questions or concerns about this Privacy Policy,
              please contact us at{' '}
              <a href="mailto:weborders@bahamajoes.com">
                weborders@bahamajoes.com
              </a>
            </p>
          </div>
        </div>
      </div>

      <br />
      <br />
    </div>
  );
};

export default Page;
