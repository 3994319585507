import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../context/auth-provider";
import { Helmet } from "react-helmet";

export default ({ setState }) => {
	// const { handleSubmit, register, setError, errors } = useForm();
	const { handleSubmit, register, errors } = useForm();
	const [postErrors, setPostErrors] = useState("");
	// const [password, setPassword] = useState("");
	const navigate = useNavigate();
	const { login } = useContext(AuthContext);

	const onSubmit = (values) => {
		const { un, pw } = values;
		// const { from } = this.auth.location.state || {
		// 	from: { pathname: '/authenticated' },
		// };

		login({ username: un, password: pw }).then((response) => {
			if (!response) {
				setPostErrors("Invalid Credentials");
				return;
			}
			navigate("/account");
		});
	};

	return (
		<form name="login-form" onSubmit={handleSubmit(onSubmit)}>
			<Helmet>
				<title>Login : Bahama Joe&apos;s</title>
			</Helmet>
			<div className="form-holder">
				<div className="center-align helper-text red-text">
					{postErrors && postErrors}
				</div>
				<div className="input-field col s6">
					<input
						type="text"
						ref={register({
							required: "Username is required!",
							// pattern: {
							//    value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
							//    message: 'Invalid Email Address',
							// },
						})}
						className="input"
						name="un"
						id="un"
						placeholder="email@domain.com"
					/>
					{/* <label className="active" htmlFor="username">
												Username
											</label> */}
					<span className="helper-text red-text">
						{errors.un && errors.un.message}
					</span>
				</div>
				<div className="input-field col s6">
					<input
						type="password"
						ref={register({ required: "Password is required!" })}
						className="input"
						name="pw"
						id="pw"
						placeholder="Password"
					/>
					{/* <label className="active" htmlFor="confirmPassword">
												Password
											</label> */}
					<span className="helper-text red-text">
						{errors.pw && errors.pw.message}
					</span>
				</div>
			</div>
			<button className="btn submit-btn">Login</button>
			<p className="center-align">
				<a href="#!" onClick={() => setState("reset")}>
					<small>Forgot Your Password?</small>
				</a>
			</p>

			<p className="center-align">
				If you are having trouble logging in, you may need to clear your
				browser's cache. Please click the link below for instructions
				for your system/browser.
				<br />
				<br />
				<a
					href="https://www.whatismybrowser.com/guides/how-to-clear-cookies-browsing-history-and-cache/auto"
					target="_blank"
					className="goldBtn"
					rel="noopener noreferrer"
				>
					Instructions
				</a>
			</p>
		</form>
	);
};
