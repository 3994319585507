const loadAcceptJS = (callback) => {
   const existingScript = document.getElementById("AcceptJS");

   if (!existingScript) {
      const script = document.createElement("script");

      script.src = "https://js.authorize.net/v1/Accept.js";
      // script.src = "https://jstest.authorize.net/v1/Accept.js";
      script.id = "AcceptJS";
      document.body.appendChild(script);

      script.onload = () => {
         callback && callback();
      };
   }

   existingScript && callback && callback();
};

export default loadAcceptJS;
