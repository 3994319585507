import React from "react";
import "./App.scss";

const SearchBar = ({ keyword, setKeyword }) => {
	const BarStyling = {
		width: "20rem",
		background: "#F2F1F9",
		border: "none",
		padding: "0.5rem",
	};
	return (
		<form
			id="header_search_form"
			method="post"
			action="https://shop.bahamajoes.com/lsearch.jhtm"
		>
			<div className="input-group">
				<span className="input-group-addon">
					<i className="fa fa-search fa-fw"></i>
				</span>
				<input
					type="text"
					className="form-control"
					placeholder="enter search keyword"
					name="keywords"
				/>
				<span className="input-group-btn">
					<button className="btn-search custom-orange-btn" type="submit">
						Go
					</button>
				</span>

				{/* <input
				style={BarStyling}
				key="random1"
				value={keyword}
				placeholder={"search country"}
				onChange={(e) => setKeyword(e.target.value)}
			/> */}
			</div>
		</form>
	);
};

export default SearchBar;
