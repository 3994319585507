import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Zunzis from './Zunzis.png';
import Allegiant from './allegiant_management.png';
import GACraft from './georgia_craft_brewers_guild.png';
import Jedson from './jedson_engineering.png';
// import Optim from './Optim.png';
import Optim from './Optim-Health-System.png';
import TMX from './titlemax_cares.png';
import PgTitle from './corporate.png';
import { Helmet } from 'react-helmet';

const Page = () => {
	const [path, setPath] = useState('');
	const [display, setDisplay] = useState([]);

	const params = useParams();
	// const { params } = match;

	const partners = [
		// {
		//    path: '',
		//    to: '/Partners/Allegiant_Management',
		//    image: Allegiant,
		// },
		// {
		//    path: '',
		//    to: '/Partners/Georgia_Brewers',
		//    image: GACraft,
		// },
		// {
		//   path: '',
		//   to: '/Partners/Titlemax',
		//   image: TMX,
		// },
		// {
		//    path: '',
		//    to: '/Partners/Zunzis',
		//    image: Zunzis,
		// },
		// {
		//    path: '',
		//    to: '/Partners/Jedson_Engineering',
		//    image: Jedson,
		// },
		{
			path: '',
			to: '/Corporate-Stores/optim',
			image: Optim,
		},
		// {
		//    path: 'optim',
		//    to: '/Partners/Optim_Healthcare',
		//    image: 'https://bahamajoes.com/assets/logos/optim-healthcare.svg',
		// },
		// {
		//    path: 'optim',
		//    to: '/Partners/optim_imaging',
		//    image: 'https://bahamajoes.com/assets/logos/optim-imaging.svg',
		// },
		// {
		//    path: 'optim',
		//    to: '/Partners/optim_medical_center',
		//    image: 'https://bahamajoes.com/assets/logos/optim-medicalcenter.svg',
		// },
		{
			path: 'optim',
			to: '/Partners/optim_medical_center_screven',
			image:
				'https://bahamajoes.com/assets/logos/optim-medicalcenter-screvin.svg',
		},
		{
			path: 'optim',
			to: '/Partners/optim_medical_center_tattnall',
			image:
				'https://bahamajoes.com/assets/logos/optim-medicalcenter-tattnall.svg',
		},
		{
			path: 'optim',
			to: '/Partners/optim_neurosurgery',
			image: 'https://bahamajoes.com/assets/logos/optim-neurosurgery.svg',
		},
		// {
		//    path: 'optim',
		//    to: '/Partners/optim_orthopedics',
		//    image: 'https://bahamajoes.com/assets/logos/optim-ortho.svg',
		// },
		{
			path: 'optim',
			to: '/Partners/optim_pain_management',
			image: 'https://bahamajoes.com/assets/logos/optim-pain.svg',
		},
		{
			path: 'optim',
			to: '/Partners/optim_primary_care',
			image: 'https://bahamajoes.com/assets/logos/optim-primary.svg',
		},
		{
			path: 'optim',
			to: '/Partners/optim_sports_medicine',
			image: 'https://bahamajoes.com/assets/logos/optim-sports.svg',
		},
		{
			path: 'optim',
			to: '/Partners/optim_surgery_center',
			image: 'https://bahamajoes.com/assets/logos/optim-surgery-center.svg',
		},
		{
			path: 'optim',
			to: '/Partners/optim-health-system',
			image:
				'https://bahamajoes.com/assets/images/optim-health-system-index.png',
		},
		// {
		//    path: 'optim',
		//    to: '/Partners/optim_therapy',
		//    image: 'https://bahamajoes.com/assets/logos/optim-therapy.svg',
		// },
	];

	useEffect(() => {
		setDisplay(partners.filter((el) => el.path === path));
	}, [path]);

	useEffect(() => {
		'store' in params && setPath(params.store);
	}, [params]);

	return (
		<div>
			<Helmet>
				<title>Corporate Store : Bahama Joe&apos;s</title>
			</Helmet>
			<div className="container">
				<div className="center-align">
					<br />
					<br />
					<img src={PgTitle} className="responsive-img" />
					<h4 style={{ textTransform: 'uppercase' }}>
						Select your branded logo to get started.
					</h4>
				</div>
			</div>
			<div className="row container store">
				<div
					className="grid col s12 m12 l10 offset-l1"
					style={{ position: 'relative' }}
				>
					{path !== '' && (
						<div className="center-align">
							<button className="btn btn-small" onClick={() => setPath('')}>
								Back To main page
							</button>
						</div>
					)}
					<div
						style={{
							display: 'flex',
							flexWrap: 'wrap',
							justifyContent: 'space-between',
						}}
					>
						{display.map((el, i) => (
							<div
								key={i}
								style={{
									flex: '300px',
									padding: '1rem',
									alignSelf: 'center',
								}}
							>
								<Link className="" to={`${el.to}`}>
									<figure className=" logoStores card">
										{/* <img src={el.image} alt="img12" /> */}
										<img
											src={el.image}
											alt="img12"
											style={{
												width: 'auto',
												margin: '0 auto',
												height: '200px',
											}}
										/>
									</figure>
								</Link>
							</div>
						))}
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col s12 m12 l8 offset-l2 leftAlignAdj">
						<p>
							Bahama Joe's take great pride in building strong partnerships with
							small businesses and corporations. We work with businesses to
							prove online stores for their employee uniforms and apparel. We
							believe that a good successful businesses start with a vision.
							Bahama Joe’s combines your company vision with our products to
							create that positive lasting impression - to your employees,
							customers and others in your industry.
						</p>

						<p>
							Bahama Joe’s is successful when our clients are successful. So,
							let us help you make that first impression today. Select from our
							wide variety of items online or call us at 912-233-0060 to get
							your order started today.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

// const Holder = () => <></>;

export default Page;
