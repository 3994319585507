import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CartContext } from "../../context/CartContext";
import { AuthContext } from "../../context/auth-provider";

import axios from "axios";
const CartPage = () => {
  // const [orders, setOrders] = useState(null);
  const email = sessionStorage.getItem("EMS_EMAIL");
  const {
    cartItems,
    clear,
    cost,
    shipping,
    billing,
    payment,
    setPayment,
    delivery,
    pickup,
  } = useContext(CartContext);

  const [user, setUser] = useState(null);

  const EMS = JSON.parse(sessionStorage.getItem("EMS"));

  const updateUserCredit = async (totalDiscount) => {
    const domain = "https://bahamajoes.com/api";
    const localhost = "http://localhost:3360";
    const res = await axios.put(`${domain}/users/updateUserCredit`, {
      email,
      discount_amount: totalDiscount,
    });
    console.log("result from updateUserCredit", res);
  };
  const sendUserEmail = async (totalGrandCost, totalDiscount, userInfo) => {
    const domain = "https://bahamajoes.com/api";
    const localhost = "http://localhost:3360";

    try {
      await axios.post(`${domain}/users/send-user-email`, {
        user: userInfo,
        order: cartItems[0],
        cost,
        shipping,
        billing: pickup,
        totalGrandCost,
        totalDiscount,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const sendEmailEMS = async (totalGrandCost, totalDiscount, userInfo) => {
    const domain = "https://bahamajoes.com/api";
    const localhost = "http://localhost:3360";

    try {
      await axios.post(`${domain}/users/send-EMS-email`, {
        order: cartItems[0],
        cost,
        shipping,
        billing: pickup,
        user: userInfo,
        totalGrandCost,
        totalDiscount,
        user: userInfo,
      });
      sendUserEmail(totalGrandCost, totalDiscount, userInfo);
    } catch (error) {
      console.log(error);
    }
  };

  const getUSerInformation = async () => {
    const domain = "https://bahamajoes.com/api";
    const localhost = "http://localhost:3360";
    try {
      const res = await axios.get(`${domain}/users/ems/${email}`);

      if (res) {
        setUser(res.data.results[0]);

        let totalGrandCost =
          cost.grandTotal - parseInt(res.data.results[0].discount_amount);

        let totalDiscount;

        if (totalGrandCost == res.data.results[0].discount_amount) {
          totalDiscount = 0;
        } else if (totalGrandCost < 0) {
          totalDiscount = Math.abs(totalGrandCost);
        } else {
          totalDiscount = 0;
        }

        if (totalGrandCost < 0) {
          totalGrandCost = 0;
        }
        updateUserCredit(totalDiscount);
        sendEmailEMS(totalGrandCost, totalDiscount, res.data.results[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUSerInformation();
  }, []);

  // if (!order && (order === null || order === [])) navigate("/");
  // if (!order) return <center>Loading . . .</center>;
  return (
    <>
      <div className="container row">
        <h1 className="center">Order Confirmation</h1>
        <center>
          <img
            src="https://bahamajoes.thesabresolution.com/assets/logos/black-logo.png"
            style={{ height: "200px", width: "auto" }}
            alt=""
          />
        </center>
      </div>
      <div
        className="login-box store"
        style={{ paddingTop: "10px", paddingBottom: "0" }}
      >
        <div
          className="container z-depth-2"
          style={{ height: "400px", padding: "10px", textAlign: "center" }}
        >
          <div className="row">
            <div className="col s12">
              <h6>Thank you for your order!</h6>
              <p>
                <br />A Bahama Joe's representative will email you soon to
                confirm and complete your order!
              </p>
              <p>
                <Link className="btn" to={`/Schools/${EMS.school}`}>
                  Continue Shopping
                </Link>
              </p>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </>
  );
};

export default CartPage;
