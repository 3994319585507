import React, { useState, useEffect } from "react";
import styles from "./index.module.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const EMSVerify = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const verifyEmail = () => {
    const domain = "https://bahamajoes.com/api";
    const localhost = "http://localhost:3360";
    axios
      .get(`${domain}/users/ems/${email}`)
      .then((res) => {
        console.log("result from EMS page", res);
        if (res.data.results.length !== 0) {
          sessionStorage.setItem(
            "EMS",
            JSON.stringify({ school: "chatham-county-ems" })
          );
          sessionStorage.setItem("EMS_EMAIL", email);
          navigate("/Schools/chatham-county-ems");
          window.location.reload();
        } else {
          navigate("/EMSVerify");
          setMessage("Try again");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log("This is console.log for email", email);

  return (
    <div className={styles.outerContainer}>
      <div className={styles.innerContainer}>
        <h5>Welcome!</h5>
        <p>
          Enter your email address below to begin shopping.
          <br />
          Please contact Bahama Joe's at (912) 233-0060 <br />
          if you have any problems accessing the store.
        </p>
        <div>
          <input
            className={styles.input}
            type="text"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <button onClick={verifyEmail}>Submit</button>
        <h6>{message ? message : ""}</h6>
      </div>
    </div>
  );
};

export default EMSVerify;
