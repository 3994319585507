import React from 'react';
import { Link } from 'react-router-dom';
import M from 'materialize-css';
import foIcon_01 from './foIcon_01.png';
import foIcon_02 from './foIcon_02.png';
import foIcon_03 from './foIcon_03.png';
import foIcon_04 from './foIcon_04.png';
import { WEBJAGUAR_URL } from '../../config';

// const topStyle = {};

export default class Home extends React.Component {
	componentDidMount() {
		M.Sidenav.init(document.getElementById('mobile-nav'));
	}

	render() {
		return (
			<div
				className=""
				style={{
					backgroundColor: 'rgb(54, 54, 54)',
					borderTop: '7px solid #fcb040',
				}}
			>
				<div
					className="container center white-text"
					style={{ position: 'relative' }}
				>
					<footer className="row" style={{ padding: '5px 0', margin: '0' }}>
						<ul className="hide-on-med-and-down" style={{ marginBottom: '0' }}>
							<li>
								<Link className="" to="/About">
									ABOUT
								</Link>
							</li>
							<li>
								<Link className="" to="/ScreenPrinting">
									SCREENPRINTING
								</Link>
							</li>
							<li>
								<Link className="" to="/Embroidery">
									EMBROIDERY
								</Link>
							</li>
							<li>
								<Link className="" to="https://bahamajoesuniforms.myshopify.com/" onClick={() => window.location.href="https://bahamajoesuniforms.myshopify.com/"}>
									SCHOOL UNIFORMS
								</Link>
							</li>
							<li>
								<Link className="" to="/Corporate-Stores">
									Corporate Stores
								</Link>
							</li>
							{/* <li>
                        <Link className="" to="/Design">
                           DESIGN STUDIO
                        </Link>
                     </li> */}
							<li>
								<a
									className=""
									href={`${WEBJAGUAR_URL}category/26/Everything-Else.html`}
								>
									EVERYTHING ELSE
								</a>
							</li>
						</ul>
						<ul className="hide-on-med-and-down" style={{ marginTop: '5px' }}>
							<li>
								<Link className="" to="/Careers">
									CAREERS
								</Link>
							</li>
							<li>
								<Link className="" to="/Privacy-Policy">
									Privacy Policy
								</Link>
							</li>
							{/* <li>
								<Link className="" to="/Feedback">
									Feedback
								</Link>
							</li> */}
							<li>
								<Link className="" to="/Contact-Us">
									Store Locations
								</Link>
							</li>
							<li>
								<Link className="" to="/Contact-Us">
									Contact Us
								</Link>
							</li>
						</ul>
						{/* <div className="ssIcon" style={{}}>
							<a
								className="fbIcon"
								target="_blank"
								href="https://www.facebook.com/ShopBahamaJoes"
							>
								<i className="fab fa-facebook-f"></i>
							</a>
							<a
								className="igIcon"
								target="_blank"
								href="https://www.instagram.com/shopbahamajoes/"
							>
								<i className="fab fa-instagram"></i>
							</a>
							<a
								className="twIcon"
								target="_blank"
								href="https://twitter.com/shopbahamajoes"
							>
								<i className="fab fa-twitter"></i>
							</a>
							<input style={{ width: "200px" }} />
						</div> */}

						<p style={{ fontSize: '1.75em' }}>
							<a href="tel:912.233.0060" style={{ color: 'white' }}>
								912.233.0060
							</a>
							<br />
							<a href="mailto:weborders@bahamajoes.com">
								weborders@bahamajoes.com
							</a>
						</p>

						<p>
							<span style={{ fontSize: '22px', color: 'rgb(252, 176, 64)' }}>
								Corporate Offices
							</span>
							<br />
							198 W Chatham Blvd. Savannah, GA 31408
							<br />
							Customer Service hours:{' '}
							<span className="hide-on-med-and-up" style={{ lineHeight: '0' }}>
								<br />
							</span>
							Monday to Friday 8 - 5
						</p>

						<p>
							<span style={{ fontSize: '22px', color: 'rgb(252, 176, 64)' }}>
								School Uniform Store
							</span>
							<br />
							4755 Waters Ave. Savannah, GA 31404
							<br />
							Customer Service hours:{' '}
							<span className="hide-on-med-and-up" style={{ lineHeight: '0' }}>
								<br />
							</span>
							Monday to Thursday 9 - 5:30
							<br />
							Friday 9 - 5 and{' '}
							<span className="hide-on-med-and-up" style={{ lineHeight: '0' }}>
								<br />
							</span>
							Saturday 9 - 1
						</p>

						<p
							style={{
								textTransform: 'uppercase',
								fontSize: '14px',
								marginTop: '25px',
							}}
						>
							&copy; 2021 BAHAMA JOE'S
							<span
								className="hide-on-small-inline-block"
								style={{ lineHeight: '0' }}
							>
								&nbsp; | &nbsp;
							</span>
							<span className="hide-on-med-and-up" style={{ lineHeight: '0' }}>
								<br />
							</span>
							Crafted and Hosted by{' '}
							<a
								href="https://thesabresolution.com/"
								rel="noopener noreferrer"
								target="_blank"
								className="white-text"
							>
								Sabre Creative
							</a>
						</p>
					</footer>
				</div>
			</div>
		);
	}
}
