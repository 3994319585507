import React from 'react';
// import { AuthContext } from '../../context/auth-provider';
// import { CartContext } from '../../context/CartContext';
import { Link } from 'react-router-dom';
import BMSlogo from './Benedictine.jpg';
import BBCAlogo from './bbca.jpg';
import BSSlogo from './bss.jpg';
import BCAlogo from './bca.jpg';
import FCAlogo from './fca.jpg';
import HDSlogo from './hds.jpg';
import MEMlogo from './mem.jpg';
import JSlogo from './sj.jpg';
import PCAlogo from './pca.jpg';
import SCPSlogo from './scps.jpg';
import SCAlogo from './sca.jpg';
import THSlogo from './ths.jpg';
import SVAlogo from './sva.jpg';
import SPAlogo from './spa.jpg';
import CAlogo from './chatham-academy-swoosh-navy.jpg';
import TIMAlogo from './TIMA.jpg';
// import cartImg from './CART.png';
import { WEBJAGUAR_URL } from '../../config';

const Navlinks = ({ closeMobileNav, ...rest }) => {
	// const { cartItems } = useContext(CartContext);
	// const { user, logout } = useContext(AuthContext);
	return (
		<React.Fragment>
			<li>
				<Link className="" to="/About" onClick={() => closeMobileNav()}>
					ABOUT
					<span>GET TO KNOW US</span>
				</Link>
			</li>
			<li>
				<Link
					className=""
					to="/ScreenPrinting"
					onClick={() => closeMobileNav()}
				>
					SCREENPRINTING
					<span>CUSTOM SCREENPRINTING</span>
				</Link>
			</li>
			<li>
				<Link className="" to="/Embroidery" onClick={() => closeMobileNav()}>
					EMBROIDERY
					<span>CUSTOM EMBROIDERY</span>
				</Link>
			</li>

			{/* <li className="hide-on-large-only">
				<ul className="collapsible">
					<li>
						<div
							className="collapsible-header"
							style={{
								display: "block",
								padding: "0 5px 0 25px",
								fontSize: "16px",
								fontWeight: "500",
							}}
						>
							SCHOOL UNIFORMS<i className="material-icons right">add</i>
						</div>
						<div
							className="collapsible-body"
							style={{ padding: "0 !important" }}
						>
							<ul className="topNav">
								<li>
									<Link to="/">912-233-0060</Link>
								</li>
								<li>
									<Link to="/">My Account</Link>
								</li>
								<li>
									<Link to="/">My Wishlist</Link>
								</li>
								<li>
									<a href="https://www.bahamajoes.com/login.jhtm">Log In</a>

									{user ? (
                              <span style={{ cursor: 'pointer' }} onClick={logout}>
                                 Log Out
                              </span>
                           ) : (
                              <Link to="/">Log In</Link>
                           )}
								</li>
								<li>
									<Link to="/">Register</Link>
								</li>
								<li>
									<Link to="/cart">
										View Cart{" "}
										{cartItems.length > 0 &&
											`(${cartItems.reduce(
												(total, i) => total + i.quantity,
												0
											)})`}
									</Link>
								</li>
							</ul>
							<ul className="">
								<li>
									<Link
										className="mobileSubLi"
										to="/Schools/Benedictine"
										onClick={() => closeMobileNav()}
									>
										Benedictine Military School
									</Link>
								</li>
								<li>
									<Link
										className="mobileSubLi"
										to="/Schools/Bible-Baptist-Christian"
										onClick={() => closeMobileNav()}
									>
										Bible Baptist Christian Academy
									</Link>
								</li>
								<li>
									<Link
										className="mobileSubLi"
										to="/Schools/Blessed-Sacrament"
										onClick={() => closeMobileNav()}
									>
										Blessed Sacrament School
									</Link>
								</li>
								<li>
									<Link
										className="mobileSubLi"
										to="/Schools/Butler-Christian"
										onClick={() => closeMobileNav()}
									>
										Butler Christian Academy
									</Link>
								</li>
								<li>
									<Link
										className="mobileSubLi"
										to="/Schools/First-Christian"
										onClick={() => closeMobileNav()}
									>
										First Christian Academy
									</Link>
								</li>
								<li>
									<Link
										className="mobileSubLi"
										to="/Schools/Hancock-Day"
										onClick={() => closeMobileNav()}
									>
										Hancock Day School
									</Link>
								</li>
								<li>
									<Link
										className="mobileSubLi"
										to="/Schools/Memorial-Day"
										onClick={() => closeMobileNav()}
									>
										Memorial Day School
									</Link>
								</li>
								<li>
									<Link
										className="mobileSubLi"
										to="/Schools/St-James"
										onClick={() => closeMobileNav()}
									>
										St. James School
									</Link>
								</li>
								<li>
									<Link
										className="mobileSubLi"
										to="/Schools/Pinewood-Christian"
										onClick={() => closeMobileNav()}
									>
										Pinewood Christian Academy
									</Link>
								</li>
								<li>
									<Link
										className="mobileSubLi"
										to="/Schools/Savannah-Christian"
										onClick={() => closeMobileNav()}
									>
										Savannah Christian Preparatory School
									</Link>
								</li>
								<li>
									<Link
										className="mobileSubLi"
										to="/Schools/Savannah-Classical"
										onClick={() => closeMobileNav()}
									>
										Savannah Classical Academy
									</Link>
								</li>
								<li>
									<Link
										className="mobileSubLi"
										to="/Schools/Habersham-School"
										onClick={() => closeMobileNav()}
									>
										The Habersham School
									</Link>
								</li>
								<li>
									<Link
										className="mobileSubLi"
										to="/Schools/St-Vincents"
										onClick={() => closeMobileNav()}
									>
										St. Vincent's Academy
									</Link>
								</li>
								<li>
									<Link
										className="mobileSubLi"
										to="/Schools/St-Peter-The-Apostle"
										onClick={() => closeMobileNav()}
									>
										St. Peter Apostle
									</Link>
								</li>
							</ul>
						</div>
					</li>
				</ul>
			</li> */}
			{/* <li>
				<Link to="/Design" onClick={() => closeMobileNav()}>
					DESIGN STUDIO
					<span>YOU DESIGN IT!</span>
				</Link>
			</li> */}
			<li>
				<a
					className=""
					href={`${WEBJAGUAR_URL}category/26/Everything-Else.html`}
					onClick={() => closeMobileNav()}
				>
					EVERYTHING ELSE
					<span>ALL THE PROMO!</span>
				</a>
			</li>
			<DropdownLink
				closeMobileNav={closeMobileNav}
				linkText={
					<Link
						className=""
						to="https://bahamajoesuniforms.myshopify.com/"
						onClick={() => window.location.href="https://bahamajoesuniforms.myshopify.com/"}
					>
						SCHOOL UNIFORMS
						<span>SPECIALTY UNIFORMS</span>
					</Link>
				}
			>
				<div className="dropdownNav hide-on-med-and-down">
					<div
						className="col s12"
						style={{
							borderBottom: 'solid #edc11e 2px',
							backgroundColor: '#edc11e',
						}}
					>
						<div style={{ padding: '0 10px' }}>
							{/* <ul className="topNav" style={{ float: "left" }}>
								<li>
									<Link to="/">912.355.6960</Link>
								</li>
								<li>
                           <Link to="/">4755 Waters Avenue</Link>
                        </li>
                        <li>
                           <Link to="/">Hours: Monday - Thursday 9 - 5:30 | Friday 9 - 5:00 | Saturday 9 - 1:00</Link>
                        </li>
							</ul> */}
							{/* <ul className="topNav">
                        <li style={{ height: "30px", margin: "3px 0", border: "0" }}>
                           <Link to="/login" style={{ border: "1px solid black", padding: "5px" }}>
                              Sign In
                           </Link>
                        </li>
                        <li style={{ border: "0" }}>
                           <Link to="/Register">Register</Link>
                        </li>
                        <li style={{ height: "30px", margin: "3px 0", border: "0" }}>
                           <Link to="/cart" style={{ position: "relative" }}>
                              <img src={cartImg} />
                              <span className="cartNumItem">{cartItems.length > 0 && `${cartItems.reduce((total, i) => total + i.quantity, 0)}`}</span>
                           </Link>
                        </li>
                     </ul> */}
						</div>
					</div>
					<div className="col s12 m4 l4">
						<ul>
							<li>
								<Link
									className="dropdownItem valign-wrapper"
									to="https://bahamajoesuniforms.myshopify.com/collections/bc"
									onClick={() => window.location.href="https://bahamajoesuniforms.myshopify.com/collections/bc"}
								>
									<img alt="" src={BMSlogo} className="dropdownSchoolLogo" />
									Benedictine Military School
								</Link>
							</li>
							<li>
								<Link
									className="dropdownItem valign-wrapper"
									to="https://bahamajoesuniforms.myshopify.com/collections/bbca"
									onClick={() => window.location.href="https://bahamajoesuniforms.myshopify.com/collections/bbca"}
								>
									<img alt="" src={BBCAlogo} className="dropdownSchoolLogo" />
									Bible Baptist Christian Academy
								</Link>
							</li>
							<li>
								<Link
									className="dropdownItem valign-wrapper"
									to="https://bahamajoesuniforms.myshopify.com/collections/bss"
									onClick={() => window.location.href="https://bahamajoesuniforms.myshopify.com/collections/bss"}
								>
									<img alt="" src={BSSlogo} className="dropdownSchoolLogo" />
									Blessed Sacrament School
								</Link>
							</li>
							<li>
								<Link
									className="dropdownItem valign-wrapper"
									to="https://bahamajoesuniforms.myshopify.com/collections/bca"
									onClick={() => window.location.href="https://bahamajoesuniforms.myshopify.com/collections/bca"}
								>
									<img alt="" src={BCAlogo} className="dropdownSchoolLogo" />
									Butler Christian Academy
								</Link>
							</li>
							<li>
								<Link
									className="dropdownItem valign-wrapper"
									to="https://bahamajoesuniforms.myshopify.com/collections/fca"
									onClick={() => window.location.href="https://bahamajoesuniforms.myshopify.com/collections/fca"}
								>
									<img alt="" src={FCAlogo} className="dropdownSchoolLogo" />
									First Christian Academy
								</Link>
							</li>
							<li>
								<Link
									className="dropdownItem valign-wrapper"
									to="https://bahamajoesuniforms.myshopify.com/collections/tima"
									// to="/Schools/TIMA"
									onClick={() => window.location.href="https://bahamajoesuniforms.myshopify.com/collections/tima"}
								>
									<img alt="" src={TIMAlogo} className="dropdownSchoolLogo" />
									Tybee Island Maritime Academy
								</Link>
							</li>
						</ul>
					</div>
					<div className="col s12 m4 l4">
						<ul>
							<li>
								<Link
									className="dropdownItem valign-wrapper"
									to="https://bahamajoesuniforms.myshopify.com/collections/hds"
									onClick={() => window.location.href="https://bahamajoesuniforms.myshopify.com/collections/hds"}
								>
									<img alt="" src={HDSlogo} className="dropdownSchoolLogo" />
									Hancock Day School
								</Link>
							</li>
							<li>
								<Link
									className="dropdownItem valign-wrapper"
									to="https://bahamajoesuniforms.myshopify.com/collections/mds"
									onClick={() => window.location.href="https://bahamajoesuniforms.myshopify.com/collections/mds"}
								>
									<img alt="" src={MEMlogo} className="dropdownSchoolLogo" />
									Memorial Day School
								</Link>
							</li>
							<li>
								<Link
									className="dropdownItem valign-wrapper"
									to="https://bahamajoesuniforms.myshopify.com/collections/sjs"
									onClick={() => window.location.href="https://bahamajoesuniforms.myshopify.com/collections/sjs"}
								>
									<img alt="" src={JSlogo} className="dropdownSchoolLogo" />
									St. James School
								</Link>
							</li>
							<li>
								<Link
									className="dropdownItem valign-wrapper"
									to="https://bahamajoesuniforms.myshopify.com/collections/pca"
									onClick={() => window.location.href="https://bahamajoesuniforms.myshopify.com/collections/pca"}
								>
									<img alt="" src={PCAlogo} className="dropdownSchoolLogo" />
									Pinewood Christian Academy
								</Link>
							</li>
							<li>
								<Link
									className="dropdownItem valign-wrapper"
									to="https://bahamajoesuniforms.myshopify.com/collections/scps"
									onClick={() => window.location.href="https://bahamajoesuniforms.myshopify.com/collections/scps"}
								>
									<img alt="" src={SCPSlogo} className="dropdownSchoolLogo" />
									Savannah Christian Preparatory School
								</Link>
							</li>
						</ul>
					</div>
					<div className="col s12 m4 l4">
						<ul>
							<li>
								<Link
									className="dropdownItem valign-wrapper"
									to="https://bahamajoesuniforms.myshopify.com/collections/sca"
									onClick={() => window.location.href="https://bahamajoesuniforms.myshopify.com/collections/sca"}
								>
									<img alt="" src={SCAlogo} className="dropdownSchoolLogo" />
									Savannah Classical Academy
								</Link>
							</li>
							<li>
								<Link
									className="dropdownItem valign-wrapper"
									to="https://bahamajoesuniforms.myshopify.com/collections/ths"
									onClick={() => window.location.href="https://bahamajoesuniforms.myshopify.com/collections/ths"}
								>
									<img alt="" src={THSlogo} className="dropdownSchoolLogo" />
									The Habersham School
								</Link>
							</li>
							<li>
								<Link
									className="dropdownItem valign-wrapper"
									to="https://bahamajoesuniforms.myshopify.com/collections/sva"
									onClick={() => window.location.href="https://bahamajoesuniforms.myshopify.com/collections/sva"}
								>
									<img alt="" src={SVAlogo} className="dropdownSchoolLogo" />
									St. Vincent's Academy
								</Link>
							</li>
							<li>
								<Link
									className="dropdownItem valign-wrapper"
									to="https://bahamajoesuniforms.myshopify.com/collections/spa"
									onClick={() => window.location.href="https://bahamajoesuniforms.myshopify.com/collections/spa"}
								>
									<img alt="" src={SPAlogo} className="dropdownSchoolLogo" />
									St. Peter Apostle
								</Link>
							</li>
							<li>
								<Link
									className="dropdownItem valign-wrapper"
									to="https://bahamajoesuniforms.myshopify.com/collections/ca"
									onClick={() => window.location.href="https://bahamajoesuniforms.myshopify.com/collections/ca"}
								>
									<img alt="" src={CAlogo} className="dropdownSchoolLogo" />
									Chatham Academy
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</DropdownLink>
			{/* <DropdownLink
				closeMobileNav={closeMobileNav}
				linkText={
					<Link className="" to="/" onClick={() => closeMobileNav()}>
						EVERYTHING ELSE
						<span>ALL THE PROMO!</span>
					</Link>
				}
			>
				<div
					className="dropdownNav hide-on-med-and-down"
					style={{
						border: "solid #4ca59e 5px",
					}}
				>
					<div
						className="col s12 bahama-green"
						style={{
							borderBottom: "solid #4ca59e 2px",
						}}
					>
						<div style={{ padding: "0 10px" }}>
							<ul className="topNav" style={{ float: "left" }}>
								<li>
									<Link to="/">912.233.0060</Link>
								</li>
								<li>
									<Link to="/">My Account</Link>
								</li>
								<li>
									<Link to="/">My Wishlist</Link>
								</li>
							</ul>
							<ul className="topNav">
								<li style={{ height: "30px", margin: "3px 0", border: "0" }}>
									<a
										href="https://www.bahamajoes.com/login.jhtm"
										style={{ border: "1px solid black", padding: "5px" }}
									>
										Sign In
									</a>
								</li>
								<li style={{ border: "0" }}>
									<Link to="/">Register</Link>
								</li>
								<li style={{ height: "30px", margin: "3px 0", border: "0" }}>
									<Link to="/cart" style={{ position: "relative" }}>
										<img src={cartImg} />
										<span className="cartNumItem">
											{cartItems.length > 0 &&
												`${cartItems.reduce(
													(total, i) => total + i.quantity,
													0
												)}`}
										</span>
									</Link>
								</li>
							</ul>
						</div>
					</div>
					<div className="col s12 m2 l2">
						<ul>
							<li>
								<Link
									className="dropdownItem"
									style={{ textAlign: "center" }}
									to="/Schools/Benedictine"
									onClick={() => closeMobileNav()}
								>
									<br />
									<img src="https://via.placeholder.com/150x200" />
									<br />
									Apparel
								</Link>
							</li>
						</ul>
					</div>
					<div className="col s12 m2 l2">
						<ul>
							<li>
								<Link
									className="dropdownItem"
									style={{ textAlign: "center" }}
									to="/Schools/Benedictine"
									onClick={() => closeMobileNav()}
								>
									<br />
									<img src="https://via.placeholder.com/150x200" />
									<br />
									Hat
								</Link>
							</li>
						</ul>
					</div>
					<div className="col s12 m2 l2">
						<ul>
							<li>
								<Link
									className="dropdownItem"
									style={{ textAlign: "center" }}
									to="/Schools/Benedictine"
									onClick={() => closeMobileNav()}
								>
									<br />
									<img src="https://via.placeholder.com/150x200" />
									<br />
									Footwear
								</Link>
							</li>
						</ul>
					</div>
					<div className="col s12 m2 l2">
						<ul>
							<li>
								<Link
									className="dropdownItem"
									style={{ textAlign: "center" }}
									to="/Schools/Benedictine"
									onClick={() => closeMobileNav()}
								>
									<br />
									<img src="https://via.placeholder.com/150x200" />
									<br />
									Bags
								</Link>
							</li>
						</ul>
					</div>
					<div className="col s12 m2 l2">
						<ul>
							<li>
								<Link
									className="dropdownItem"
									style={{ textAlign: "center" }}
									to="/Schools/Benedictine"
									onClick={() => closeMobileNav()}
								>
									<br />
									<img src="https://via.placeholder.com/150x200" />
									<br />
									Drinkware
								</Link>
							</li>
						</ul>
					</div>
					<div className="col s12 m2 l2">
						<ul>
							<li>
								<Link
									className="dropdownItem"
									style={{ textAlign: "center" }}
									to="/Schools/Benedictine"
									onClick={() => closeMobileNav()}
								>
									<br />
									<img src="https://via.placeholder.com/150x200" />
									<br />
									Everything Else
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</DropdownLink> */}
		</React.Fragment>
	);
};

const DropdownLink = ({ closeMobileNav, linkText, children }) => {
	const [hover, setHover] = React.useState(false);

	return (
		<>
			<li
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
			>
				{/* <Link className="" to="/" onClick={() => closeMobileNav()}> */}
				{linkText}
				{/* </Link> */}
				{hover && <div>{children}</div>}
			</li>
		</>
	);
};

export default Navlinks;
